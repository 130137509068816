import React from "react";
import { Link } from 'react-router-dom';
import './css/style.css';
import './css/utilities.css';
import topImage from './images/lt_top_image.png';
import newRequest from './images/lt_1_new_request.png';
import leaveType from './images/lt_2_leave_type.png';
import leavePolicy from './images/lt_3_leave_policy_new.png';
import userConfig from './images/lt_4_user_config.png';
import approval from './images/lt_5_approval.png';
import allRequests from './images/lt_6_all_leave_history.png';
import statsAdmin from './images/lt_7_stats_admin.png';
import location1 from './images/lt_8_location_1.png';
import dashboard from './images/lt_9_dashboard.png';
import location2 from './images/lt_10_location_2.png';

class Home extends React.Component {
    render() {
        return (
            <div className="homepage">

                {/* Navbar */}
                <div className="navbar">
                    <div className="container flex">
                        <h1 className="logo">Leave Tracker</h1>
                        <nav>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/privacy">Privacy</Link></li>
                                <li><Link to="/termsofuse">Terms of Use</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                {/* Showcase */}
                <section class="showcase">
                    <div class="container grid">

                        <div class="showcase-text">
                            <h1>Revolutionizing</h1>
                            <h1>Your Organization's</h1>
                            <h1>Leave Management.</h1>
                            <p>As organizations evolve and scale, managing employee leaves can become a daunting task. This intuitive application offers easy access to leave requests, approvals, tracking, and policy configuration, providing a seamless experience for both employees and administrators.</p>
                            <p>Built for Microsoft Teams, Office 365 and Outlook.</p>
                            <a href="/" class="btn-btn btn-primary">Check on AppSource</a>
                        </div>

                        <div class="showcase-image-container">
                            <div class="top-image"><img src={topImage} alt="" /></div>
                        </div>

                    </div>
                </section>

                {/* App features */}
                <section className="app-features">
                    <div className="section-header">
                        <h1>The Application Features</h1>
                    </div>

                    <div className="app-features">
                        <div className="grid container">
                            <div>
                                <h2>Request and Track Leaves</h2>
                                <p>Allows employees to easily submit leave requests and monitor their status, providing transparency and real-time updates. </p>
                            </div>
                            <div><img src={newRequest} alt="" /></div>
                        </div>
                    </div>

                    <div className="app-features app-features-light-section">
                        <div className="grid container">
                            <div><img src={leaveType} alt="" /></div>
                            <div>
                                <h2>Configure Leave Types</h2>
                                <p>Enables administrators to define different types of leaves according to organizational needs, offering flexibility and specificity.</p>
                            </div>
                        </div>
                    </div>

                    <div className="app-features">
                        <div className="grid container">
                            <div>
                                <h2>Set Leave Policies</h2>
                                <p>Provides the ability to establish comprehensive leave policies, ensuring clarity and fairness in leave allocations.</p>
                            </div>
                            <div><img src={leavePolicy} alt="" /></div>
                        </div>
                    </div>

                    <div className="app-features app-features-light-section">
                        <div className="grid container">
                            <div><img src={userConfig} alt="" /></div>
                            <div>
                                <h2>Manage User Roles</h2>
                                <p>Facilitates the assignment and modification of user roles and locations, enhancing efficiency in user management.</p>
                            </div>
                        </div>
                    </div>

                    <div className="app-features">
                        <div className="grid container">
                            <div>
                                <h2>Approve Leave Requests</h2>
                                <p>Streamlines the approval process with an intuitive interface, making leave management swift and hassle-free for administrators.</p>
                            </div>
                            <div><img src={approval} alt="" /></div>
                        </div>
                    </div>

                    <div className="app-features app-features-light-section">
                        <div className="grid container">
                            <div><img src={allRequests} alt="" /></div>
                            <div>
                                <h2>View All Leave History</h2>
                                <p>Admins can view all leave history within their organization and carry out filtering actions based on employee name, time and leave approval status.</p>
                            </div>
                        </div>
                    </div>

                    <div className="app-features">
                        <div className="grid container">
                            <div>
                                <h2>Leave Statistics</h2>
                                <p>Users and Admins get to see a rundown of all their leave requests and get to see a breakdown of leave balances.</p>
                            </div>
                            <div><img src={statsAdmin} alt="" /></div>
                        </div>
                    </div>

                    <div className="app-features app-features-light-section">
                        <div className="grid container">
                            <div><img src={location1} alt="" /></div>
                            <div>
                                <h2>Location Configuration</h2>
                                <p>Enables setting up of multiple locations, catering to the diverse geographical presence of an organization, enhancing the relevancy and applicability of leave policies.</p>
                            </div>
                        </div>
                    </div>

                    <div className="app-features">
                        <div className="grid container">
                            <div>
                                <h2>Intuitive Dashboard</h2>
                                <p>Admins get a comprehensive overview of leave requests. They get a breakdown and categorization of leave activities within their organization.</p>
                            </div>
                            <div><img src={dashboard} alt="" /></div>
                        </div>
                    </div>

                    <div className="app-features app-features-light-section">
                        <div className="grid container">
                            <div><img src={location2} alt="" /></div>
                            <div>
                                <h2>Policy-Driven Locations</h2>
                                <p>Allows administrators to define and apply distinct leave policies based on location, ensuring regional compliance and consideration of local norms and regulations.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* The conclusion*/}
                <section className="conclusion" id="the-make-up">
                    <div>
                        <h1> The Leave Tracker App </h1>
                        <h1>helps you keep</h1>
                        <h1> a finger on the pulse</h1>
                        <h1>of your organization's leave dynamics.</h1>
                        <a href="/" class="btn-btn btn-primary">Check on AppSource</a>
                    </div>
                </section>

                {/* Footer */}
                <footer className="footer">
                    <div>
                        <p><Link to="/privacy">Privacy Policy</Link></p>
                        <p><Link to="/termsofuse">Terms of Use</Link></p>
                        <p>&copy; Reliance Infosystems Limited</p>
                    </div>
                </footer>

            </div>
        )
    }
}

export default Home;