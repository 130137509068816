import React, { useState } from 'react';
import closeForm from "../../images/cancel.png";
import "./LeavePolicyConfigForm.scss";
import { useData } from '@microsoft/teamsfx-react';
import { handleBackendRequestBlockErrors, toasterErrorMessage, toasterSuccessMessage } from '../utils/errorHandlingUtils';


export default function LeavePolicyConfigForm(props) {
  const {
    togglePolicyForm,
    apiClient,
    triggerConsent,
    leavePolicies,
    selectedLeavePolicy,
    setSelectedLeavePolicy,
    reloadLeavePolicies,
    isNewPolicyForm,
    setIsNewPolicyForm,
    isViewForm
  } = props;

  const [polLocations, setPolLocations] = useState([]);
  const [locLeaveTypes, setLocLeaveTypes] = useState(selectedLeavePolicy?.locLeaveTypes || []);

  const [leavePolicyLocation, setLeavePolicyLocation] = useState(selectedLeavePolicy?.location || "");
  const [leaveTypePolicy, setLeaveTypePolicy] = useState(selectedLeavePolicy?.leaveType || "");
  const [selectedLeaveTypeUnit, setSelectedLeaveTypeUnit] = useState(selectedLeavePolicy?.leaveUnit || "");
  const [yearlyQuota, setYearlyQuota] = useState(selectedLeavePolicy?.yearlyQuota || "");
  const [cummulative, setCummulative] = useState(selectedLeavePolicy?.cummulative || "");
  const [paid, setPaid] = useState(selectedLeavePolicy?.paid || false);
  const [unlimited, setUnlimited] = useState((selectedLeavePolicy?.unlimited === undefined) ? true : selectedLeavePolicy?.unlimited);
  const [requireReliever, setRequireReliever] = useState(selectedLeavePolicy?.requireReliever || "");

  // Get locations (excluding default)
  useData(async () => {
    try {
      let resLocations = await apiClient.get(`/location/locations`);
      resLocations = resLocations.data.data.reduce((accum, currLoc) => {
        if (currLoc.locationName !== "default") {
          accum.push(currLoc.locationName);
        }
        return accum;
      }, []);
      setPolLocations(resLocations);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Get all leave types for selected location
  async function getLocLeaveTypes(location) {
    try {
      const resLeaveTypes = await apiClient.get(`/leaveType/leaveTypes?type=location&location=${location}`);
      setLocLeaveTypes(resLeaveTypes.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  }

  // Function to handle submitting leave policy
  const handleLeavePolicySubmit = async (event) => {
    try {
      event.preventDefault();
      let response;
      let data = {
        location: leavePolicyLocation,
        leaveName: leaveTypePolicy,
        yearlyQuota: yearlyQuota,
        cummulative: cummulative,
        paid: paid,
        unlimited: unlimited,
        requireReliever: (requireReliever === "Yes" ? true : false)
      };
      if (isNewPolicyForm) {
        response = await apiClient.post("/leavePolicy/new", data);
      } else {
        data.leavePolicyId = selectedLeavePolicy.leavePolicyId;
        data.location = undefined;
        data.leaveName = undefined;
        response = await apiClient.put("/leavePolicy/update", data);
      }
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadLeavePolicies();
    // reset the form fields/variables
    setLeavePolicyLocation("");
    setLeaveTypePolicy("");
    setYearlyQuota("");
    setCummulative(false);
    setPaid(false);
    setUnlimited(false);
    setRequireReliever("");
    setIsNewPolicyForm(true);
    togglePolicyForm(false);
  }

  return (
    <div id="policy-form-container" togglePolicyForm={togglePolicyForm}>
      <form
        action=""
        onSubmit={handleLeavePolicySubmit}
        id="policy-form"
      >
        <div className="close-policy-form">
          <h4>Configure Leave Policy</h4>
          <img
            src={closeForm}
            alt="close-form"
            className="close"
            onClick={() => {
              togglePolicyForm(false);
              // reset the form fields/variables
              setLeavePolicyLocation("");
              setLeaveTypePolicy("");
              setYearlyQuota("");
              setCummulative(false);
              setPaid(false);
              setUnlimited(false);
              setRequireReliever("");
              setSelectedLeavePolicy({});
              setIsNewPolicyForm(true);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="leave-type">Location:</label>
          <select
            name="leave policy location"
            id="leave-policy-location"
            disabled={isNewPolicyForm ? false : true}
            value={leavePolicyLocation}
            onChange={async (event) => {
              setLeavePolicyLocation(event.target.value);
              await getLocLeaveTypes(event.target.value);
            }}
          >
            <option value="" disabled selected className="selected">
              Please pick a location
            </option>
            {polLocations.map((location, key) => {
              return (
                <option value={location} key={key}>
                  {location}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="leave-type">Leave Type:</label>
          <select
            name="leave type policy"
            id="leave-type-policy"
            disabled={isNewPolicyForm ? false : true}
            value={leaveTypePolicy}
            onChange={(event) => {
              setLeaveTypePolicy(event.target.value);
              leavePolicies.forEach((leavePolicy) => {
                if (leavePolicy.location === leavePolicyLocation && leavePolicy.leaveType === event.target.value) {
                  toasterErrorMessage("Leave Policy for Leave Type already exists.")
                }
              });
              // set the leave unit for selected leave type
              let leaveunit = (locLeaveTypes.find((leavetype) => leavetype.leaveTypeName === event.target.value)).leaveUnit;
              setSelectedLeaveTypeUnit(leaveunit);
            }}
          >
            <option value="" disabled selected className="selected">
              Please pick a leave type
            </option>
            {locLeaveTypes.map((leaveType, key) => {
              return (
                <option value={leaveType.leaveTypeName} key={key}>
                  {leaveType.leaveTypeName}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group">
          <div className="checkboxes checkboxes-paid">
            <input
              type="checkbox"
              name=""
              id=""
              checked={unlimited}
              onChange={(event) => {
                setUnlimited(event.target.checked);

                if (event.target.checked) {
                  setYearlyQuota("");
                  setCummulative("");
                }
              }}
              disabled={isViewForm}
            />
            <label htmlFor="paid">Unlimited?</label>
          </div>
        </div>

        {!unlimited &&
          <div className="form-group">
            <label htmlFor="yearly-quota">Yearly Quota ({selectedLeaveTypeUnit || "N/A"}):</label>
            <input
              name="yearly quota"
              type="number"
              value={yearlyQuota}
              onChange={(event) => setYearlyQuota(event.target.value)}
              placeholder="e.g 1"
              disabled={isViewForm}
            />
          </div>
        }

        {!unlimited &&
          <div className="form-group">
            <div className="checkboxes">
              <input
                type="checkbox"
                name=""
                id=""
                checked={cummulative}
                onChange={(event) => { setCummulative(event.target.checked) }}
                disabled={isViewForm}
              />
              <label htmlFor="">Cumulative?</label>
            </div>
          </div>
        }

        <div className="form-group">
          <div className="checkboxes checkboxes-paid">
            <input
              type="checkbox"
              name=""
              id=""
              checked={paid}
              onChange={(event) => { setPaid(event.target.checked) }}
              disabled={isViewForm}
            />
            <label htmlFor="paid">Paid?</label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="">Require Reliever?</label>
          <select
            name="require reliever"
            id="require-reliever"
            value={requireReliever}
            onChange={(event) =>
              setRequireReliever(event.target.value)
            }
            disabled={isViewForm}
          >
            <option value="" disabled selected className="selected">
              Please pick an option
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {!isViewForm && <button className="btn-content-submit">{(isNewPolicyForm) ? "Save" : "Update"}</button>}
      </form>
    </div>
  )
}
