import React, { useState } from "react";
import LocationForm from "./LocationForm";
import "./LocationConfig.scss";
import { useData } from "@microsoft/teamsfx-react";
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  handleBackendRequestBlockErrors,
  toasterSuccessMessage,
} from "../utils/errorHandlingUtils";
import { capitalizeFirstLetter } from "../utils/generalUtils";
import EmptyDataPlaceHolder2 from "./EmptyDataPlaceHolder2";
import ConfirmAction from "../helperComponents/ConfirmAction";

import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'MoreVertical' }}
      menuProps={{ items: overflowItems }}
    />
  );
};

function LocationConfig(props) {
  const { toggleState, apiClient, user, triggerConsent } = props;
  const [showContinueForm, setShowContinueForm] = useState(false);
  const [locLocations, setLocLocations] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [locationData, setLocationData] = useState({});
  const [users, setUsers] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const confirmDeleteConfig = {
    header: "Delete Location",
    body: `Are you sure you want to delete location, ${locationData.locationName}?`,
    action: "Delete"
  };

  // Function to show or hide location form
  const toggleContinueForm = (showContinueFormState) => {
    setShowContinueForm(showContinueFormState);
  };

  // Get all locations data
  const { reload: reloadLocations } = useData(async () => {
    try {
      const resLocations = await apiClient.get(`/location/locations`);
      setLocLocations(resLocations.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Get all users data
  useData(async () => {
    try {
      const resUsers = await apiClient.get(`/user/users`);
      setUsers(resUsers.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // handle showing popup and sending location data to popup
  const handleLocationFormPopUp = () => {
    setLocationData({ ...locationData, locationName: locationName });
    toggleContinueForm(true);
  };

  // handle submitting location data to database (this is called from the LocationForm component)
  const handleLocationSubmit = async (data) => {
    let response;
    try {
      if (data.isEdit) {
        response = await apiClient.put("/location/update", data);
      } else {
        response = await apiClient.post("/location/new", data);
      }
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadLocations();
    setLocationName("");
    setLocationData({});
  };

  // helper function to truncate text
  function truncateText(text, length) {
    return text?.length > length ? text?.substr(0, length - 1) + "…" : text;
  }

  // function to handle location edit button click event
  const handleLocationEdit = async (event) => {
    const id = event.target.parentNode.parentNode.id;
    const location = locLocations[id];
    location.isEdit = true;
    setLocationData(location);
    setLocationName(location.locationName);
    toggleContinueForm(true);
  };

  const handleDeleteButtonClick = (event) => {
    const id = event.target.parentNode.parentNode.id;
    const location = locLocations[id];
    setLocationData(location);
    setShowConfirmDelete(true);
  };

  // function to handle location delete button click event
  const handleLocationDelete = async (event) => {
    try {
      event.preventDefault();
      const response = await apiClient.delete(
        `/location/delete?locationId=${locationData.locationId}`
      );
      toasterSuccessMessage(response.data.message);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadLocations();
    setLocationData({});
    setShowConfirmDelete(false);
  };

  const closeDeletePopup = () => {
    setLocationData({});
    setShowConfirmDelete(false);
  };

  const tableHeaderDetails = [
    "Name of Location",
    "WeekStart",
    "WorkWeek",
    "Address",
    "Users",
    ""
  ]



  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showLocConfigPopup, setShowLocConfigPopup] = useState(false);
  const [showLocItemsPopup, setShowLocItemsPopup] = useState(false);

  const toggleLocConfigPopup = () => {
    setShowLocConfigPopup(!showLocConfigPopup);
  };

  const locConfigPopupOptions = [
    { id: 1, label: "Add New Location", onClick: handleLocationFormPopUp },
  ];

  // table items popup
  const handleLocationViewOrEditMobile = (id, display) => {
    const location = locLocations[id];
    location.isEdit = true;
    location.isView = (display === "View") ? true : false;
    setLocationData(location);
    setLocationName(location.locationName);
    toggleContinueForm(true);
  };

  const toggleLocItemsPopup = () => {
    setShowLocItemsPopup(!showLocItemsPopup);
  };

  const locItemsPopupOptions = [
    { id: 1, label: "View", onClick: handleLocationViewOrEditMobile },
    { id: 2, label: "Edit", onClick: handleLocationViewOrEditMobile },
  ];


  return (
    <div className="location-config-section">

      {isMobile &&
        <div className="config-mobile-subsection-header">
          <p>Location</p>
        </div>
      }

      {/* location table configuration  */}
      <div className="location-config-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{!isMobile ? "Name of Location" : "Location"}</th>
              <th>WeekStart</th>
              {!isMobile && <th>WorkWeek</th>}
              {!isMobile && <th>Address</th>}
              {!isMobile && <th>Users</th>}
              {!isMobile && <th>Location Status</th>}
              <th>
                {""}
                {!isMobile
                  ?
                  <button
                    className="btn-table"
                    type="button"
                    onClick={handleLocationFormPopUp}
                  >
                    Add New Location
                  </button>
                  :
                  <>
                    <IconButton
                      ariaLabel="Add approver"
                      title="Add approver"
                      styles={{
                        root: {
                          backgroundColor: '#00000000',
                          width: '20px',
                          height: '20px'
                        },
                        menuIcon: {
                          fontSize: '18',
                          marginLeft: '12px'
                        }
                      }}
                      menuIconProps={{ iconName: 'Add' }}
                      onClick={toggleContinueForm}
                    />
                    {/* <Popup
                      trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                      position="bottom right"
                      on="click"
                      open={showLocConfigPopup}
                      onClose={toggleLocConfigPopup}
                    >
                      <div className="locConfigPopupOptions-popup">
                        {locConfigPopupOptions.map((option) => (
                          <button
                            key={option.id}
                            // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                            onClick={() => option.onClick()}
                            style={{ width: '90%' }}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </Popup> */}
                  </>
                }
              </th>
            </tr>
          </thead>

          {locLocations.length > 0 &&
            <tbody>
              {locLocations.map((location, key) => {
                // change first three users adId to their full names (to display names in Users column)
                let locUsers = location.users.slice(0, 2);
                locUsers = locUsers.map((locUserId) => {
                  let userData = users.find((user) => user.adId === locUserId);
                  return userData?.fullName;
                });
                return (
                  <tr key={key} id={key}>
                    <td>{capitalizeFirstLetter(location.locationName) || "N/A"}</td>
                    <td>{capitalizeFirstLetter(location.weekstart) || "N/A"}</td>
                    {!isMobile &&
                      <td>
                        {truncateText(
                          capitalizeFirstLetter(location.workweek.join(", ")),
                          12
                        ) || "N/A"}
                      </td>
                    }
                    {!isMobile && <td>{truncateText(location.address, 12) || "N/A"}</td>}
                    {!isMobile && <td>{truncateText(locUsers.join(", "), 12) || "N/A"}</td>}
                    {!isMobile && <td>{location.status || "N/A"}</td>}
                    <td className={!isMobile ? "btn-table-column-location" : ""}>
                      {/* <button
                          className="btn-table btn-delete"
                          disabled={
                            location.locationName === "default" ? true : false
                          }
                          onClick={handleDeleteButtonClick}
                        >
                          delete
                        </button> */}
                      {!isMobile
                        ?
                        <button
                          className="btn-table btn-edit"
                          disabled={
                            location.locationName === "default" ? true : false
                          }
                          onClick={handleLocationEdit}
                        >
                          edit
                        </button>
                        :
                        <>
                          <OverflowSet
                            // key={"user-table-items-overflowset"}
                            aria-label="more actions for user"
                            items={[]}
                            overflowItems={[
                              {
                                key: 'View',
                                name: 'View',
                                iconProps: { iconName: 'EntryView' },
                                onClick: () => handleLocationViewOrEditMobile(key, "View"),
                              },
                              {
                                key: 'Edit',
                                name: 'Edit',
                                iconProps: { iconName: 'PageEdit' },
                                onClick: () => handleLocationViewOrEditMobile(key, "Edit"),
                              },
                            ]}
                            onRenderOverflowButton={onRenderOverflowButton}
                            onRenderItem={onRenderItem}
                          />
                          {/* <Popup
                            trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                            position="bottom right"
                            on="click"
                            open={showLocItemsPopup}
                            onClose={toggleLocItemsPopup}
                          >
                            <div className="locConfigPopupOptions-popup">
                              {locItemsPopupOptions.map((option) => (
                                <button
                                  key={option.id}
                                  // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                                  onClick={() => option.onClick(key, option.label)}
                                  style={{ width: '90%' }}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </Popup> */}
                        </>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          }

          {locLocations.length === 0 &&
            <tbody>
              <tr>
                <td colspan="7">
                  <EmptyDataPlaceHolder2 />
                </td>
              </tr>
            </tbody>
          }
        </table>
      </div>

      {showContinueForm && (
        <LocationForm
          apiClient={apiClient}
          user={user}
          triggerConsent={triggerConsent}
          toggleContinueForm={toggleContinueForm}
          resetLocationName={setLocationName}
          locationData={locationData}
          setLocationData={setLocationData}
          handleLocationSubmit={handleLocationSubmit}
          users={users}
        />
      )}

      {showConfirmDelete && (
        <ConfirmAction
          close={closeDeletePopup}
          submit={handleLocationDelete}
          config={confirmDeleteConfig}
        />
      )}
    </div>
  );
}

export default LocationConfig;
