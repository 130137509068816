import React from 'react'
import "./DesktopOnly.scss";
import imgPlaceholder from "../../images/placeholder-desktop.png";

function DesktopOnly() {
  return (
    <div className='desktop-view-placeholder'>
      <img src={imgPlaceholder} alt="" />
      <h1 className='placeholder-text'>This view is only available on desktop.</h1>
    </div>
  )
}

export default DesktopOnly
