import React from 'react';
import { Chart } from 'react-google-charts';
import NoData from './NoData';

const ApprovalLeaveStatus = ({ leaveRequestStats = {} }) => {
  const { pendingRequests: Pending = 0, approvedRequests: Approved = 0, rejectedRequests: Rejected = 0 } = leaveRequestStats;
  let leaveStatusData = {
    'Status': 'Count',
    Pending,
    Approved,
    Rejected
  };
  leaveStatusData = Object.entries(leaveStatusData);

  return (
    <div>
      {leaveRequestStats?.totalRequests === 0 ?
        <NoData />
        :
        <Chart
          height={'99%'}
          width={'100%'}
          chartType="PieChart"
          data={leaveStatusData}
          options={{
            colors: ['#FFA500', '#008000', '#FF0000'],
          }}
        />
      }
    </div>
  );
};

export default ApprovalLeaveStatus;
