import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function capitalizeFirstLetter(input) {
  if (typeof input === 'string') {
    return input.replace(/\b\w/g, match => match.toUpperCase());
  } else if (Array.isArray(input)) {
    return input.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  }
  // Return input as is if it's not a string or an array
  return input;
}

function countWeekdays(startDate, endDate, workWeek) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const allDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const weekendDaysIndex = [];

  if (start > end) {
    return -1;
  }

  allDays.forEach((day) => {
    for (let i = 0; i < workWeek.length; i++) {
      if (day === workWeek[i]) {
        return;
      }
    };
    weekendDaysIndex.push(allDays.indexOf(day));
    return;
  });

  let count = 0;
  let current = start;

  while (current <= end) {
    const dayOfWeek = current.getDay();
    if (!weekendDaysIndex.includes(dayOfWeek)) {
      count++;
    }

    current.setDate(current.getDate() + 1);
  }
  return count;
}

function getUniqueObjectsFromArray(arr, filterProp) {
  const uniqueObjects = {};
  const result = [];

  arr.forEach((obj) => {
    let prop = obj[filterProp];
    if (!uniqueObjects[prop]) {
      uniqueObjects[prop] = true;
      result.push(obj);
    }
  });

  return result;
}

export {
  useWindowDimensions,
  capitalizeFirstLetter,
  countWeekdays,
  getUniqueObjectsFromArray
}