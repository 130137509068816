import "./History.scss";
import React, { useState } from "react";
import { Loader } from "@fluentui/react-northstar";
// import { Link } from "react-router-dom"
import Form from "./Form";
import { useData } from "@microsoft/teamsfx-react";
import TrackPage from "./TrackPage";
import ViewPage from "./View";
import StatsUser from "./StatsUser";
import { handleBackendRequestBlockErrors, toasterErrorMessage } from "../utils/errorHandlingUtils";
import moment from "moment";
import EmptyDataPlaceHolder from "./EmptyDataPlaceHolder";
import menuImage from "../../images/menu-dots.png";
import iconFunnel from "../../images/icon-funnel.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useMediaQuery } from "react-responsive";

function History(props) {
  const { triggerConsent, apiClient, user } = props;
  const [historyData, setHistoryData] = useState([]);
  const [historyLeaveTypes, setHistoryLeaveTypes] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState();
  const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [leaveCalc, setLeaveCalc] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // Get user's leave request history, leave calculation and extract history leave types
  const { reload: reloadRequests } = useData(async () => {
    try {
      const currentYear = new Date().getFullYear();
      const resHistory = apiClient.get(`/leave/myLeaves`);
      const leaveCalculation = apiClient.get(
        `/user/leaveCalc?userAdId=${user.adId}&location=${user.appLocation}&year=${currentYear}&access=user`
      );
      setHistoryData((await resHistory).data.data);
      setLeaveCalc((await leaveCalculation).data.data);

      let historyLeaveTypes = (await resHistory).data.data.map(
        (leaveRequest) => leaveRequest.leaveType
      );
      setLoading(false);
      let uniqueHistoryLeaveTypes = [...new Set(historyLeaveTypes)];
      setHistoryLeaveTypes(uniqueHistoryLeaveTypes);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
      setLoading(false);
    }
  });

  const [showForm, setShowForm] = useState(false);

  const toggleForm = (formState) => {
    setShowForm(formState);
  };

  const [showTracker, setShowTracker] = useState(false);

  const toggleTracker = (trackerState) => {
    setShowTracker(trackerState);
  };

  const [showView, setShowView] = useState(false);

  const toggleView = (viewState) => {
    setShowView(viewState);
  };

  const [showStats, setShowStats] = useState(false);
  const toggleStats = (statsState) => {
    setShowStats(statsState);
  };

  const tableHeaderDetails = [
    "Leave Type",
    "Start Date",
    "End Date",
    "Duration",
    "Approval Status",
    "",
  ];

  const tableHeaderDetailsMobile = ["Leave Type", "Start Date", "End Date", ""];

  return (
    <div className="table-container">
      <h3>
        Leave History{" "}
        <span>
          <Popup
            className="btn-popup"
            trigger={<img src={menuImage} alt="" className="icon-submenu" />}
            position="left center"
          >
            <div className="btn-mobile-group">
              <button
                className="btn-mobile btn-mobile-leaves"
                onClick={() => {
                  toggleStats(true);
                }}
              >
                My Leaves
              </button>
              <button
                className="btn-mobile btn-mobile-new-request"
                onClick={() => {
                  toggleForm(true);
                }}
              >
                New Request
              </button>
            </div>
          </Popup>
        </span>
      </h3>

      <div className="filter-container">
        <div className="filter">
          <div className="filter-input">
            <label htmlFor="">Filter:</label>
            <select
              name=""
              id=""
              value={selectedLeaveType}
              onChange={(event) => setSelectedLeaveType(event.target.value)}
            >
              <option value="" selected disabled>
                Filter By Leave Type
              </option>
              <option value="All">All</option>
              {historyLeaveTypes.map((leaveType, key) => {
                return (
                  <option value={leaveType} key={key}>
                    {leaveType}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="filter-input filter-input-search">
            <label htmlFor="">Search:</label>
            <div className="search">
              <input
                type="search"
                name=""
                id=""
                placeholder="Search by Approval Status"
                onChange={(event) => setSearchText(event.target.value)}
              />
              <button
                className="btn-input-search"
                onClick={() => setSearchQuery(searchText)}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="filter-btn-new">
          <button
            onClick={() => {
              toggleStats(true);
            }}
            className=" btn-new btn-stats"
          >
            My Leaves
          </button>
          <button
            onClick={() => {
              toggleForm(true);
            }}
            className="btn-new"
          >
            New Request
          </button>
        </div>

        <div className="mobile-menu">
          <Popup
            trigger={
              <button className="mobile-menu-filter">
                <span className="filter-icon">
                  <img src={iconFunnel} alt="" className="icon-funnel" />
                </span>{" "}
                <span className="filter-text">Filter</span>
              </button>
            }
            position="left center"
          >
            <div className="filter-input">
              <label htmlFor="">Filter:</label>
              <select
                name=""
                id=""
                value={selectedLeaveType}
                onChange={(event) => setSelectedLeaveType(event.target.value)}
              >
                <option value="" selected disabled>
                  Filter By Leave Type
                </option>
                <option value="All">All</option>
                {historyLeaveTypes.map((leaveType, key) => {
                  return (
                    <option value={leaveType} key={key}>
                      {leaveType}
                    </option>
                  );
                })}
              </select>
            </div>
          </Popup>
        </div>
      </div>

      {loading ? (
        <div>
          <Loader style={{ margin: 100 }} />
        </div>
      ) : (
        <>
          {!isMobile && historyData.length === 0 ? (
            <EmptyDataPlaceHolder tableHeaderDetails={tableHeaderDetails} />
          ) : (
            <div className="desktop-table">
                <table>
                  <thead>
                    <tr>
                      <th>Leave Type</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Duration</th>
                      <th>Approval Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  {historyData
                    .filter(
                      (row) =>
                        (!searchQuery.length ||
                          row.approvalStatus
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toString().toLowerCase())) &&
                        (!selectedLeaveType ||
                          selectedLeaveType === "All" ||
                          row.leaveType === selectedLeaveType)
                    )
                    .map((item) => {
                      return (
                        <tbody>
                          <tr key={item.leaveRequestId} id={item.leaveRequestId}>
                            <td>{item.leaveType}</td>
                            <td>
                              {moment(item.startDate)
                                .format("DD-MMM-YYYY")
                                .toLocaleUpperCase()}
                            </td>
                            <td>
                              {moment(item.endDate)
                                .format("DD-MMM-YYYY")
                                .toLocaleUpperCase()}
                            </td>

                            <td>
                              {item.totalDays} {item.leaveUnit}
                            </td>
                            <td>
                              {item.approvalStatus === "Pending"
                                ? item.approvalStatus + " - " + item.approverRole
                                : item.approvalStatus}
                            </td>
                            <td>
                              <button
                                className="btn-table btn-track"
                                onClick={(event) => {
                                  let leaveRequestId = parseInt(
                                    event.target.parentNode.parentNode.id
                                  );
                                  setSelectedLeave(
                                    historyData.find(
                                      (leaveRequest) =>
                                        leaveRequest.leaveRequestId ===
                                        leaveRequestId
                                    )
                                  );
                                  toggleTracker(true);
                                }}
                              >
                                track
                              </button>

                              <button
                                className="btn-table btn-view"
                                onClick={(event) => {
                                  let leaveRequestId = parseInt(
                                    event.target.parentNode.parentNode.id
                                  );
                                  setSelectedLeave(
                                    historyData.find(
                                      (leaveRequest) =>
                                        leaveRequest.leaveRequestId ===
                                        leaveRequestId
                                    )
                                  );
                                  toggleView(true);
                                }}
                              >
                                view
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
            </div>
          )}

          {isMobile && historyData.length === 0 ? (
            <EmptyDataPlaceHolder
              tableHeaderDetails={tableHeaderDetailsMobile}
            />
          ) : (
            <div className="mobile-table">
                <table>
                  <thead>
                    <tr>
                      <th>Leave Type</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  {historyData
                    .filter(
                      (row) =>
                        (!searchQuery.length ||
                          row.approvalStatus
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toString().toLowerCase())) &&
                        (!selectedLeaveType ||
                          selectedLeaveType === "All" ||
                          row.leaveType === selectedLeaveType)
                    )
                    .map((item, key) => {
                      return (
                        <tbody>
                          <tr key={key} id={key}>
                            <td>{item.leaveType}</td>
                            <td>
                              {moment(item.startDate)
                                .format("DD-MMM-YYYY")
                                .toLocaleUpperCase()}
                            </td>

                            <td>
                              {moment(item.endDate)
                                .format("DD-MMM-YYYY")
                                .toLocaleUpperCase()}
                            </td>

                            <td>
                              <Popup
                                trigger={
                                  <img
                                    src={menuImage}
                                    alt=""
                                    className="table-menu-icon"
                                  />
                                }
                                position="left center"
                              >
                                <button
                                  className="btn-mobile btn-mobile-view"
                                  onClick={() => {
                                    // let leaveId = event.target.parentNode.parentNode.id;
                                    setSelectedLeave(item);
                                    toggleView(true);
                                  }}
                                >
                                  view
                                </button>
                                <button
                                  className="btn-mobile btn-mobile-track"
                                  onClick={() => {
                                    // let leaveId = event.target.parentNode.parentNode.id;
                                    setSelectedLeave(item);
                                    toggleTracker(true);
                                  }}
                                >
                                  track
                                </button>
                              </Popup>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
            </div>
          )}
        </>
      )}

      {showForm && (
        <Form
          apiClient={apiClient}
          user={user}
          toggleForm={toggleForm}
          triggerConsent={triggerConsent}
          reloadRequests={reloadRequests}
          leaveCalc={leaveCalc}
        />
      )}

      {showTracker && (
        <TrackPage
          toggleTracker={toggleTracker}
          selectedLeave={selectedLeave}
          apiClient={apiClient}
          triggerConsent={triggerConsent}
        />
      )}

      {showView && (
        <ViewPage
          toggleView={toggleView}
          selectedLeave={selectedLeave}
          apiClient={apiClient}
          triggerConsent={triggerConsent}
          user={user}
          reloadRequests={reloadRequests}
        />
      )}

      {showStats && (
        <StatsUser
          toggleStats={toggleStats}
          leaveCalc={leaveCalc}
          setLeaveCalc={{}}
          reloadLeaveCalc={{}}
          user={{}}
          access={"user"}
        />
      )}
    </div>
  );
}

export default History;
