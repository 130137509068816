import React, { useState } from "react";
import "./LeavePolicyConfig.scss";
import PolicyConfigForm from "./LeavePolicyConfigForm"
import { useData } from "@microsoft/teamsfx-react";
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { handleBackendRequestBlockErrors, toasterSuccessMessage } from "../utils/errorHandlingUtils";
import { capitalizeFirstLetter } from "../utils/generalUtils";
import ConfirmAction from "../helperComponents/ConfirmAction";
import EmptyDataPlaceHolder2 from "./EmptyDataPlaceHolder2";

import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'MoreVertical' }}
      menuProps={{ items: overflowItems }}
    />
  );
};

function LeavePolicyConfig(props) {
  const {
    apiClient,
    triggerConsent
  } = props;
  const [showPolicyForm, setShowPolicyForm] = useState(false);
  const [leavePolicies, setLeavePolicies] = useState([]);
  const [selectedLeavePolicy, setSelectedLeavePolicy] = useState();
  const [isNewPolicyForm, setIsNewPolicyForm] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const confirmDeleteConfig = {
    header: "Delete Leave Policy",
    body: `Are you sure you want to delete leave policy, ${selectedLeavePolicy?.leaveType} for ${selectedLeavePolicy?.location}?`,
    action: "Delete"
  };

  // Function to show or hide policy form
  const togglePolicyForm = (policyFormState) => {
    setShowPolicyForm(policyFormState);
  };

  // get all leave policies (excluding default location leave policies)
  const { reload: reloadLeavePolicies } = useData(async () => {
    try {
      const resLeavePolicies = await apiClient.get(`/leavePolicy/leavePolicies`);
      setLeavePolicies(resLeavePolicies.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // function to handle edit button click event
  const handleLeavePolicyEdit = async (event) => {
    const id = event.target.parentNode.parentNode.id;
    const leavePolicy = { ...leavePolicies[id] };

    // get leave types for specified location
    try {
      const resLeaveTypes = await apiClient.get(`/leaveType/leaveTypes?type=location&location=${leavePolicy.location}`);
      leavePolicy.locLeaveTypes = resLeaveTypes.data.data;
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }

    // convert 'true' and 'false' string values to boolean
    leavePolicy.cummulative = (leavePolicy.cummulative === "true") ? true : false;
    leavePolicy.paid = (leavePolicy.paid === "true") ? true : false;
    leavePolicy.unlimited = (leavePolicy.unlimited === "true") ? true : false;
    leavePolicy.requireReliever = (leavePolicy.requireReliever === "true") ? "Yes" : "No";

    setSelectedLeavePolicy(leavePolicy);
    setIsNewPolicyForm(false);
    togglePolicyForm(true);
  };

  const handleDeleteButtonClick = (event) => {
    const id = event.target.parentNode.parentNode.id;
    const leavePolicy = leavePolicies[id];
    setSelectedLeavePolicy(leavePolicy);
    setShowConfirmDelete(true);
  };

  // function to handle leave policy delete button click event
  const handleLeavePolicyDelete = async (event) => {
    try {
      event.preventDefault();
      const response = await apiClient.delete(`/leavePolicy/delete?leavePolicyId=${selectedLeavePolicy.leavePolicyId}`);
      toasterSuccessMessage(response.data.message);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadLeavePolicies();
    setSelectedLeavePolicy();
    setShowConfirmDelete(false);
  }

  const closeDeletePopup = () => {
    setSelectedLeavePolicy();
    setShowConfirmDelete(false);
  };






  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showLeavePolicyConfigPopup, setShowLeavePolicyConfigPopup] = useState(false);
  const [showLeavePolicyItemsPopup, setShowLeavePolicyItemsPopup] = useState(false);
  const [isViewForm, setIsViewForm] = useState(false);

  const toggleLeavePolicyConfigPopup = () => {
    setShowLeavePolicyConfigPopup(!showLeavePolicyConfigPopup);
  };

  const leavePolicyConfigPopupOptions = [
    { id: 1, label: "Add New Policy", onClick: () => togglePolicyForm(true) },
  ];

  // table items popup
  const handleLeavePolicyViewOrEditMobile = async (id, display) => {
    const leavePolicy = { ...leavePolicies[id] };

    // get leave types for specified location
    try {
      const resLeaveTypes = await apiClient.get(`/leaveType/leaveTypes?type=location&location=${leavePolicy.location}`);
      leavePolicy.locLeaveTypes = resLeaveTypes.data.data;
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }

    // convert 'true' and 'false' string values to boolean
    leavePolicy.cummulative = (leavePolicy.cummulative === "true") ? true : false;
    leavePolicy.paid = (leavePolicy.paid === "true") ? true : false;
    leavePolicy.unlimited = (leavePolicy.unlimited === "true") ? true : false;
    leavePolicy.requireReliever = (leavePolicy.requireReliever === "true") ? "Yes" : "No";

    setSelectedLeavePolicy(leavePolicy);
    setIsNewPolicyForm(false);
    if (display === "View") {
      setIsViewForm(true);
    } else {
      setIsViewForm(false);
    }
    togglePolicyForm(true);
  };

  const handleDeleteButtonClickMobile = (id) => {
    const leavePolicy = leavePolicies[id];
    setSelectedLeavePolicy(leavePolicy);
    setShowConfirmDelete(true);
  };

  const toggleLeavePolicyItemsPopup = () => {
    setShowLeavePolicyItemsPopup(!showLeavePolicyItemsPopup);
  };

  const leavePolicyItemsPopupOptions = [
    { id: 1, label: "View", onClick: (id) => handleLeavePolicyViewOrEditMobile(id, "View") },
    { id: 2, label: "Edit", onClick: (id) => handleLeavePolicyViewOrEditMobile(id, "Edit") },
    { id: 3, label: "Delete", onClick: (id) => handleDeleteButtonClickMobile(id) }
  ];

  return (
    <div className="leave-policy-config-section">

      {isMobile &&
        <div className="config-mobile-subsection-header">
          <p>Leave Policies</p>
        </div>
      }

      <div className="leave-policy-config-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Leave Type</th>
              <th>Location</th>
              {!isMobile && <th>Yearly Quota</th>}
              {!isMobile && <th>Cumulative</th>}
              {!isMobile && <th>Paid</th>}
              {!isMobile && <th>Unlimited</th>}
              {!isMobile && <th>Require Reliever</th>}
              <th className={!isMobile ? "leave-policy-table-button-column" : ""}>
                {" "}
                {!isMobile
                  ?
                  <button
                    id="btn-add-policy"
                    className="btn-table"
                    onClick={() => togglePolicyForm(true)}
                  >
                    add new policy
                  </button>
                  :
                  <>
                    <IconButton
                      ariaLabel="Add approver"
                      title="Add approver"
                      styles={{
                        root: {
                          backgroundColor: '#00000000',
                          width: '20px',
                          height: '20px'
                        },
                        menuIcon: {
                          fontSize: '18',
                          marginLeft: '12px'
                        }
                      }}
                      menuIconProps={{ iconName: 'Add' }}
                      onClick={() => togglePolicyForm(true)}
                    />
                    {/* <Popup
                      trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                      position="bottom right"
                      on="click"
                      open={showLeavePolicyConfigPopup}
                      onClose={toggleLeavePolicyConfigPopup}
                    >
                      <div className="locConfigPopupOptions-popup">
                        {leavePolicyConfigPopupOptions.map((option) => (
                          <button
                            key={option.id}
                            // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                            onClick={() => option.onClick()}
                            style={{ width: '90%' }}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </Popup> */}
                  </>
                }
              </th>
            </tr>
          </thead>

          {leavePolicies.length > 0 &&
            <tbody>
              {leavePolicies.map((leavePolicy, key) => {
                return (
                  <tr key={key} id={key}>
                    <td>{leavePolicy.leaveType}</td>
                    <td>{capitalizeFirstLetter(leavePolicy.location)}</td>
                    {!isMobile && <td>{(leavePolicy.yearlyQuota) ? `${leavePolicy.yearlyQuota} ${leavePolicy.leaveUnit}` : "Unlimited"}</td>}
                    {!isMobile && <td>{(leavePolicy.cummulative === "true") ? "Yes" : "No"}</td>}
                    {!isMobile && <td>{(leavePolicy.paid === "true") ? "Yes" : "No"}</td>}
                    {!isMobile && <td>{(leavePolicy.unlimited === "true") ? "Yes" : "No"}</td>}
                    {!isMobile && <td>{(leavePolicy.requireReliever === "true") ? "Yes" : "No"}</td>}
                    <td>
                      {!isMobile
                        ?
                        <>
                          <button className="btn-table btn-delete" onClick={handleDeleteButtonClick}>delete</button>
                          <button className="btn-table btn-edit" onClick={handleLeavePolicyEdit}>edit</button>
                        </>
                        :
                        <>
                          <OverflowSet
                            // key={"user-table-items-overflowset"}
                            aria-label="more actions for user"
                            items={[]}
                            overflowItems={[
                              {
                                key: 'View',
                                name: 'View',
                                iconProps: { iconName: 'EntryView' },
                                onClick: () => handleLeavePolicyViewOrEditMobile(key, "View"),
                              },
                              {
                                key: 'Edit',
                                name: 'Edit',
                                iconProps: { iconName: 'PageEdit' },
                                onClick: () => handleLeavePolicyViewOrEditMobile(key, "Edit"),
                              },
                              {
                                key: 'Delete',
                                name: 'Delete',
                                iconProps: { iconName: 'Delete' },
                                onClick: () => handleDeleteButtonClickMobile(key),
                              },
                            ]}
                            onRenderOverflowButton={onRenderOverflowButton}
                            onRenderItem={onRenderItem}
                          />
                          {/* <Popup
                            trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                            position="bottom right"
                            on="click"
                            open={showLeavePolicyItemsPopup}
                            onClose={toggleLeavePolicyItemsPopup}
                          >
                            <div className="locConfigPopupOptions-popup">
                              {leavePolicyItemsPopupOptions.map((option) => (
                                <button
                                  key={option.id}
                                  // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                                  onClick={() => option.onClick(key)}
                                  style={{ width: '90%' }}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </Popup> */}
                        </>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          }

          {leavePolicies.length === 0 &&
            <tbody>
              <tr>
                <td colspan="8">
                  <EmptyDataPlaceHolder2 />
                </td>
              </tr>
            </tbody>
          }
        </table>
      </div>

      {showPolicyForm && (
        <PolicyConfigForm
          togglePolicyForm={togglePolicyForm}
          apiClient={apiClient}
          triggerConsent={triggerConsent}
          leavePolicies={leavePolicies}
          selectedLeavePolicy={selectedLeavePolicy}
          reloadLeavePolicies={reloadLeavePolicies}
          isNewPolicyForm={isNewPolicyForm}
          setSelectedLeavePolicy={setSelectedLeavePolicy}
          setIsNewPolicyForm={setIsNewPolicyForm}
          isViewForm={isViewForm}
        />
      )}

      {showConfirmDelete && (
        <ConfirmAction
          close={closeDeletePopup}
          submit={handleLeavePolicyDelete}
          config={confirmDeleteConfig}
        />
      )}
    </div>
  );
}

export default LeavePolicyConfig;
