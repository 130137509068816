import React, { useState } from "react";
import "./History.scss";
import "./Approval.scss";
import ApprovalAction from "./ApprovalAction";
import { useData } from "@microsoft/teamsfx-react";
import { handleBackendRequestBlockErrors } from "../utils/errorHandlingUtils";
import moment from "moment";
import EmptyDataPlaceHolder from "./EmptyDataPlaceHolder";
import DateFilter from "./DatePicker";
import iconFunnel from "../../images/icon-funnel.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useMediaQuery } from "react-responsive";
import { Loader } from "@fluentui/react-northstar";

function Approval(props) {
  const { triggerConsent, apiClient } = props;
  const [approvalData, setApprovalData] = useState([]);
  const [historyLeaveTypes, setHistoryLeaveTypes] = useState([]);
  const [approvalAction, setApprovalAction] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState();
  const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const toggleApprovalACtion = () => {
    setApprovalAction(!approvalAction);
  };

  //TableHeader details
  const tableHeaderDetails = [
    "Full Name",
    "Leave Type",
    "Start Date",
    "End Date",
    "Duration",
    "Approval Status",
    "",
  ];

  const tableHeaderDetailsMobile = [
    "Full Name",
    "Leave Type",
    "Approval Status",
    "",
  ];

  // Get leave request(s) assigned to user
  const { reload: reloadRequests } = useData(async () => {
    try {
      const response = await apiClient.get(`/leave/assignedLeaves`);
      setApprovalData(response.data.data);

      let historyLeaveTypes = response.data.data.map(
        (leaveRequest) => leaveRequest.leaveType
      );
      let uniqueHistoryLeaveTypes = [...new Set(historyLeaveTypes)];
      setHistoryLeaveTypes(uniqueHistoryLeaveTypes);
      setLoading(false);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
      setLoading(false);
    }
  });

  const onDateChange = (start, end) => {
    setFilterStartDate(start);
    setFilterEndDate(end);
  };

  return (
    <div className="table-container">
      <h3>Approval History</h3>

      <div className="table-filter">
        <div className="desktop-table-filter-section">
          <div className="filter-div">
            <label htmlFor="">Filter:</label>
            <select
              name=""
              id=""
              value={selectedLeaveType}
              onChange={(event) => setSelectedLeaveType(event.target.value)}
            >
              <option value="Filter By Leave Type" selected disabled>
                Filter By Leave Type
              </option>
              <option value="All">All</option>
              {historyLeaveTypes.map((leaveType, key) => {
                return (
                  <option value={leaveType} key={key}>
                    {leaveType}
                  </option>
                );
              })}
            </select>
          </div>

          <DateFilter onDateChange={onDateChange} />

          <div className="filter-div">
            <label htmlFor="">Search:</label>
            <div className="search">
              <input
                type="search"
                name=""
                id=""
                placeholder="Search By Full Name"
                onChange={(event) => setSearchText(event.target.value)}
              />
              <button
                className="btn-input-search"
                onClick={() => setSearchQuery(searchText)}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="desktop-table-filter-section mobile-table-filter-section">
          <Popup
            trigger={
              <button className="mobile-menu-filter">
                <span className="filter-icon">
                  <img src={iconFunnel} alt="" className="icon-funnel" />
                </span>{" "}
                <span className="filter-text">Filter</span>
              </button>
            }
            position="left center"
          >
            <div className="filter-input">
              <label htmlFor="">Filter:</label>
              <select
                name=""
                id=""
                value={selectedLeaveType}
                onChange={(event) => setSelectedLeaveType(event.target.value)}
              >
                <option value="Filter By Leave Type" selected disabled>
                  Filter By Leave Type
                </option>
                <option value="All">All</option>
                {historyLeaveTypes.map((leaveType, key) => {
                  return (
                    <option value={leaveType} key={key}>
                      {leaveType}
                    </option>
                  );
                })}
              </select>
            </div>

            <DateFilter onDateChange={onDateChange} />
          </Popup>
        </div>
      </div>

      {loading ? (
        <div>
          <Loader style={{ margin: 100 }} />
        </div>
      ) : (
        <>
          {!isMobile && approvalData.length === 0 ? (
            <EmptyDataPlaceHolder tableHeaderDetails={tableHeaderDetails} />
          ) : (
            <div className="desktop-table-section">
              <table>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Leave Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Duration</th>
                    <th>Approval Status</th>
                    <th></th>
                  </tr>
                </thead>

                {approvalData
                  .filter(
                    (row) =>
                      (!searchQuery.length ||
                        row.fullName
                          .toString()
                          .toLowerCase()
                          .includes(searchQuery.toString().toLowerCase())) &&
                      (!selectedLeaveType ||
                        selectedLeaveType === "All" ||
                        row.leaveType === selectedLeaveType) &&
                      (!filterEndDate ||
                        (new Date(row.createdAt).toLocaleDateString("sv-SE") >=
                          filterStartDate &&
                          new Date(row.createdAt).toLocaleDateString("sv-SE") <=
                            filterEndDate))
                  )
                  .map((item) => {
                    return (
                      <tbody>
                        <tr key={item.leaveRequestId} id={item.leaveRequestId}>
                          <td>{item.fullName}</td>
                          <td>{item.leaveType}</td>
                          <td>
                            {moment(item.startDate)
                              .format("DD-MMM-YYYY")
                              .toLocaleUpperCase()}
                          </td>
                          <td>
                            {moment(item.endDate)
                              .format("DD-MMM-YYYY")
                              .toLocaleUpperCase()}
                          </td>
                          <td>
                            {item.totalDays} {item.leaveUnit}
                          </td>
                          <td>
                            {item.approvalStatus === "Pending"
                              ? item.approvalStatus + " - " + item.approverRole
                              : item.approvalStatus}
                          </td>
                          <td>
                            <button
                              className="btn-table btn-action"
                              onClick={(event) => {
                                toggleApprovalACtion(true);
                                let leaveRequestId = parseInt(
                                  event.target.parentNode.parentNode.id
                                );
                                setSelectedLeave(
                                  approvalData.find(
                                    (leaveRequest) =>
                                      leaveRequest.leaveRequestId ===
                                      leaveRequestId
                                  )
                                );
                              }}
                            >
                              approve/reject
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          )}

          {isMobile && approvalData.length === 0 ? (
            <EmptyDataPlaceHolder tableHeaderDetails={tableHeaderDetailsMobile} />
          ) : (
            <div  className="mobile-table-section">
                <table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Leave Type</th>
                      <th>Approval Status</th>
                      <th></th>
                    </tr>
                  </thead>

                  {approvalData
                    .filter(
                      (row) =>
                        (!searchQuery.length ||
                          row.fullName
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toString().toLowerCase())) &&
                        (!selectedLeaveType ||
                          selectedLeaveType === "All" ||
                          row.leaveType === selectedLeaveType)
                    )
                    .map((item, key) => {
                      return (
                        <tbody>
                          <tr key={key} id={key}>
                            <td>{item.fullName}</td>
                            <td>{item.leaveType}</td>

                            <td>
                              {item.approvalStatus === "Pending"
                                ? item.approvalStatus + " - " + item.approverRole
                                : item.approvalStatus}
                            </td>
                            <td>
                              <button
                                className="btn-table btn-action"
                                onClick={(event) => {
                                  toggleApprovalACtion(true);
                                  let leaveId =
                                    event.target.parentNode.parentNode.id;
                                  setSelectedLeave(approvalData[leaveId]);
                                }}
                              >
                                approve/reject
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
            </div>
          )}
        </>
      )}

      {approvalAction && (
        <ApprovalAction
          toggleApprovalACtion={toggleApprovalACtion}
          triggerConsent={triggerConsent}
          apiClient={apiClient}
          selectedLeave={selectedLeave}
          reloadRequests={reloadRequests}
        />
      )}
    </div>
  );
}

export default Approval;
