import React from 'react';
import { Chart } from 'react-google-charts';
import NoData from './NoData';

const ApprovedLeaveChart = ({ approvedLeavesPerLocation = [] }) => {
  return (
    <div>
      {approvedLeavesPerLocation?.length < 2 ?
        <NoData />
        :
        <Chart
          height={'99%'}
          width={'100%'}
          chartType="AreaChart"
          data={approvedLeavesPerLocation}
          options={{
            hAxis: {
              title: 'Location',
            },
            vAxis: {
              title: 'Approved Leave',
            },
            legend: { position: 'bottom' },
          }}
        />
      }
    </div>
  );
};

export default ApprovedLeaveChart;
