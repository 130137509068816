import { useContext, useState } from "react";
// import { Welcome } from "./helperComponents/Welcome";
import { TeamsFxContext } from "./Context";
import config from "./utils/config";

import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { useData } from "@microsoft/teamsfx-react";
import Consent from "./helperComponents/Consent";
import { Loader } from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import {
  ErrorBoundary,
  handleBackendRequestBlockErrors,
} from "./utils/errorHandlingUtils";
import { Toaster } from "react-hot-toast";
import History from "./appComponents/History";
import Navbar from "./appComponents/Navbar";
import Approval from "./appComponents/Approval";
import Settings from "./appComponents/Settings";
import { useWindowDimensions } from "./utils/generalUtils";
// import Form from "./appComponents/Form"
import AllRequests from "./appComponents/AllRequests";
import NewRequest from "./appComponents/NewRequest";
import DesktopOnly from "./appComponents/DesktopOnly";
import Dashboard from "./appComponents/dashboard/Dashboard";

/* TODO
  i. To format dates coming from the db appropriately
  ii. To disable/hide edit button until reliever data arrives to avoid empty reliever on the edit page (in view.jsx)
*/

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  const { height, width } = useWindowDimensions();
  const [needConsent, setNeedConsent] = useState();
  const [selectedNav, setSelectedNav] = useState("Dashboard");
  const [user, setUser] = useState();

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // Create API client
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
  if (!teamsUserCredential) {
    // TODO: Replace this with a toaster error popup.
    throw new Error("TeamsFx SDK is not initialized.");
  }
  const apiBaseUrl = config.apiEndpoint + "/api/";
  const apiClient = createApiClient(
    apiBaseUrl,
    new BearerTokenAuthProvider(
      async () => (await teamsUserCredential.getToken("")).token
    )
  );

  // Test to print sso token
  // (async () => {
  //   const token = (await teamsUserCredential.getToken("")).token;
  //   console.log(apiBaseUrl);
  //   console.log("....................", token);
  // })();

  // function to show or hide consent page when consent is needed here or down in the component tree
  const triggerConsent = (booleanValue) => {
    setNeedConsent(booleanValue);
  };

  const selectNav = async (nav) => {
    setSelectedNav(nav);
  }

  // call consent endpoint to check if there is a need to consent to permissions
  const { loading } = useData(async () => {
    try {
      const response = await apiClient.get("/user/consent");
      if (response.data.data === true) {
        triggerConsent(false);
      }

      // Get logged-in user's data from the database and PowerBI report embed details
      let responseUser = await apiClient.get("/user/user");
      responseUser = responseUser.data.data;
      setUser(responseUser);
      // set default nav item to "User Requests" if user role is ordinary, if not, get PowerBI config
      if (!["Owner", "Admin"].includes(responseUser?.role)) {
        if (responseUser?.role === "User") {
          selectNav("User Requests")
        } else {
          selectNav("New Request");
        }
      } else {
        await selectNav(selectedNav);
      }
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  return (
    <div className={themeString === "default" ? "" : "dark"}>
      <ErrorBoundary>
        {loading && <Loader />}
        {!loading && (
          <div>
            {needConsent ? (
              <Consent triggerConsent={triggerConsent} />
            ) : (
              <div>
                <Navbar selectNav={selectNav} user={user} />
                {selectedNav === "Dashboard" && ["Owner", "Admin"].includes(user?.role)
                  &&
                  <Dashboard
                    triggerConsent={triggerConsent}
                    apiClient={apiClient}
                  />
                }
                {selectedNav === "New Request" && (
                  <NewRequest
                    triggerConsent={triggerConsent}
                    apiClient={apiClient}
                    user={user}
                  />
                )}
                {selectedNav === "User Requests" && (
                  <History
                    triggerConsent={triggerConsent}
                    apiClient={apiClient}
                    user={user}
                  />
                )}
                {selectedNav === "All Requests" && ["Owner", "Admin"].includes(user?.role) && (
                  <AllRequests
                    triggerConsent={triggerConsent}
                    apiClient={apiClient}
                    user={user}
                  />
                )}
                {selectedNav === "Approvals" && (
                  <Approval
                    triggerConsent={triggerConsent}
                    apiClient={apiClient}
                    user={user}
                  />
                )}

                {selectedNav === "Settings" && ["Owner", "Admin"].includes(user?.role) && (
                  <Settings
                    triggerConsent={triggerConsent}
                    apiClient={apiClient}
                    user={user}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <Toaster toastOptions={{ duration: 5000 }} />
      </ErrorBoundary>
    </div>
  );
}