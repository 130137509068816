import React, { useState } from "react";
import "./TrackPage.scss";
import LeaveTrackerImg from "../../images/leavetracker.png";
import btn_Cancel from "../../images/cancel.png";
import { useData } from "@microsoft/teamsfx-react";
import { handleBackendRequestBlockErrors } from "../utils/errorHandlingUtils";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
// import { info } from "cli";

function TrackPage(props) {
  const { selectedLeave, apiClient, toggleTracker, triggerConsent } = props;
  const [trackerData, setTrackerData] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // Get approval logs for leave request
  useData(async () => {
    try {
      let response = await apiClient.get(
        `/leave/track?leaveRequestId=${selectedLeave.leaveRequestId}`
      );
      response = response.data.data.sort((a, b) =>
        a.approvalLevel > b.approvalLevel
          ? 1
          : b.approvalLevel > a.approvalLevel
          ? -1
          : 0
      );
      setTrackerData(response);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  return (
    <div className="tracker-container">
      {isMobile ? (
        <div className="track-page">
          <div className="cancel-div">
            <h4>Track Leave Request</h4>
            <img
              src={btn_Cancel}
              alt="cancel button"
              className="btn-cancel"
              onClick={() => {
                toggleTracker(false);
              }}
            />
          </div>
          <div className="page-header">
            <div className="header-img">
              <img src={LeaveTrackerImg} alt="leave tracker header" />
            </div>

            {/* {trackerData.map((item, key) => {
             return (
                <div className="header-texts" key={key}>
                   <h4>{item.leaveType}</h4>
                   <p>{item.totalDays}</p>
                   <h4>{item.approvalStatus}</h4>
                </div>
             )
           })} */}
            <div className="header-texts">
              <h4>
                <span className="title-span">Leave Type: </span>{" "}
                {selectedLeave?.leaveType}
              </h4>
              <h6>
                <span className="title-span">Duration: </span>{" "}
                {selectedLeave?.totalDays} {selectedLeave?.leaveUnit}{" "}
                {selectedLeave?.leaveUnit === "hours"
                  ? "(" +
                    selectedLeave?.startTime +
                    " - " +
                    selectedLeave?.endTime +
                    ")"
                  : ""}
              </h6>
              <h4>
                <span className="title-span">Approval Status:</span>{" "}
                {selectedLeave?.approvalStatus}
              </h4>
            </div>
          </div>

          <div className="tracker-details">
            {trackerData?.map((approver, index) => {
              return (
                <div className="tracker-details-step">
                  <div className="tracker-details-status">
                    <span className="tracker-details-status-dot"></span>
                    <span className="tracker-details-status-line"></span>
                  </div>
                  <div className="tracker-details-text">
                    <h4 className="tracker-details-text-role">
                      {approver?.approverRole}
                    </h4>

                    <p className="tracker-details-text-stat">
                      {approver?.approvalStatus}{" "}
                      <span className="vertical-line">|</span>
                      <span className="tracker-details-text-sub">
                        {approver?.approvalDate
                          ? moment(approver?.approvalDate)
                              .format("DD-MMM-YYYY")
                              .toLocaleUpperCase()
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="close-tracker">
            <button
              className="btn btn-close-tracker"
              onClick={() => {
                toggleTracker(false);
              }}
            >
              close
            </button>
          </div>
        </div>
      ) : (
        <div className="track-page">
          <div className="cancel-div">
            <h4>Track Leave Request</h4>
            <img
              src={btn_Cancel}
              alt="cancel button"
              className="btn-cancel"
              onClick={() => {
                toggleTracker(false);
              }}
            />
          </div>
          <div className="page-header">
            <div className="header-img">
              <img src={LeaveTrackerImg} alt="leave tracker header" />
            </div>

            {/* {trackerData.map((item, key) => {
             return (
                <div className="header-texts" key={key}>
                   <h4>{item.leaveType}</h4>
                   <p>{item.totalDays}</p>
                   <h4>{item.approvalStatus}</h4>
                </div>
             )
           })} */}
            <div className="header-texts">
              <h4>
                <span className="title-span">Leave Type: </span>{" "}
                {selectedLeave?.leaveType}
              </h4>
              <h6>
                <span className="title-span">Duration: </span>{" "}
                {selectedLeave?.totalDays} {selectedLeave?.leaveUnit}{" "}
                {selectedLeave?.leaveUnit === "hours"
                  ? "(" +
                    selectedLeave?.startTime +
                    " - " +
                    selectedLeave?.endTime +
                    ")"
                  : ""}
              </h6>
              <h4>
                <span className="title-span">Approval Status:</span>{" "}
                {selectedLeave?.approvalStatus}
              </h4>
            </div>
          </div>

          <div className="tracker-details">
            {trackerData?.map((approver, index) => {
              return (
                <div className="tracker-details-step">
                  <div className="tracker-details-status">
                    <span className="tracker-details-status-dot"></span>
                    <span className="tracker-details-status-line"></span>
                  </div>
                  <div className="tracker-details-text">
                    <h4 className="tracker-details-text-role">
                      {approver?.approverRole}
                    </h4>

                    <p className="tracker-details-text-stat">
                      {approver?.approvalStatus}{" "}
                      <span className="vertical-line">|</span>
                      <span className="tracker-details-text-sub">
                        {approver?.approvalDate
                          ? moment(approver?.approvalDate)
                              .format("DD-MMM-YYYY")
                              .toLocaleUpperCase()
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="close-tracker">
            <button
              className="btn btn-close-tracker"
              onClick={() => {
                toggleTracker(false);
              }}
            >
              close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrackPage;
