import React, { useState } from "react";
import "./Form.scss";
import "./NewRequest.scss";
import { useData } from "@microsoft/teamsfx-react";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  handleBackendRequestBlockErrors,
  toasterSuccessMessage,
  toasterErrorMessage,
} from "../utils/errorHandlingUtils";
import { countWeekdays } from "../utils/generalUtils";
import { useMediaQuery } from "react-responsive";

function NewRequest(props) {
  const { apiClient, user, triggerConsent } = props;
  const [leaveType, setLeaveType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalTime, setTotalTime] = useState(0);
  const [reason, setReason] = useState("");
  const [relieverADId, setRelieverADId] = useState("");
  const [users, setUsers] = useState([]);
  const [myLeaveTypes, setMyLeaveTypes] = useState([]);
  const [showRequireRelieverField, setShowRequireRelieverField] =
    useState(false);
  const [defaultManager, setDefaultManager] = useState({});

  const [leaveCalc, setLeaveCalc] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState({});
  const [calcForSelectedLeaveType, setCalcForSelectedLeaveType] = useState([]);
  const [leaveBalanceText, setLeaveBalanceText] = useState("");
  const [disableEndDate, setDisableEndDate] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const currentUser = user?.adId;

  // Get all users' data, user's location-based leave types and leave statistics
  const { loading } = useData(async () => {
    try {
      // check if logged-in user does not have a line manager and disable submit button
      // if (user?.manager?.fullName === "No line manager found") {
      //   setDisableSubmitButton(true);
      // }

      const currentYear = new Date().getFullYear();
      const resLeaveTypes = apiClient.get(
        `/leaveType/leaveTypes?type=user&location=${user?.appLocation}`
      );
      const resLeaveCalculation = apiClient.get(
        `/user/leaveCalc?userAdId=${user?.adId}&location=${user?.appLocation}&year=${currentYear}&access=user`
      );
      let resUsers = apiClient.get(`/user/users`);

      if (user?.locationStatus === "Active") {
        setMyLeaveTypes((await resLeaveTypes).data.data);
      }

      setLeaveCalc((await resLeaveCalculation).data.data);
      setUsers((await resUsers).data.data);

      // set a default line-manager for users without line-manager
      // if (["Owner", "Admin", "User"].includes(user?.role)) {
      //   const defaultManager = (await resUsers).data.data.find(
      //     (user) => user.role === "Owner"
      //   );
      //   setDefaultManager(defaultManager);
      // } else {
      //   setDefaultManager({
      //     adId: "",
      //     fullName: "No line manager found",
      //     email: ""
      //   });
      // }
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // check if user has a line manager
    if (user?.manager?.fullName === "No line manager found") {
      toasterErrorMessage("No line manager found! \nKindly reach out to your admin to assign you a line manager.");
      return;
    }

    try {
      const reliever = users?.find((user) => user.adId === relieverADId);
      let data = {
        userId: user.adId,
        fullName: user.fullName,
        email: user.email,
        department: user.adDepartment,
        jobTitle: user.jobTitle,
        location: user.appLocation,
        leaveType: leaveType,
        startDate: moment(startDate2).format("YYYY-MM-DD"),
        endDate: selectedLeaveType.leaveUnit === "days" ? moment(endDate2).format("YYYY-MM-DD") : moment(startDate2).format("YYYY-MM-DD"),
        startTime: selectedLeaveType.leaveUnit === "days" ? "00:00" : moment(startTime2).format('HH:mm'),
        endTime: selectedLeaveType.leaveUnit === "days" ? "00:00" : moment(endTime2).format('HH:mm'),
        // startDate: startDate,
        // endDate: selectedLeaveType.leaveUnit === "days" ? endDate : startDate,
        // startTime: selectedLeaveType.leaveUnit === "days" ? "00:00" : startTime,
        // endTime: selectedLeaveType.leaveUnit === "days" ? "00:00" : endTime,
        totalDays: totalTime,
        reason: reason,
        leaveUnit: selectedLeaveType.leaveUnit,
        reliever: !reliever
          ? undefined
          : {
            id: reliever.adId,
            displayName: reliever.fullName,
            mail: reliever.email,
            role: "Reliever",
          },
        lineManager: {
          id: user?.manager?.adId || defaultManager.adId,
          displayName: user?.manager?.fullName || defaultManager.fullName,
          mail: user?.manager?.email || defaultManager.email,
          role: "Line Manager",
        },
        createdAt: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      };
      const response = await apiClient.post("/leave/new", data);
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }

    //reset the form after submission
    setLeaveType("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setTotalTime("");
    setRelieverADId("");
    setReason("");
    setLeaveBalanceText("");

    // remove during cleanup
    setStartDate2("");
    setEndDate2("");
    setStartTime2("");
    setEndTime2("");
  };

  const resetForm = function () {
    setLeaveType("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setTotalTime("");
    setRelieverADId("");
    setReason("");
    setLeaveBalanceText("");
    toasterErrorMessage("Leave request was cancelled");
  };

  // Function to handle calculating total days (or hours) and leave type days validation (for days leave unit)
  let calcTotalTime = async (end, start) => {
    if (!selectedLeaveType.leaveUnit) {
      toasterErrorMessage("Please select a leave type!");
      setStartDate("");
      setEndDate("");
      return;
    }

    // total time calculation for leave type (with hours as leave unit)
    if (!!end && !!start && selectedLeaveType.leaveUnit === "hours") {
      end = new Date("01/01/2001 " + end);
      start = new Date("01/01/2001 " + start);
      let total = (end - start) / (1000 * 3600);

      if (total < 0) {
        toasterErrorMessage("End time is before start time.");
        setEndTime("");
        setTotalTime(0);
        return;
      }

      if (
        selectedLeaveType.unlimited === "false" &&
        total > calcForSelectedLeaveType.balance
      ) {
        toasterErrorMessage(
          "Your balance is insufficient to make this request!"
        );
        setDisableSubmitButton(true);
        return;
      }

      setDisableSubmitButton(false); // this is to enable submit button if user reduces their request to meet their balance
      setTotalTime(total);
      return;
    }

    // total time calculation for leave type (with days as leave unit)
    let total = countWeekdays(start, end, user.workweek);

    setTotalTime(total ? total : 0);

    if (leaveType) {
      if (
        selectedLeaveType.unlimited === "false" &&
        total > calcForSelectedLeaveType.balance
      ) {
        toasterErrorMessage(
          "Your balance is insufficient to make this request!"
        );
        setDisableSubmitButton(true);
        return;
      }
      setDisableSubmitButton(false); // this is to enable submit button if user reduces their request to meet their balance
    } else {
      toasterErrorMessage("Please select a leave type!");
      setStartDate("");
      setEndDate("");
    }
  };

  //function to convert time format to 24hrs from 12hrs
  const convertTime = timeStr => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  //function to validate start time
  let calcStartTime = async () => {
    // Get current time
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();

    // Convert start time to 24-hour format
    const convertedStartTime = convertTime(startTime);

    // Parse converted start time string into hours and minutes
    const selectedTimeParts = convertedStartTime.split(":");
    const startHour = parseInt(selectedTimeParts[0]);
    const startMinute = parseInt(selectedTimeParts[1]);

    // Compare converted start time with current time
    if (startHour < currentHours || (startHour === currentHours && startMinute < currentMinutes)) {
      // Start time is before current time
      toasterErrorMessage("Start time cannot be before current time.");
      setStartTime("");
      return;
    }
  };


  const onLeaveTypeInputFocus = () => {
    if (user?.role === "Guest") {
      toasterErrorMessage("Kindly notify your admin to synchronize users.");
    } else if (user?.locationStatus === "Inactive") {
      toasterErrorMessage("No leave type found!\nKindly contact your admin to reactivate your Location or assign you a new location.")
    } else if (!user?.appLocation) {
      toasterErrorMessage("No Leave Type found! \nPlease contact your admin to assign you a location.");
    } else if (!!user?.appLocation === true && myLeaveTypes.length < 1) {
      toasterErrorMessage("No Leave Type found! \nPlease contact your admin to assign your location a leave type and/or leave policy.");
    }
  }


  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");
  const [startTime2, setStartTime2] = useState("");
  const [endTime2, setEndTime2] = useState("");
  const [totalTime2, setTotalTime2] = useState(0);
  const [minStartTime, setMinStartTime] = useState();
  const [minEndTime, setMinEndTime] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [disableTimeControls, setDisableTimeControls] = useState(true);
  const [disableEndDate2, setDisableEndDate2] = useState(true);

  const calcInitialTimeValuesAndTotalTime = (date) => {
    const currentDate = new Date();
    let startTime;
    let endTime;

    // Check if the selected date is today
    const isToday =
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear();


    // If it's today, set the time to the current time
    if (isToday) {
      const currentDayHour = currentDate.getHours();
      startTime = new Date(date.setHours(currentDayHour + 1, 0));
      endTime = new Date(date.setHours(currentDayHour + 2, 0));

      setStartTime2(startTime);
      setEndTime2(endTime);

      setMinStartTime(new Date(startTime));
      setMinEndTime(new Date(endTime));
    } else {
      // If it's not today, set the time to 00:00
      date.setHours(0);
      date.setMinutes(0);

      startTime = new Date(date);
      endTime = new Date(date.setHours(1, 0));
      // set start and end times, and minstart and minend
      setStartTime2(startTime);
      setEndTime2(endTime);

      setMinStartTime(startTime);
      setMinEndTime(endTime);
    }
    setDisableTimeControls(false);

    // calculate total time
    calcLeaveTotalTimeOrDate(endTime, startTime);
  }

  const calcInitialEndDateValuesAndTotalTime = (date) => {
    const endDate = new Date(date).setDate(new Date(date).getDate());
    setEndDate2(endDate);
    setMinEndDate(endDate);

    setDisableEndDate2(false);
    calcLeaveTotalTimeOrDate(new Date(endDate), date);
  }

  const leaveTypeValidations = () => {
    let message = "";
    let validationState;
    if (!selectedLeaveType.leaveType) {
      message = "Kindly select a leaveType."
      validationState = "Passed"
    }
  }

  const checkFormFields = () => {

  }

  const calcLeaveTotalTimeOrDate = (end, start) => {
    let total;

    if (selectedLeaveType.leaveUnit === "hours") {
      // total time calculation for leave type (with hours as leave unit)
      total = (end - start) / (1000 * 3600);
      setTotalTime(total);
    } else if (selectedLeaveType.leaveUnit === "days") {
      // total time calculation for leave type (with days as leave unit)
      total = countWeekdays(start, end, user.workweek);
      setTotalTime(total);
    }

    if (leaveType) {
      if (
        selectedLeaveType.unlimited === "false" &&
        total > calcForSelectedLeaveType.balance
      ) {
        toasterErrorMessage(
          "Your balance is insufficient to make this request!"
        );
        setDisableSubmitButton(true);
        return;
      }
      setDisableSubmitButton(false); // this is to enable submit button if user reduces their request to meet their balance
    }
  }

  return (
    <div className="new-form-container">
      {isMobile ? (
        <form
          action=""
          onSubmit={handleSubmit}
          method="get"
          id="request-form"
          className="request-form"
        >
          <h4>
            New Leave Request{" "}
          </h4>

          <div className="form-group-wrapper">
            <div className="group-wrapper">
              {/* first form group  */}

              <div className="first-form-group">
                <div className="form-group">
                  <label htmlFor="leave-type">Leave Type:</label>
                  <select
                    name="leave type"
                    id="leave-type"
                    value={leaveType}
                    disabled={loading}
                    onFocus={onLeaveTypeInputFocus}
                    onChange={(event) => {
                      setLeaveType(event.target.value);
                      let leaveTypeData = myLeaveTypes.find(
                        (type) => type.leaveType === event.target.value
                      );
                      let leaveTypeCalc = leaveCalc.find(
                        (type) => type.leaveType === event.target.value
                      );
                      setSelectedLeaveType(leaveTypeData);
                      setCalcForSelectedLeaveType(leaveTypeCalc);

                      if (leaveTypeData.requireReliever === "true") {
                        setShowRequireRelieverField(true);
                      } else {
                        setShowRequireRelieverField(false);
                      }

                      if (leaveTypeData.unlimited === "true") {
                        setLeaveBalanceText("The selected leave is unlimited.");
                      } else {
                        if (leaveTypeCalc.balance < 1) {
                          // change the leave balance text under leave type textbox to red
                          let ele =
                            document.getElementsByClassName(
                              "leave-balance-text"
                            )[0];
                          ele.style.color = "red";

                          let message = `You have used up your ${leaveTypeCalc.yearlyQuota +
                            " " +
                            leaveTypeCalc.leaveUnit
                            } quota.`;
                          setLeaveBalanceText(message);
                          toasterErrorMessage(message);
                          setDisableSubmitButton(true);
                          return;
                        } else {
                          let message;
                          if (
                            leaveTypeData.cummulative === "false" &&
                            leaveTypeData.leaveUnit === "days"
                          ) {
                            message = `This is One-Off. You have ${leaveTypeCalc.balance +
                              " " +
                              leaveTypeCalc.leaveUnit
                              }.`;
                          } else {
                            message = `You have ${leaveTypeCalc.balance +
                              " " +
                              leaveTypeCalc.leaveUnit
                              } left.`;
                            // change the leave balance text under leave type textbox to red if balance is less than 15% of set quota
                            if (
                              leaveTypeCalc.balance <
                              leaveTypeCalc.yearlyQuota * 0.15
                            ) {
                              let ele =
                                document.getElementsByClassName(
                                  "leave-balance-text"
                                )[0];
                              ele.style.color = "red";
                            }
                          }

                          setLeaveBalanceText(message);

                          setStartDate2("");
                          setEndDate2("");
                          setStartTime2("");
                          setEndTime2("");
                          setDisableTimeControls(true);
                          setDisableEndDate2(true);
                          setTotalTime(0);
                        }

                        // if (leaveTypeCalc.balance < 1) {
                        // } else {
                        //   setLeaveBalanceText(
                        //     (leaveTypeData.cummulative === "false" && leaveTypeData.leaveUnit === "days")
                        //       ? `This is One-Off. You have ${leaveTypeCalc.balance + " " + leaveTypeCalc.leaveUnit}.`
                        //       : `You have ${leaveTypeCalc.balance + " " + leaveTypeCalc.leaveUnit} left.`
                        //   );
                        // }
                      }

                      // clear form variables when leave type is changed and set start time and end time to 00:00 when leave type unit is in hours
                      setStartDate("");
                      setEndDate("");
                      setStartTime(
                        leaveTypeData.leaveUnit === "hours" ? "00:00" : ""
                      );
                      setEndTime(
                        leaveTypeData.leaveUnit === "hours" ? "00:00" : ""
                      );
                      setTotalTime(0);
                      setRelieverADId("");
                      setReason("");
                      // and for some others
                      setDisableEndDate(false);
                      setDisableSubmitButton(false);
                    }}
                  >
                    <option value="" disabled selected className="selected">
                      {loading ? "Loading..." : "Please pick a leave type"}
                    </option>
                    {myLeaveTypes.map((leaveType, key) => {
                      return (
                        <option value={leaveType.leaveType} key={key}>
                          {leaveType.leaveType}
                        </option>
                      );
                    })}
                  </select>
                  <p className="leave-balance-text">{leaveBalanceText}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="start-date">Start Date:</label>
                  <DatePicker
                    selected={startDate2}
                    className='datepickercomp'
                    popperPlacement="bottom"
                    showPopperArrow={false}
                    onChange={(date) => {
                      if (!leaveType) {
                        toasterErrorMessage("Please select a leave type!");
                        return;
                      }

                      setStartDate2(date);

                      if (selectedLeaveType.leaveUnit === "hours") {
                        // calculate initial start and end time values, and total time (hours)
                        calcInitialTimeValuesAndTotalTime(date);
                      } else if (selectedLeaveType.leaveUnit === "days") {
                        // calculate initial end date values, and total time (days)
                        calcInitialEndDateValuesAndTotalTime(date);
                      }

                      // this is the same as is in the onChange function for the leave type dropdown
                      // if (selectedLeaveType.unlimited === "false" && calcForSelectedLeaveType.balance < 1) {
                      //   toasterErrorMessage(`You have used up your ${calcForSelectedLeaveType.yearlyQuota + " " + calcForSelectedLeaveType.leaveUnit} quota.`);
                      //   return;
                      // };

                      // setStartDate(event.target.value);

                      if (
                        selectedLeaveType.cummulative === "false" &&
                        selectedLeaveType.leaveUnit === "days"
                      ) {
                        if (
                          calcForSelectedLeaveType.yearlyQuota !==
                          calcForSelectedLeaveType.balance
                        ) {
                          toasterErrorMessage(
                            "Your balance is below given quota for one-off leave type."
                          );
                          setStartDate2("");
                          setDisableSubmitButton(true);
                          return;
                        }

                        let endDate = moment(date)
                          .add(selectedLeaveType.yearlyQuota - 1, "days")
                          .format("YYYY-MM-DD");
                        setEndDate2(endDate);
                        setTotalTime(selectedLeaveType.yearlyQuota);
                        setDisableEndDate(true);
                        return;
                      }

                      // if (selectedLeaveType.leaveUnit === "days" || selectedLeaveType.leaveUnit === "hours") {
                      //   calcTotalTime(endDate, date);
                      // } else {
                      //   setEndDate(date);
                      // }
                    }}
                    minDate={new Date()}
                    placeholderText="dd/mm/yyyy"
                    required
                  />

                  {/* <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    value={startDate}
                    onChange={(event) => {
                      if (!leaveType) {
                        toasterErrorMessage("Please select a leave type!");
                        return;
                      }

                      // this is the same as is in the onChange function for the leave type dropdown
                      // if (selectedLeaveType.unlimited === "false" && calcForSelectedLeaveType.balance < 1) {
                      //   toasterErrorMessage(`You have used up your ${calcForSelectedLeaveType.yearlyQuota + " " + calcForSelectedLeaveType.leaveUnit} quota.`);
                      //   return;
                      // };

                      setStartDate(event.target.value);

                      if (
                        selectedLeaveType.cummulative === "false" &&
                        selectedLeaveType.leaveUnit === "days"
                      ) {
                        if (
                          calcForSelectedLeaveType.yearlyQuota !==
                          calcForSelectedLeaveType.balance
                        ) {
                          toasterErrorMessage(
                            "Your balance is below given quota for one-off leave type."
                          );
                          setStartDate("");
                          setDisableSubmitButton(true);
                          return;
                        }
                        let endDate = moment(event.target.value)
                          .add(selectedLeaveType.yearlyQuota - 1, "days")
                          .format("YYYY-MM-DD");
                        setEndDate(endDate);
                        setTotalTime(selectedLeaveType.yearlyQuota);
                        setDisableEndDate(true);
                        return;
                      }

                      if (selectedLeaveType.leaveUnit === "days" || selectedLeaveType.leaveUnit === "hours") {
                        calcTotalTime(endDate, event.target.value);
                      } else {
                        setEndDate(event.target.value);
                      }
                    }}
                  /> */}
                </div>

                {selectedLeaveType.leaveUnit !== "hours" && (
                  <div className="form-group">
                    <label htmlFor="end-date">End Date:</label>
                    <DatePicker
                      selected={endDate2}
                      className='datepickercomp'
                      popperClassName="datepickerPopper"
                      popperPlacement="bottom"
                      showPopperArrow={false}
                      onChange={(date) => {
                        setEndDate2(date);
                        calcLeaveTotalTimeOrDate(date, startDate2);
                      }}
                      minDate={minEndDate}
                      placeholderText="dd/mm/yyyy"
                      disabled={disableEndDate2}
                      required
                    />
                    {/* <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      value={endDate}
                      disabled={disableEndDate}
                      required
                      onChange={(event) => {
                        setEndDate(event.target.value);
                        calcTotalTime(event.target.value, startDate);
                      }}
                    /> */}
                  </div>
                )}

                {selectedLeaveType.leaveUnit === "hours" && (
                  <div className="form-group">
                    <label htmlFor="start-time">Start Time:</label>
                    <DatePicker
                      selected={startTime2}
                      className='datepickercomp'
                      popperPlacement="bottom"
                      showPopperArrow={false}
                      onChange={(date) => {
                        setStartTime2(date);

                        // reset end time and its minimum time when start time changes
                        const currentHour = new Date(date).getHours();
                        const newEndTime = new Date(new Date(date).setHours(currentHour + 1, 0));
                        setEndTime2(newEndTime);
                        setMinEndTime(newEndTime);
                        calcLeaveTotalTimeOrDate(newEndTime, date);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={minStartTime}
                      maxTime={new Date(new Date().setHours(23, 0, 10))}
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="00:00 AM"
                      disabled={disableTimeControls}
                      required
                    />
                    {/* <input
                      type="time"
                      placeholder="hh:mm"
                      value={startTime}
                      required
                      onChange={(event) => {
                        setStartTime(event.target.value);
                        calcTotalTime(endTime, event.target.value);
                        calcStartTime(endTime, event.target.value);
                      }}
                    /> */}
                  </div>
                )}

                {selectedLeaveType.leaveUnit === "hours" && (
                  <div className="form-group">
                    <label htmlFor="end-time">End Time:</label>
                    <DatePicker
                      selected={endTime2}
                      className='datepickercomp'
                      popperPlacement="bottom"
                      showPopperArrow={false}
                      onChange={(date) => {
                        setEndTime2(date);
                        calcLeaveTotalTimeOrDate(date, startTime2);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={minEndTime}
                      maxTime={new Date(new Date().setHours(23, 0, 10))}
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="00:00 AM"
                      disabled={disableTimeControls}
                      required
                    />
                    {/* <input
                      type="time"
                      placeholder="hh:mm"
                      value={endTime}
                      required
                      onChange={(event) => {
                        setEndTime(event.target.value);
                        calcTotalTime(event.target.value, startTime);
                      }}
                    /> */}
                  </div>
                )}

                {selectedLeaveType.leaveUnit !== "hours" && (
                  <div className="form-group">
                    <label htmlFor="total-days">
                      Total Time ({selectedLeaveType.leaveUnit || "N/A"}):
                    </label>
                    <input
                      type="number"
                      placeholder="Please, input a number"
                      disabled
                      value={totalTime}
                      onChange={(event) => setTotalTime(event.target.value)}
                    />
                  </div>
                )}
              </div>

              {/* second form group  */}

              <div className="second-form-group">
                {selectedLeaveType.leaveUnit === "hours" && (
                  <div className="form-group">
                    <label htmlFor="total-days">
                      Total Time ({selectedLeaveType.leaveUnit || "N/A"}):
                    </label>
                    <input
                      type="number"
                      placeholder="Please, input a number"
                      disabled
                      value={totalTime}
                      onChange={(event) => setTotalTime(event.target.value)}
                    />
                  </div>
                )}
                {showRequireRelieverField && (
                  <div className="form-group">
                    <label htmlFor="reliever-name">Reliever's Name:</label>
                    <select
                      name=""
                      id=""
                      value={relieverADId}
                      required
                      onChange={(event) => setRelieverADId(event.target.value)}
                    >
                      <option value="" selected disabled>
                        Please pick a reliever name
                      </option>
                      {users.map((item, key) => {
                        // Check if the current user's AD ID is not equal to the AD ID of the user being iterated
                        if (currentUser !== item.adId) {
                          return (
                            <option value={item.adId} key={key}>
                              {item.fullName}
                            </option>
                          );
                        } else {
                          return null; // Exclude the current user from the list
                        }
                      })}
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="line-manager-name">
                    Line Manager's Name:
                  </label>
                  <input
                    type="text"
                    placeholder="Example: Lucia Davis"
                    value={user?.manager?.fullName || defaultManager.fullName}
                    // onChange={(event) => setLineManagerName(event.target.value)}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="reason">Reason for Leave:</label>
                  <textarea
                    type="text"
                    placeholder="Please explain the reason for your choice of leave type"
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-buttons">
              <button
                className="btn btn-cancel"
                onClick={resetForm}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={disableSubmitButton}
                className="btn btn-submit"
                id="btn-submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form
          action=""
          onSubmit={handleSubmit}
          method="get"
          id="request-form"
          className="request-form"
        >
          <h4>
            New Leave Request{" "}
          </h4>

          <div className="form-group-wrapper">
            <div className="group-wrapper">
              {/* first form group  */}

              <div className="first-form-group">
                <div className="form-group">
                  <label htmlFor="leave-type">Leave Type:</label>
                  <select
                    name="leave type"
                    id="leave-type"
                    value={leaveType}
                    disabled={loading}
                    onFocus={onLeaveTypeInputFocus}
                    onChange={(event) => {
                      setLeaveType(event.target.value);
                      let leaveTypeData = myLeaveTypes.find(
                        (type) => type.leaveType === event.target.value
                      );
                      let leaveTypeCalc = leaveCalc.find(
                        (type) => type.leaveType === event.target.value
                      );
                      setSelectedLeaveType(leaveTypeData);
                      setCalcForSelectedLeaveType(leaveTypeCalc);

                      if (leaveTypeData.requireReliever === "true") {
                        setShowRequireRelieverField(true);
                      } else {
                        setShowRequireRelieverField(false);
                      }

                      if (leaveTypeData.unlimited === "true") {
                        setLeaveBalanceText("The selected leave is unlimited.");
                      } else {
                        if (leaveTypeCalc.balance < 1) {
                          // change the leave balance text under leave type textbox to red
                          let ele =
                            document.getElementsByClassName(
                              "leave-balance-text"
                            )[0];
                          ele.style.color = "red";

                          let message = `You have used up your ${leaveTypeCalc.yearlyQuota +
                            " " +
                            leaveTypeCalc.leaveUnit
                            } quota.`;
                          setLeaveBalanceText(message);
                          toasterErrorMessage(message);
                          setDisableSubmitButton(true);
                          return;
                        } else {
                          let message;
                          if (
                            leaveTypeData.cummulative === "false" &&
                            leaveTypeData.leaveUnit === "days"
                          ) {
                            message = `This is One-Off. You have ${leaveTypeCalc.balance +
                              " " +
                              leaveTypeCalc.leaveUnit
                              }.`;
                          } else {
                            message = `You have ${leaveTypeCalc.balance +
                              " " +
                              leaveTypeCalc.leaveUnit
                              } left.`;
                            // change the leave balance text under leave type textbox to red if balance is less than 15% of set quota
                            if (
                              leaveTypeCalc.balance <
                              leaveTypeCalc.yearlyQuota * 0.15
                            ) {
                              let ele =
                                document.getElementsByClassName(
                                  "leave-balance-text"
                                )[0];
                              ele.style.color = "red";
                            }
                          }

                          setLeaveBalanceText(message);

                          setStartDate2("");
                          setEndDate2("");
                          setStartTime2("");
                          setEndTime2("");
                          setDisableTimeControls(true);
                          setDisableEndDate2(true);
                          setTotalTime(0);
                        }

                        // if (leaveTypeCalc.balance < 1) {
                        // } else {
                        //   setLeaveBalanceText(
                        //     (leaveTypeData.cummulative === "false" && leaveTypeData.leaveUnit === "days")
                        //       ? `This is One-Off. You have ${leaveTypeCalc.balance + " " + leaveTypeCalc.leaveUnit}.`
                        //       : `You have ${leaveTypeCalc.balance + " " + leaveTypeCalc.leaveUnit} left.`
                        //   );
                        // }
                      }

                      // clear form variables when leave type is changed and set start time and end time to 00:00 when leave type unit is in hours
                      setStartDate("");
                      setEndDate("");
                      setStartTime(
                        leaveTypeData.leaveUnit === "hours" ? "00:00" : ""
                      );
                      setEndTime(
                        leaveTypeData.leaveUnit === "hours" ? "00:00" : ""
                      );
                      setTotalTime(0);
                      setRelieverADId("");
                      setReason("");
                      // and for some others
                      setDisableEndDate(false);
                      setDisableSubmitButton(false);
                    }}
                  >
                    <option value="" disabled selected className="selected">
                      {loading ? "Loading..." : "Please pick a leave type"}
                    </option>
                    {myLeaveTypes.map((leaveType, key) => {
                      return (
                        <option value={leaveType.leaveType} key={key}>
                          {leaveType.leaveType}
                        </option>
                      );
                    })}
                  </select>
                  <p className="leave-balance-text">{leaveBalanceText}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="start-date">Start Date:</label>
                  <DatePicker
                    selected={startDate2}
                    className='datepickercomp'
                    popperPlacement="right"
                    onChange={(date) => {
                      if (!leaveType) {
                        toasterErrorMessage("Please select a leave type!");
                        return;
                      }

                      setStartDate2(date);

                      if (selectedLeaveType.leaveUnit === "hours") {
                        // calculate initial start and end time values, and total time (hours)
                        calcInitialTimeValuesAndTotalTime(date);
                      } else if (selectedLeaveType.leaveUnit === "days") {
                        // calculate initial end date values, and total time (days)
                        calcInitialEndDateValuesAndTotalTime(date);
                      }




                      // this is the same as is in the onChange function for the leave type dropdown
                      // if (selectedLeaveType.unlimited === "false" && calcForSelectedLeaveType.balance < 1) {
                      //   toasterErrorMessage(`You have used up your ${calcForSelectedLeaveType.yearlyQuota + " " + calcForSelectedLeaveType.leaveUnit} quota.`);
                      //   return;
                      // };

                      // setStartDate(event.target.value);

                      if (
                        selectedLeaveType.cummulative === "false" &&
                        selectedLeaveType.leaveUnit === "days"
                      ) {
                        if (
                          calcForSelectedLeaveType.yearlyQuota !==
                          calcForSelectedLeaveType.balance
                        ) {
                          toasterErrorMessage(
                            "Your balance is below given quota for one-off leave type."
                          );
                          setStartDate("");
                          setDisableSubmitButton(true);
                          return;
                        }

                        let endDate = moment(date)
                          .add(selectedLeaveType.yearlyQuota - 1, "days")
                          .format("YYYY-MM-DD");
                        setEndDate(endDate);
                        setTotalTime(selectedLeaveType.yearlyQuota);
                        setDisableEndDate(true);
                        return;
                      }

                      // if (selectedLeaveType.leaveUnit === "days" || selectedLeaveType.leaveUnit === "hours") {
                      //   calcTotalTime(endDate, date);
                      // } else {
                      //   setEndDate(date);
                      // }
                    }}
                    minDate={new Date()}
                    placeholderText="dd/mm/yyyy"
                    required
                  />

                  {/* <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    value={startDate}
                    onChange={(event) => {
                      if (!leaveType) {
                        toasterErrorMessage("Please select a leave type!");
                        return;
                      }

                      // this is the same as is in the onChange function for the leave type dropdown
                      // if (selectedLeaveType.unlimited === "false" && calcForSelectedLeaveType.balance < 1) {
                      //   toasterErrorMessage(`You have used up your ${calcForSelectedLeaveType.yearlyQuota + " " + calcForSelectedLeaveType.leaveUnit} quota.`);
                      //   return;
                      // };

                      setStartDate(event.target.value);

                      if (
                        selectedLeaveType.cummulative === "false" &&
                        selectedLeaveType.leaveUnit === "days"
                      ) {
                        if (
                          calcForSelectedLeaveType.yearlyQuota !==
                          calcForSelectedLeaveType.balance
                        ) {
                          toasterErrorMessage(
                            "Your balance is below given quota for one-off leave type."
                          );
                          setStartDate("");
                          setDisableSubmitButton(true);
                          return;
                        }
                        let endDate = moment(event.target.value)
                          .add(selectedLeaveType.yearlyQuota - 1, "days")
                          .format("YYYY-MM-DD");
                        setEndDate(endDate);
                        setTotalTime(selectedLeaveType.yearlyQuota);
                        setDisableEndDate(true);
                        return;
                      }

                      if (selectedLeaveType.leaveUnit === "days" || selectedLeaveType.leaveUnit === "hours") {
                        calcTotalTime(endDate, event.target.value);
                      } else {
                        setEndDate(event.target.value);
                      }
                    }}
                  /> */}
                </div>

                {selectedLeaveType.leaveUnit !== "hours" && (
                  <div className="form-group">
                    <label htmlFor="end-date">End Date:</label>
                    <DatePicker
                      selected={endDate2}
                      className='datepickercomp'
                      popperClassName="datepickerPopper"
                      popperPlacement="right"
                      onChange={(date) => {
                        setEndDate2(date);
                        calcLeaveTotalTimeOrDate(date, startDate2);
                      }}
                      minDate={minEndDate}
                      placeholderText="dd/mm/yyyy"
                      disabled={disableEndDate2}
                      required
                    />

                    {/* <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      value={endDate}
                      disabled={disableEndDate}
                      required
                      onChange={(event) => {
                        setEndDate(event.target.value);
                        calcTotalTime(event.target.value, startDate);
                      }}
                    /> */}
                  </div>
                )}

                {selectedLeaveType.leaveUnit === "hours" && (
                  <div className="form-group">
                    <label htmlFor="start-time">Start Time:</label>
                    <DatePicker
                      selected={startTime2}
                      className='datepickercomp'
                      popperPlacement="right"
                      onChange={(date) => {
                        setStartTime2(date);

                        // reset end time and its minimum time when start time changes
                        const currentHour = new Date(date).getHours();
                        const newEndTime = new Date(new Date(date).setHours(currentHour + 1, 0));
                        setEndTime2(newEndTime);
                        setMinEndTime(newEndTime);
                        calcLeaveTotalTimeOrDate(newEndTime, date);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={minStartTime}
                      maxTime={new Date(new Date().setHours(23, 0, 10))}
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="00:00 AM"
                      disabled={disableTimeControls}
                      required
                    />

                    {/* <input
                      type="time"
                      placeholder="hh:mm"
                      value={startTime}
                      required
                      onChange={(event) => {
                        setStartTime(event.target.value);
                        calcTotalTime(endTime, event.target.value);
                        calcStartTime(endTime, event.target.value);
                      }}
                    /> */}
                  </div>
                )}

                {selectedLeaveType.leaveUnit === "hours" && (
                  <div className="form-group">
                    <label htmlFor="end-time">End Time:</label>
                    <DatePicker
                      selected={endTime2}
                      className='datepickercomp'
                      popperPlacement="right"
                      onChange={(date) => {
                        setEndTime2(date);
                        calcLeaveTotalTimeOrDate(date, startTime2);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={minEndTime}
                      maxTime={new Date(new Date().setHours(23, 0, 10))}
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="00:00 AM"
                      disabled={disableTimeControls}
                      required
                    />
                    {/* <input
                      type="time"
                      placeholder="hh:mm"
                      value={endTime}
                      required
                      onChange={(event) => {
                        setEndTime(event.target.value);
                        calcTotalTime(event.target.value, startTime);
                      }}
                    /> */}
                  </div>
                )}

                {selectedLeaveType.leaveUnit !== "hours" && (
                  <div className="form-group">
                    <label htmlFor="total-days">
                      Total Time ({selectedLeaveType.leaveUnit || "N/A"}):
                    </label>
                    <input
                      type="number"
                      placeholder="Please, input a number"
                      disabled
                      value={totalTime}
                      required
                      onChange={(event) => setTotalTime(event.target.value)}
                    />
                  </div>
                )}
              </div>

              {/* second form group  */}

              <div className="second-form-group">
                {selectedLeaveType.leaveUnit === "hours" && (
                  <div className="form-group">
                    <label htmlFor="total-days">
                      Total Time ({selectedLeaveType.leaveUnit || "N/A"}):
                    </label>
                    <input
                      type="number"
                      placeholder="Please, input a number"
                      disabled
                      value={totalTime}
                      required
                      onChange={(event) => setTotalTime(event.target.value)}
                    />
                  </div>
                )}
                {showRequireRelieverField && (
                  <div className="form-group">
                    <label htmlFor="reliever-name">Reliever's Name:</label>
                    <select
                      name=""
                      id=""
                      value={relieverADId}
                      required
                      onChange={(event) => setRelieverADId(event.target.value)}
                    >
                      <option value="" selected disabled>
                        Please pick a reliever name
                      </option>
                      {users.map((item, key) => {
                        // Check if the current user's AD ID is not equal to the AD ID of the user being iterated
                        if (currentUser !== item.adId) {
                          return (
                            <option value={item.adId} key={key}>
                              {item.fullName}
                            </option>
                          );
                        } else {
                          return null; // Exclude the current user from the list
                        }
                      })}
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="line-manager-name">
                    Line Manager's Name:
                  </label>
                  <input
                    type="text"
                    placeholder="Example: Lucia Davis"
                    value={user?.manager?.fullName || defaultManager.fullName}
                    // onChange={(event) => setLineManagerName(event.target.value)}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="reason">Reason for Leave:</label>
                  <textarea
                    type="text"
                    placeholder="Please explain the reason for your choice of leave type"
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-buttons">
              <button
                className="btn btn-cancel"
                onClick={resetForm}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={disableSubmitButton}
                className="btn btn-submit"
                id="btn-submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default NewRequest;
