import React, { useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  handleBackendRequestBlockErrors,
  toasterSuccessMessage,
} from "../utils/errorHandlingUtils";
import "./ApproverConfig.scss";
import EmptyDataPlaceHolder2 from "./EmptyDataPlaceHolder2";
import ConfirmAction from "../helperComponents/ConfirmAction";

import "./LocationForm.scss"
import closeForm from "../../images/cancel.png";


import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'MoreVertical' }}
      menuProps={{ items: overflowItems }}
    />
  );
};

function ApproverConfig(props) {
  const { apiClient, triggerConsent } = props;
  const [approverADId, setApproverADId] = useState("");
  const [approverRole, setApproverRole] = useState("");
  const [approverLevel, setApproverLevel] = useState();
  const [isNewForm, setIsNewForm] = useState(true);
  const [approvers, setApprovers] = useState([]);
  const [approver, setApprover] = useState();
  const [users, setUsers] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const confirmDeleteConfig = {
    header: "Delete Approver",
    body: `Are you sure you want to delete approver, ${approver?.approverFullName}?`,
    action: "Delete"
  };

  // Get all user's data
  useData(async () => {
    try {
      const resUsers = await apiClient.get(`/user/users`);
      setUsers(resUsers.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Get approvers
  const { reload: reloadApprovers } = useData(async () => {
    try {
      const resApprovers = await apiClient.get(`/approver/approvers`);
      setApprovers(resApprovers.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // function to handle submitting new and edited approver data
  const handleApproverFormSubmit = async (event) => {
    try {
      event.preventDefault();
      let response;
      const approverDetail = users?.find((user) => user.adId === approverADId);
      const data = {
        approverADId: approverADId,
        approverFullName: approverDetail.fullName,
        approverEmail: approverDetail.email,
        approverRole: approverRole,
        approvalLevel: parseInt(approverLevel),
      };
      if (isNewForm) {
        response = await apiClient.post("/approver/new", data);
      } else {
        data.approverId = approver.approverId;
        response = await apiClient.put("/approver/update", data);
      }
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
      // reset the form fields
      setApproverADId("");
      setApproverRole("");
      setApproverLevel("");
      setIsNewForm(true);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadApprovers();
  };

  // function to handle edit button click event
  const handleApproverEdit = (event) => {
    const id = event.target.parentNode.parentNode.id;
    const approver = approvers[id];
    setApprover(approver);
    setIsNewForm(false);
    setApproverADId(approver.approverADId);
    setApproverRole(approver.approverRole);
    setApproverLevel(approver.approvalLevel);
  };

  const handleDeleteButtonClick = (event) => {
    const id = event.target.parentNode.parentNode.id;
    const approver = approvers[id];
    setApprover(approver);
    setShowConfirmDelete(true);
  };

  // function to handle delete button click event
  const handleApproverDelete = async (event) => {
    // const id = event.target.parentNode.parentNode.id;
    // const approver = approvers[id];
    try {
      event.preventDefault();
      const response = await apiClient.delete(
        `/approver/delete?approverId=${approver.approverId}`
      );
      toasterSuccessMessage(response.data.message);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadApprovers();
    setApprover();
    setShowConfirmDelete(false);
  };

  const closeDeletePopup = () => {
    setApprover({});
    setShowConfirmDelete(false);
  };

  const tableHeaderDetails = [
    "Approver's Name",
    "Approver's Email",
    "Approver's Role",
    "Approver's Level",
    ""
  ]

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showApproverForm, setShowApproverForm] = useState(false);
  const [showApproverHeaderActionPopup, setShowApproverHeaderActionPopup] = useState(false);
  const [showApproverItemsActionPopup, setShowApproverTableItemsPopup] = useState(false);
  const [isViewForm, setIsViewForm] = useState(false);

  // Function to show or hide new leave type form
  const toggleNewApproverForm = () => {
    setShowApproverForm(!showApproverForm);
  };

  const toggleApproverHeaderActionPopup = () => {
    setShowApproverHeaderActionPopup(!showApproverHeaderActionPopup);
  };

  const ApproverHeaderActionPopupOptions = [
    {
      id: 1, label: "Add Approver", onClick: () => {
        toggleNewApproverForm();
        setShowApproverHeaderActionPopup(false);
      }
    },
  ];


  // table items popup
  // function to handle leave type edit button click event
  const handleApproverViewOrEditMobile = (id, display) => {
    const approver = approvers[id];
    setApprover(approver);
    setIsNewForm(false);
    setApproverADId(approver.approverADId);
    setApproverRole(approver.approverRole);
    setApproverLevel(approver.approvalLevel);
    if (display === "View") {
      setIsViewForm(true);
    } else {
      setIsViewForm(false);
    };
    toggleNewApproverForm();
    toggleApproverHeaderActionPopup();
  };

  const handleDeleteButtonClickMobile = (id) => {
    const approver = approvers[id];
    setApprover(approver);
    setShowConfirmDelete(true);
  };

  const toggleApproverTableItemsPopup = () => {
    setShowApproverTableItemsPopup(!showApproverItemsActionPopup);
  };

  const approverTableItemsPopupOptions = [
    { id: 1, label: "View", onClick: (id) => handleApproverViewOrEditMobile(id, "View") },
    { id: 2, label: "Edit", onClick: (id) => handleApproverViewOrEditMobile(id, "Edit") },
  ];


  return (
    <div className="approver-config-section">
      {/* approver config form  */}
      {!isMobile &&
        <>
          <form action="" onSubmit={handleApproverFormSubmit}>
            <div className="form-group">
              <label htmlFor="">Approver's Full Name:</label>
              <select
                name=""
                id=""
                value={approverADId}
                onChange={(event) => setApproverADId(event.target.value)}
              >
                <option value="" disabled selected className="selected">
                  Please pick an approver
                </option>
                {users.map((item, key) => {
                  return (
                    <option value={item.adId} key={key}>
                      {item.fullName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="">Approver Role:</label>
              <input
                type="text"
                name="Jane Abby Smith"
                value={approverRole}
                onChange={(event) => setApproverRole(event.target.value)}
                placeholder="HR"
              />
            </div>

            <div className="form-group">
              <label htmlFor="">Approver Level:</label>
              <input
                type="number"
                value={approverLevel}
                onChange={(event) => setApproverLevel(event.target.value)}
                placeholder="e.g 1"
              />
            </div>
            <button className="btn btn-update">
              {isNewForm ? "Add" : "Update"}
            </button>
          </form>
        </>
      }

      {isMobile &&
        <div className="config-mobile-subsection-header">
          <p>Approver's Log</p>
        </div>
      }

      {/* approver table configuration */}
      <div className="approver-config-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Approver's Name</th>
              {!isMobile && <th>Approver's Email</th>}
              <th>Approver's Role</th>
              {!isMobile && <th>Approver's Level</th>}
              <th>
                {isMobile &&

                  <>
                    <IconButton
                      ariaLabel="Add approver"
                      title="Add approver"
                      styles={{
                        root: {
                          backgroundColor: '#00000000',
                          width: '20px',
                          height: '20px'
                        },
                        menuIcon: {
                          fontSize: '18',
                          marginLeft: '12px'
                        }
                      }}
                      menuIconProps={{ iconName: 'Add' }}
                      onClick={toggleNewApproverForm}
                    />

                    {/* <Popup
                      trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                      position="bottom right"
                      on="click"
                      open={showApproverHeaderActionPopup}
                      onClose={toggleApproverHeaderActionPopup}
                    >
                      <div className="locConfigPopupOptions-popup">
                        {ApproverHeaderActionPopupOptions.map((option) => (
                          <button
                            key={option.id}
                            // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                            onClick={() => option.onClick()}
                            style={{ width: '90%' }}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </Popup> */}
                  </>
                }
              </th>
            </tr>
          </thead>

          {approvers.length > 0 &&
            <tbody>
              {approvers.map((approver, key) => {
                return (
                  <tr key={key} id={key}>
                    <td>{approver.approverFullName}</td>
                    {!isMobile && <td>{approver.approverEmail}</td>}
                    <td>{approver.approverRole}</td>
                    {!isMobile && <td>{approver.approvalLevel}</td>}
                    <td className={!isMobile ? "btn-table-column-approver" : ""}>
                      {!isMobile
                        ?
                        <>
                          <button
                            className="btn-table btn-delete"
                            // onClick={handleApproverDelete}
                            onClick={handleDeleteButtonClick}
                          >
                            delete
                          </button>
                          <button
                            className="btn-table btn-edit"
                            onClick={handleApproverEdit}
                          >
                            edit
                          </button>
                        </>
                        :
                        <>
                          <OverflowSet
                            // key={"user-table-items-overflowset"}
                            aria-label="more actions for user"
                            items={[]}
                            overflowItems={[
                              {
                                key: 'View',
                                name: 'View',
                                iconProps: { iconName: 'EntryView' },
                                onClick: () => handleApproverViewOrEditMobile(key, "View"),
                              },
                              {
                                key: 'Edit',
                                name: 'Edit',
                                iconProps: { iconName: 'PageEdit' },
                                onClick: () => handleApproverViewOrEditMobile(key, "Edit"),
                              },
                              {
                                key: 'Delete',
                                name: 'Delete',
                                iconProps: { iconName: 'Delete' },
                                onClick: () => handleDeleteButtonClickMobile(key),
                              },
                            ]}
                            onRenderOverflowButton={onRenderOverflowButton}
                            onRenderItem={onRenderItem}
                          />
                          {/* <Popup
                            trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                            position="bottom right"
                            on="click"
                            open={showApproverItemsActionPopup}
                            onClose={toggleApproverTableItemsPopup}
                          >
                            <div className="locConfigPopupOptions-popup">
                              {approverTableItemsPopupOptions.map((option) => (
                                <button
                                  key={option.id}
                                  // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                                  onClick={() => option.onClick(key, option.label)}
                                  style={{ width: '90%' }}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </Popup> */}
                        </>
                      }

                    </td>
                  </tr>
                );
              })}
            </tbody>
          }

          {approvers.length === 0 &&
            <tbody>
              <tr>
                <td colspan="5">
                  <EmptyDataPlaceHolder2 tableHeaderDetails={tableHeaderDetails} />
                </td>
              </tr>
            </tbody>
          }
        </table>
      </div>

      {showApproverForm &&
        <div className="location-form-container">
          <form
            action=""
            className="location-form-container-mainform"
            onSubmit={async (event) => {
              await handleApproverFormSubmit(event);
              toggleNewApproverForm();
            }}
            style={{ padding: '0px' }}
          >
            <div className="location-form-container-mainform-header">
              <h4>Configure Approver</h4>
              <img
                src={closeForm}
                alt="close"
                onClick={() => {
                  // reset the form fields
                  setApproverADId("");
                  setApproverRole("");
                  setApproverLevel("");

                  setIsNewForm(true);
                  setIsViewForm(false);
                  toggleNewApproverForm();
                }}
              />
            </div>

            <div className="location-form-container-mainform-group-wrapper">
              <div className="form-group">
                <label htmlFor="">Approver's Full Name:</label>
                <select
                  name=""
                  id=""
                  value={approverADId}
                  onChange={(event) => setApproverADId(event.target.value)}
                  disabled={isViewForm}
                >
                  <option value="" disabled selected className="selected">
                    Please pick an approver
                  </option>
                  {users.map((item, key) => {
                    return (
                      <option value={item.adId} key={key}>
                        {item.fullName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="">Approver Role:</label>
                <input
                  type="text"
                  name="Jane Abby Smith"
                  value={approverRole}
                  onChange={(event) => setApproverRole(event.target.value)}
                  placeholder="HR"
                  disabled={isViewForm}
                />
              </div>

              <div className="form-group">
                <label htmlFor="">Approver Level:</label>
                <input
                  type="number"
                  value={approverLevel}
                  onChange={(event) => setApproverLevel(event.target.value)}
                  placeholder="e.g 1"
                  disabled={isViewForm}
                />
              </div>

              {!isViewForm &&
                <button className="btn btn-update-location">
                  {isNewForm ? "Add" : "Update"}
                </button>
              }
            </div>
          </form>
        </div>
      }

      {
        showConfirmDelete && (
          <ConfirmAction
            close={closeDeletePopup}
            submit={handleApproverDelete}
            config={confirmDeleteConfig}
          />
        )
      }
    </div >
  );
}

export default ApproverConfig;
