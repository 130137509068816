import "./StatsUser.scss";
import btn_Cancel from "../../images/cancel.png";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import EmptyDataPlaceHolder from "./EmptyDataPlaceHolder";

const currentYear = new Date().getFullYear();

function yearsFunc() {
  const years = [];
  for (let year = 2021; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
}

function StatsUser(props) {
  const {
    toggleStats,
    leaveCalc,
    setLeaveCalc,
    reloadLeaveCalc,
    user,
    access,
  } = props;
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = yearsFunc();

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const tableHeaderDetails = ["Leave Type", "Quota", "Leave Taken", "Balance"];

  return (
    <div className="stats-container">
      <div className="stats-details-container">
        <div className="stats-details-container-header-one">
          <h4>
            {access === "user" ? "My" : user.fullName + "'s"} Leave Entitlements
          </h4>
          <img
            src={btn_Cancel}
            alt="cancel button"
            className="btn-cancel"
            onClick={() => {
              if (access === "admin") {
                setLeaveCalc([]);
              }
              toggleStats(false);
            }}
          />
        </div>

        {access === "admin" && (
          <div className="filter-container">
            <div className="filter">
              <div className="filter-input">
                <label htmlFor="">Filter By Year:</label>
                <select
                  name=""
                  id=""
                  value={selectedYear}
                  onChange={(event) => {
                    setSelectedYear(event.target.value);
                    setLeaveCalc([]);
                    reloadLeaveCalc(user, event.target.value);
                  }}
                >
                  {years.map((year, key) => {
                    return (
                      <option value={year} key={key}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        )}

        <div>
          {leaveCalc.length === 0 ? (
            <EmptyDataPlaceHolder tableHeaderDetails={tableHeaderDetails} />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Leave Type</th>
                  <th>Quota</th>
                  <th>Leave Taken</th>
                  {/* <th>Leave Taken (Pending)</th> */}
                  <th>Balance</th>
                </tr>
              </thead>
              {leaveCalc.map((item) => {
                return (
                  <tbody>
                    <tr>
                      <td>{item.leaveType}</td>
                      <td>
                        {item.yearlyQuota !== null
                          ? item.yearlyQuota + " " + item.leaveUnit
                          : "N/A"}
                      </td>
                      <td>
                        {item.takenApproved} {item.leaveUnit}
                      </td>
                      {/* <td>
                      {item.takenPending} {item.leaveUnit}
                    </td> */}
                      <td>
                        {item.balance !== null
                          ? item.balance + " " + item.leaveUnit
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          )}
        </div>

        <div className="stats-btn">
          <button
            className="btn btn-stats-close"
            onClick={() => {
              if (access === "admin") {
                setLeaveCalc([]);
              }
              toggleStats(false);
            }}
          >
            close
          </button>
        </div>
      </div>
    </div>
  );
}

export default StatsUser;
