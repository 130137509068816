import React, { useState } from "react";
import closeForm from "../../images/cancel.png";
import "./LeavePolicySync.scss";
import { handleBackendRequestBlockErrors, toasterSuccessMessage } from "../utils/errorHandlingUtils";

function LeavePolicySync(props) {
  const {
    apiClient,
    leaveTypeData,
    handleLeaveTypeSubmissionCleanup,
    toggleSyncPolicyForm,
    triggerConsent,
    isNewLeaveTypeForm
  } = props;
  const [syncYearlyQuota, setSyncYearlyQuota] = useState(leaveTypeData?.leavePolicy?.yearlyQuota || "");
  const [cummulative, setCummulative] = useState(leaveTypeData?.leavePolicy?.cummulative || "");
  const [paid, setPaid] = useState(leaveTypeData?.leavePolicy?.paid || false);
  const [unlimited, setUnlimited] = useState((leaveTypeData?.leavePolicy?.unlimited === undefined) ? true : leaveTypeData?.leavePolicy?.unlimited);
  const [syncRequireReliever, setSyncRequireReliever] = useState(leaveTypeData?.leavePolicy?.requireReliever || "");

  // function to handle submitting leave type and default leave policy
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      let response;
      let data = {
        leaveType: {
          ...leaveTypeData.leaveType
        },
        leavePolicy: {
          ...leaveTypeData.leavePolicy,
          yearlyQuota: syncYearlyQuota,
          paid: (paid) ? true : false,
          cummulative: cummulative,
          unlimited: (unlimited) ? true : false,
          requireReliever: (syncRequireReliever === "Yes" ? true : false)
        }
      };
      if (isNewLeaveTypeForm) {
        response = await apiClient.post("/leaveType/new", data);
      } else {
        response = await apiClient.put("/leaveType/update", data);
      }
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
      handleLeaveTypeSubmissionCleanup();
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  }

  return (
    <div className="policy-sync-container">
      <form
        action=""
        onSubmit={handleSubmit}
        className="policy-sync-container-form">
        <div className="policy-sync-container-form-header">
          <h4>Set Leave Policy</h4>
          <img
            src={closeForm}
            alt="close form"
            onClick={() => toggleSyncPolicyForm(false)}
          />
        </div>

        <div className="policy-sync-container-form-group-wrapper">
          <div className="form-group">
            <div className="checkboxes checkboxes-paid">
              <input
                type="checkbox"
                name=""
                id=""
                checked={unlimited}
                onChange={(event) => { 
                  setUnlimited(event.target.checked);

                  if (event.target.checked) {
                    setSyncYearlyQuota("");
                    setCummulative("");
                  }
                }}
              />
              <label htmlFor="paid">Unlimited?</label>
            </div>
          </div>

          {!unlimited &&
            <div className="form-group">
              <label htmlFor="yearly-quota">Yearly Quota ({leaveTypeData.leaveType.leaveUnit}):</label>
              <input
                type="number"
                value={syncYearlyQuota}
                onChange={(event) => setSyncYearlyQuota(event.target.value)}
                placeholder="e.g 1"
              />
            </div>
          }

          {!unlimited &&
            <div className="form-group">
              <div className="checkboxes">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={cummulative}
                  onChange={(event) => { setCummulative(event.target.checked) }}
                />
                <label htmlFor="">Cumulative?</label>
              </div>
            </div>
          }

          <div className="form-group">
            <div className="checkboxes checkboxes-paid">
              <input
                type="checkbox"
                name=""
                id=""
                checked={paid}
                onChange={(event) => { setPaid(event.target.checked) }}
              />
              <label htmlFor="paid">Paid?</label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Require Reliever?</label>
            <select
              name="require reliever"
              id="require-reliever"
              value={syncRequireReliever}
              onChange={(event) => setSyncRequireReliever(event.target.value)}
            >
              <option value="" disabled selected className="selected">
                Please pick an option
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <button className="btn btn-sync">Save</button>
        </div>
      </form>
    </div>
  );
}

export default LeavePolicySync;
