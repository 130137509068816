import React, { useState } from 'react';
import "./DatePicker.scss";
import moment from 'moment';

let currentDate = moment().format('YYYY-MM-DD');
let dateForThreeMonthsAgo = moment().subtract(3, 'month').format('YYYY-MM-DD');

function DateFilter({ onDateChange }) {
  const [filterStartDate, setFilterStartDate] = useState(dateForThreeMonthsAgo);
  const [filterEndDate, setFilterEndDate] = useState(currentDate);

  const handleStartDateChange = (event) => {
    setFilterStartDate(event.target.value);
    onDateChange(event.target.value, filterEndDate);
  };

  const handleEndDateChange = (event) => {
    setFilterEndDate(event.target.value);
    onDateChange(filterStartDate, event.target.value);
  };

  return (
    <div className='date-picker-container'>
      <div>
         <label htmlFor="">From:</label>
         <input type="date" value={filterStartDate} onChange={handleStartDateChange} />
      </div>

      <div>
         <label htmlFor="">To:</label>
         <input type="date" value={filterEndDate} onChange={handleEndDateChange} />
      </div>
    </div>
  );
}

export default DateFilter;