import React from "react";
import no_Data_Image from "../../images/emptyleaveImage.jpg";
import "./EmptyDataPlaceHolder.scss";
import TableHeaderPlaceHolder from "./TableHeaderPlaceHolder";

function EmptyDataPlaceHolder({tableHeaderDetails}) {
  return (
    <div className="no-data-container">
      <table className="no-data-container-table">
         <TableHeaderPlaceHolder tableHeaderDetails={tableHeaderDetails}/>
      </table>

      <div className="no-data-container-img">
        <img src={no_Data_Image} alt="" />
      </div>

      <div className="no-data-container-text">
         <p>No data available yet!</p>
      </div>
    </div>
  );
}

export default EmptyDataPlaceHolder;
