// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Route } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { initializeIcons } from '@fluentui/react';
import Landing from "./Landing";
import "./App.css";
import TabConfig from "./TabConfig";
import { TeamsFxContext } from "./Context";
import Home from './about/homepage';
import Privacy from './about/Privacy';
import TermsOfUse from './about/TermsOfUse';
import config from "./utils/config";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
initializeIcons();

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <Provider theme={teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
        <Router>
          {loading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              <Route exact path='/' component={Home} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/termsofuse" component={TermsOfUse} />
              <Route exact path="/tab" component={Landing} />
              <Route exact path="/config" component={TabConfig} />
            </>
          )}
        </Router>
      </Provider>
    </TeamsFxContext.Provider>
  );
}