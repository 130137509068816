import "./ConfirmAction.scss";
import React from "react";

import btn_Cancel from "../../images/cancel.png";

export default function ConfirmAction({ close, submit, config }) {
    return (
        <div className="edit-container">
            <form
                className="edit-container-form"
                onSubmit={submit}
            >
                <div className="edit-container-form-header">
                    <h4>{config.header}</h4>
                    <img
                        src={btn_Cancel}
                        alt="cancel"
                        onClick={() => {
                            close();
                        }}
                    />
                </div>

                <div className="edit-container-form-group">
                    <p>{config.body}</p>

                    <div className="edit-form-buttons-confirm-action">
                        <button
                            type="button"
                            onClick={close}
                            className="edit-btn-confirm-action edit-btn-cancel-confirm-action"
                        >
                            Cancel
                        </button>

                        <button
                            type="submit"
                            className="edit-btn-confirm-action edit-btn-submit-confirm-action"
                            id="confirm-action"
                        >
                            {config.action}
                        </button>
                    </div>

                </div>
            </form>
        </div>
    )
}