import React, { useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { Input, Loader, SearchIcon } from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import {
  handleBackendRequestBlockErrors,
  toasterSuccessMessage,
} from "../utils/errorHandlingUtils";
import "./UserConfig.scss";
import { capitalizeFirstLetter } from "../utils/generalUtils";
import StatsUser from "./StatsUser";
import EmptyDataPlaceHolder2 from "./EmptyDataPlaceHolder2";

import "./LocationForm.scss"
import closeForm from "../../images/cancel.png";


// sync users import start
import { mergeStyleSets, DefaultButton, FocusTrapZone, Layer, Overlay, Popup, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
// sync users import end

import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'MoreVertical' }}
      menuProps={{ items: overflowItems }}
    />
  );
};


// sync users code
const popupStyles = mergeStyleSets({
  root: {
    background: 'rgba(0, 0, 0, 0.2)',
    bottom: '0',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
  },
  content: {
    background: 'white',
    left: '50%',
    maxWidth: '400px',
    padding: '2em 2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});



function UserConfig(props) {
  const { apiClient, triggerConsent } = props;
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [locations, setLocations] = useState([]);

  const [showStats, setShowStats] = useState(false);
  const [leaveCalc, setLeaveCalc] = useState([]);
  const [submitSpinner, setSubmitSpinner] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const [searchText, setSearchText] = useState("");

  // function to handle searching users
  const handleUsersListFiltering = (searchText) => {
    if (searchText) {
      let searchedUsersData = allUsers.filter((user) => {
        return user.fullName.toLowerCase().includes(searchText.toLowerCase());
      });
      // return searchedUsersData;
      setUsers(searchedUsersData);
    } else {
      // return users;
      setUsers(allUsers);
    }
  };

  // Get all user's data
  const { loading: loadingUsers, reload: reloadUsersData } = useData(async () => {
    try {
      const resUsers = await apiClient.get(`/user/users`);
      setUsers(resUsers.data.data);
      setAllUsers(resUsers.data.data);

    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Get all locations (custom)
  // this block is separated from the one above because that would need being reloaded
  useData(async () => {
    try {
      const resLocations = await apiClient.get(`/location/locations`);
      setLocations(resLocations.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // function to handle on click event for edit button
  const handleUserEdit = (event) => {
    const userId = event.target.parentNode.parentNode.id;
    const user = users[userId];
    setSelectedUser(user);
    setUserFullName(user.fullName);
    setUserRole(user.role);
    setUserLocation((user.appLocation) ? user.appLocation : null);
  };

  // function to handle submitting updated user custom data
  const handleUsersUpdate = async () => {
    try {
      const data = {
        adId: selectedUser.adId,
        role: userRole,
        appDepartment: null,
        appLocation: userLocation,
      };
      const response = await apiClient.put("user/update", data);
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadUsersData();
  };

  const handleUsersADSync = async () => {
    try {
      if (isMobile) {
        showPopup();
      } else {
        setSubmitSpinner(true);
      }
      const response = await apiClient.get("/user/sync");
      setUsers(response.data.data);
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown

      if (isMobile) {
        hidePopup();
      } else {
        setSubmitSpinner(false);
      }
    } catch (error) {
      if (isMobile) {
        hidePopup();
      } else {
        setSubmitSpinner(false);
      }
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  };

  const toggleStats = (statsState) => {
    setShowStats(statsState);
  };

  const handleUserStats = async (user, year) => {
    try {
      const leaveCalculation = (
        await apiClient.get(`/user/leaveCalc?userAdId=${user.adId}&location=${user.appLocation}&year=${year}&access=admin`)
      ).data.data;
      setLeaveCalc(leaveCalculation);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  };

  const tableHeaderDetails = [
    "Full Name",
    "Role",
    "Location",
    ""
  ]

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showUserForm, setShowUserForm] = useState(false);
  const [showUserHeaderActionPopup, setShowUserHeaderActionPopup] = useState(false);
  const [showUserItemsActionPopup, setShowUserTableItemsPopup] = useState(false);
  const [isViewForm, setIsViewForm] = useState(false);

  // Function to show or hide user update form
  const toggleUserForm = () => {
    setShowUserForm(!showUserForm);
  };

  const toggleUserHeaderActionPopup = () => {
    setShowUserHeaderActionPopup(!showUserHeaderActionPopup);
  };

  const UserHeaderActionPopupOptions = [
    {
      id: 1, label: "Sync Users",
      component:
        <button
          key={1}
          type="button"
          onClick={() => {
            handleUsersADSync();
            toggleUserHeaderActionPopup();
          }}
          style={{ width: '90%' }}
        >
          Sync Users
        </button>
    },
  ];


  // table items popup
  // function to handle leave type edit button click event
  const handleUserViewOrEditMobile = (userId, display) => {
    const user = users[userId];
    setSelectedUser(user);
    setUserFullName(user.fullName);
    setUserRole(user.role);
    setUserLocation((user.appLocation) ? user.appLocation : null);
    if (display === "View") {
      setIsViewForm(true);
    } else {
      setIsViewForm(false);
    };
    toggleUserForm();
    // toggleUserHeaderActionPopup();
  };

  const toggleUserTableItemsPopup = () => {
    setShowUserTableItemsPopup(!showUserItemsActionPopup);
  };

  const userTableItemsPopupOptions = [
    { id: 1, label: "View", onClick: (id) => handleUserViewOrEditMobile(id, "View") },
    { id: 2, label: "Edit", onClick: (id) => handleUserViewOrEditMobile(id, "Edit") },
    {
      id: 3, label: "Leaves",
      onClick: (userId) => {
        const user = users[userId];
        const currentYear = new Date().getFullYear();
        handleUserStats(user, currentYear);
        setSelectedUser(user);
        setShowStats(true);
      }
    },
  ];


  // sync user popup
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);

  return (
    <div className="user-config-section">
      {/* user config form  */}

      {!isMobile &&
        <form action="">
          <div className="form-group">
            <label htmlFor="">Name of Employee:</label>
            <input
              type="text"
              disabled
              value={userFullName}
              placeholder="Employee name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Role of Employee:</label>
            <select
              name="employee role"
              id="employee role"
              value={userRole}
              onChange={(event) => setUserRole(event.target.value)}
            >
              <option value="" disabled selected className="selected">
                Please pick an employee role option
              </option>
              <option value="User">User</option>
              <option value="Admin">Admin</option>
              <option value="Owner">Owner</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="">Location of Employee:</label>
            <select
              name="user location"
              id="user location"
              value={userLocation}
              onChange={(event) => setUserLocation(event.target.value)}
            >
              <option value="" disabled selected className="selected">
                Please pick a location
              </option>
              {locations.map((location, key) => {
                return (
                  <option value={location.locationName} key={key}>
                    {capitalizeFirstLetter(location.locationName)}
                  </option>
                );
              })}
            </select>
          </div>

          {!submitSpinner &&
            <div className="buttons">
              <button
                className="btn btn-update"
                type="button"
                onClick={handleUsersUpdate}
              >
                Update
              </button>

              <button
                className="btn btn-submit"
                type="button"
                onClick={handleUsersADSync}
              >
                Sync Users
              </button>
            </div>
          }

          {submitSpinner &&
            <div
              className="buttons buttons-spinner"
              style={{ width: '90%', left: '10%' }}
            >
              <button
                className="btn btn-update"
                type="button"
                onClick={handleUsersUpdate}
              >
                Update
              </button>

              <button
                class="btn btn-submit btn-with-spinner"
                type="button"
                onClick={handleUsersADSync}
                disabled={submitSpinner}
              >
                <span class="spinner"></span>
                Sync Users
              </button>
            </div>
          }
        </form>
      }

      {isMobile &&
        <div className="config-mobile-subsection-header">
          <p>User</p>
          <div className='config-search-box-wrapper'>
            <Input
              icon={<SearchIcon />}
              placeholder="Search Employee..."
              fluid
              onChange={(_, event) => {
                // setSearchText(event.value);
                handleUsersListFiltering(event.value);
              }}
            />
          </div>
        </div>
      }

      {(isMobile && isPopupVisible) && (
        <Layer>
          <Popup
            className={popupStyles.root}
            role="dialog"
            aria-modal="true"
            onDismiss={hidePopup}
          >
            <Overlay onClick={hidePopup} />
            <FocusTrapZone>
              <div role="document" className={popupStyles.content}>
                <Spinner size={SpinnerSize.large} label="Syncing Users..." />
              </div>
            </FocusTrapZone>
          </Popup>
        </Layer>
      )}

      <div className="user-config-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Role</th>
              {!isMobile && <th>Location</th>}
              <th className={!isMobile ? "user-table-button-column" : ""}>
                {!isMobile
                  ?
                  <div className='user-config-search-box-wrapper'>
                    <Input
                      icon={<SearchIcon />}
                      placeholder="Search Employee..."
                      fluid
                      onChange={(_, event) => {
                        // setSearchText(event.value);
                        handleUsersListFiltering(event.value);
                      }}
                    />
                  </div>
                  :
                  <>
                    <IconButton
                      ariaLabel="Sync users"
                      title="Sync users"
                      styles={{
                        root: {
                          backgroundColor: '#00000000',
                          width: '20px',
                          height: '20px'
                        },
                        menuIcon: {
                          fontSize: '18',
                          marginLeft: '12px'
                        }
                      }}
                      menuIconProps={{ iconName: 'Sync' }}
                      onClick={handleUsersADSync}
                    />
                    {/* <OverflowSet
                      // key={"user-table-items-overflowset"}
                      aria-label="more actions for user"
                      items={[]}
                      overflowItems={[
                        {
                          key: 'Sync Users',
                          name: 'Sync Users',
                          iconProps: { iconName: 'MoveToFolder' },
                          onClick: () => {
                            handleUsersADSync();
                          },
                        },
                      ]}
                      onRenderOverflowButton={onRenderOverflowButton}
                      onRenderItem={onRenderItem}
                    /> */}
                  </>
                  // <Popup
                  //   trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                  //   position="bottom right"
                  //   on="click"
                  //   open={showUserHeaderActionPopup}
                  //   onClose={toggleUserHeaderActionPopup}
                  // >
                  //   <div className="locConfigPopupOptions-popup">
                  //     {UserHeaderActionPopupOptions.map((option) => (
                  //       option.component
                  //     ))}
                  //   </div>
                  // </Popup>
                }
              </th>
            </tr>
          </thead>

          {users.length > 0 &&
            <tbody>
              {users.map((user, key) => {
                return (
                  <tr key={key} id={key}>
                    <td>{user.fullName ? user.fullName : "N/A"}</td>
                    <td>{user.role}</td>
                    {!isMobile && <td>{user.appLocation ? capitalizeFirstLetter(user.appLocation) : "N/A"}</td>}
                    <td>
                      {!isMobile
                        ?
                        <>
                          <button
                            className=" btn-table btn-details"
                            onClick={(event) => {
                              const userId = event.target.parentNode.parentNode.id;
                              const user = users[key];
                              const currentYear = new Date().getFullYear();
                              handleUserStats(user, currentYear);
                              setSelectedUser(user);
                              setShowStats(true);
                            }}
                          >leaves</button>

                          <button
                            className="btn-table btn-edit"
                            onClick={handleUserEdit}
                          >
                            edit
                          </button>
                        </>
                        :
                        <OverflowSet
                          // key={"user-table-items-overflowset"}
                          aria-label="more actions for user"
                          items={[]}
                          overflowItems={[
                            {
                              key: 'View',
                              name: 'View',
                              iconProps: { iconName: 'EntryView' },
                              onClick: () => handleUserViewOrEditMobile(key, "View"),
                            },
                            {
                              key: 'Edit',
                              name: 'Edit',
                              iconProps: { iconName: 'PageEdit' },
                              onClick: () => handleUserViewOrEditMobile(key, "Edit"),
                            },
                            {
                              key: 'Leaves',
                              name: 'Leaves',
                              iconProps: { iconName: 'EntitlementPolicy' },
                              onClick: () => {
                                const user = users[key];
                                const currentYear = new Date().getFullYear();
                                handleUserStats(user, currentYear);
                                setSelectedUser(user);
                                setShowStats(true);
                              },
                            },
                          ]}
                          onRenderOverflowButton={onRenderOverflowButton}
                          onRenderItem={onRenderItem}
                        />
                        // <Popup
                        //   trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                        //   position="bottom right"
                        //   on="click"
                        //   open={showUserItemsActionPopup}
                        //   onClose={toggleUserTableItemsPopup}
                        // >
                        //   <div className="locConfigPopupOptions-popup">
                        //     {userTableItemsPopupOptions.map((option) => (
                        //       <button
                        //         key={option.id}
                        //         // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                        //         onClick={() => option.onClick(key, option.label)}
                        //         style={{ width: '90%' }}
                        //       >
                        //         {option.label}
                        //       </button>
                        //     ))}
                        //   </div>
                        // </Popup>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          }

          {users.length === 0 &&
            <tbody>
              <tr>
                <td colspan="4">
                  <EmptyDataPlaceHolder2 tableHeaderDetails={tableHeaderDetails} />
                </td>
              </tr>
            </tbody>
          }
        </table>
      </div>
      {/* )} */}

      {showUserForm &&
        <div className="location-form-container">
          <form
            action=""
            className="location-form-container-mainform"
            // onSubmit={handleUserFormSubmit}
            style={{ padding: '0px' }}
          >
            <div className="location-form-container-mainform-header">
              <h4>Configure User</h4>
              <img
                src={closeForm}
                alt="close"
                onClick={toggleUserForm}
              />
            </div>

            <div className="location-form-container-mainform-group-wrapper">
              <div className="form-group">
                <label htmlFor="">Name of Employee:</label>
                <input
                  type="text"
                  disabled
                  value={userFullName}
                  placeholder="Employee name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="">Role of Employee:</label>
                <select
                  name="employee role"
                  id="employee role"
                  value={userRole}
                  onChange={(event) => setUserRole(event.target.value)}
                  disabled={isViewForm}
                >
                  <option value="" disabled selected className="selected">
                    Please pick an employee role option
                  </option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                  <option value="Owner">Owner</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="">Location of Employee:</label>
                <select
                  name="user location"
                  id="user location"
                  value={userLocation}
                  onChange={(event) => setUserLocation(event.target.value)}
                  disabled={isViewForm}
                >
                  <option value="" disabled selected className="selected">
                    Please pick a location
                  </option>
                  {locations.map((location, key) => {
                    return (
                      <option value={location.locationName} key={key}>
                        {capitalizeFirstLetter(location.locationName)}
                      </option>
                    );
                  })}
                </select>
              </div>

              {!isViewForm &&
                <>
                  {!submitSpinner &&
                    <button
                      className="btn btn-update-location"
                      // btn btn-update-location
                      type="button"
                      onClick={async () => {
                        await handleUsersUpdate();
                        toggleUserForm();
                      }}
                    >
                      Update
                    </button>
                  }

                  {submitSpinner &&
                    <div
                      className="buttons buttons-spinner"
                      style={{ width: '90%', left: '10%' }}
                    >
                      <button
                        className="btn btn-update"
                        type="button"
                        onClick={handleUsersUpdate}
                      >
                        Update
                      </button>

                      {/* <button
                        class="btn btn-submit btn-with-spinner"
                        type="button"
                        onClick={handleUsersADSync}
                        disabled={submitSpinner}
                      >
                        <span class="spinner"></span>
                        Sync Users
                      </button> */}
                    </div>
                  }
                </>
              }
            </div>
          </form>
        </div>
      }

      {showStats && (
        <StatsUser
          toggleStats={toggleStats}
          leaveCalc={leaveCalc}
          setLeaveCalc={setLeaveCalc}
          reloadLeaveCalc={handleUserStats}
          user={selectedUser}
          access={"admin"}
        />
      )}
    </div>
  );
}

export default UserConfig;
