import './Dashboard.css';

import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import moment from 'moment';
import { useData } from '@microsoft/teamsfx-react';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import LeaveIcon from '../../../images/dashboard/LeaveIcon.png';
import ApproveLeaveIcon from '../../../images/dashboard/ApproveLeaveIcon.png';
import ApprovedLeaveIcon from '../../../images/dashboard/ApprovedLeaveIcon.png';
import PendingLeaveIcon from '../../../images/dashboard/PendingLeaveIcon.png';
import RejectedLeaveIcon from '../../../images/dashboard/RejectedLeaveIcon.png';

import LeaveRequestByDepartmentChart from './LeaveRequestByDepartmentChart';
import LeaveRequestByLeaveTypeChart from './LeaveRequestByLeaveTypeChart';
import LeaveTable from './LeaveTable';
import ApprovedLeaveChart from './ApprovedLeaveChart';
import ApprovalLeaveStatus from './ApprovalLeaveStatus';
import { handleBackendRequestBlockErrors } from '../../utils/errorHandlingUtils';
import NoData from './NoData';


// new imports due to tests
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import { Text, Icon, Callout } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { mergeStyles } from '@fluentui/react/lib/Styling';


import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles } from '@fluentui/react/lib/Button';

const noOp = () => undefined;

const checkboxStyles = {
  root: {
    margin: 10,
  },
};

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'More' }}
      menuProps={{ items: overflowItems }}
    />
  );
};


const currentDate = moment();
const lastOneYearDate = moment().subtract(3, 'months');

export default function Dashboard({ triggerConsent, apiClient }) {
  const [reportData, setReportData] = useState({});
  const [startDate, setStartDate] = useState(lastOneYearDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [dateRange, setDateRange] = useState([new Date(lastOneYearDate), new Date(currentDate)]);
  const [startDate2, endDate2] = dateRange;
  const [loadingReportDataFilter, setLoadingReportDataFilter] = useState(false)

  // load report data
  let { loading: loadingReportData, reload: reloadReportData } = useData(async () => {
    try {
      let responseReportData = apiClient.get(`/report/report?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`);
      responseReportData = (await responseReportData).data.data;
      setReportData(responseReportData);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  function getReportData(reportStartDate, reportEndDate) {
    (async () => {

      reportStartDate = moment(new Date(reportStartDate)).format('YYYY-MM-DD');
      reportEndDate = moment(new Date(reportEndDate)).format('YYYY-MM-DD');

      try {
        let responseReportData = apiClient.get(`/report/report?startDate=${reportStartDate}&endDate=${reportEndDate}`);
        responseReportData = (await responseReportData).data.data;
        setReportData(responseReportData);
      } catch (error) {
        handleBackendRequestBlockErrors(error, triggerConsent);
      }
    })();
  };

  useEffect(() => {
    function cb(start, end) {
      // Update the state with the selected date range
      setStartDate(start);
      setEndDate(end);

      // Update the span to display the selected date range
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));

      // call api to filter results
      getReportData(start, end);
    }

    $('#reportrange').daterangepicker({
      startDate: startDate,
      endDate: endDate,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);

    cb(startDate, endDate);
  }, [startDate, endDate]);


  // date filtering for mobile
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showCalendarPopup, setShowCalendarPopup] = useState(false);

  const toggleCalendarPopup = () => {
    setShowCalendarPopup(!showCalendarPopup);
  };

  const locConfigPopupOptions = [
    {
      id: 1, label: "Calendar", component:
        <div
          key={1}
          className='date-range-container'
          style={{ width: '90%' }}
        >
          {/* <i style={{position: 'absolute', top: '2px', left: '0px'}} className="fa fa-calendar"></i> */}
          <DatePicker
            showIcon
            icon="fa fa-calendar"
            selectsRange={true}
            startDate={startDate2}
            endDate={endDate2}
            dateFormat="dd-MM-yyyy"
            className='test'
            onChange={(update) => {
              setDateRange(update);

              if (update[1]) {
                getReportData(update[0], update[1]);
                toggleCalendarPopup(); // close calendar popup
              }
            }}
            withPortal
          />
        </div>
    },
  ];


  // test callout
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');

  const iconClass = mergeStyles({
    // fontSize: 50,
    // height: 50,
    // width: 50,
    margin: '0 25px',
    color: 'black'
  });

  const [showTest, setShowTest] = useState(false)

  const datePickerRef = useRef(null);

  const handleIconClick = () => {
    // Programmatically open the date picker when the icon is clicked
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    // observation about the react-google-charts Chart component so far is that if the 
    // width is to be responsive, the containing div has to have the overflow property
    // explicitly set to hidden
    <div>
      <div className="chart-container-header">
        <h1 className="chart-container-header-text">Admin Dashboard</h1>

        {/* <div id="reportrange" className='date-range-container'>
          <i className="fa fa-calendar"></i>&nbsp;
          <span></span>
          &nbsp;<i className="fa fa-caret-down"></i>
        </div> */}

          <div className='date-range-container'>
            <i
              className="fa fa-calendar calendar-icon dashboard-datepicker-mobile"
              onClick={handleIconClick}
            />
            <DatePicker
              ref={datePickerRef}
              showIcon={!isMobile}
              icon="fa fa-calendar"
              selectsRange={true}
              startDate={startDate2}
              endDate={endDate2}
              dateFormat="dd-MM-yyyy"
              className='dashboard-datepicker'
              onChange={(update) => {
                setDateRange(update);

                if (update[1]) {
                  getReportData(update[0], update[1]);
                }
              }}
              withPortal
            />
          </div>
      </div>

      <div class="grid-container">
        {
          showTest &&
          <div>thank you</div>
        }
        <div class="c-card-1">
          <div class="c-card-1-sub-1">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>All Request Counts</h4>
              <div className='grid-item-content-body'>
                <div className='grid-item-content-wrapper-stats'>
                  <img src={LeaveIcon} alt="" />
                  <p>{(loadingReportData || loadingReportDataFilter) ? "..." : reportData?.leaveRequestStats?.totalRequests || 0}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="c-card-1-sub-2">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>Approved Request(Hrs)</h4>
              <div className='grid-item-content-body'>
                <div className='grid-item-content-wrapper-stats'>
                  <img src={ApproveLeaveIcon} alt="" />
                  <p>{loadingReportData ? "..." : reportData?.leaveRequestStats?.totalApprovedHours || 0}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="c-card-1-sub-3">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>Approved Leave by Location</h4>
              <div className='grid-item-content-body'>
                {loadingReportData ?
                  <NoData type={"loading"} />
                  :
                  <ApprovedLeaveChart
                    className='chart'
                    approvedLeavesPerLocation={reportData?.approvedLeavesPerLocation}
                  />
                }
              </div>
            </div>
          </div>
        </div>

        <div class="t-card">
          <div class="grid-item-content-wrapper">
            <h4 className='grid-item-content-header'>Employee Currently on Leave</h4>
            <div className='grid-item-content-body'>
              <div className='grid-item-content-wrapper-table'>
                <div className='t-card-table'>
                  {loadingReportData ?
                    <NoData type={"loading"} />
                    :
                    <LeaveTable
                      activeLeaveRequests={reportData?.activeLeaveRequests}
                    />
                  }
                </div>
                <div className='t-card-table-footer'>
                  <p>Total Employees on leave : <b>{loadingReportData ? "..." : reportData?.activeLeaveRequests?.length || 0}</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="c-card-2">
          <div class="grid-item-content-wrapper">
            <h4 className='grid-item-content-header'>Request Status by Department</h4>
            <div className='grid-item-content-body'>
              {loadingReportData ?
                <NoData type={"loading"} />
                :
                <LeaveRequestByDepartmentChart
                  className='chart'
                  leavesPerDepartmentAndStatus={reportData?.leavesPerDepartmentAndStatus}
                />
              }
            </div>
          </div>
        </div>

        <div class="c-card-3">
          <div class="c-card-3-sub-1">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>Leave Approval Status</h4>
              <div className='grid-item-content-body'>
                {loadingReportData ?
                  <NoData type={"loading"} />
                  :
                  <ApprovalLeaveStatus
                    className='chart'
                    leaveRequestStats={reportData?.leaveRequestStats || {}}
                  />
                }
              </div>
            </div>
          </div>

          <div class="c-card-3-sub-2">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>Pending Request</h4>
              <div className='grid-item-content-body'>
                <div className='grid-item-content-wrapper-stats'>
                  <img src={PendingLeaveIcon} alt="" />
                  <p>{loadingReportData ? "..." : reportData?.leaveRequestStats?.pendingRequests || 0}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="c-card-3-sub-3">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>Approved Request</h4>
              <div className='grid-item-content-body'>
                <div className='grid-item-content-wrapper-stats'>
                  <img src={ApprovedLeaveIcon} alt="" />
                  <p>{loadingReportData ? "..." : reportData?.leaveRequestStats?.approvedRequests || 0}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="c-card-3-sub-4">
            <div class="grid-item-content-wrapper">
              <h4 className='grid-item-content-header'>Rejected Request</h4>
              <div className='grid-item-content-body'>
                <div className='grid-item-content-wrapper-stats'>
                  <img src={RejectedLeaveIcon} alt="" />
                  <p>{loadingReportData ? "..." : reportData?.leaveRequestStats?.rejectedRequests || 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="c-card-4">
          <div class="grid-item-content-wrapper">
            <h4 className='grid-item-content-header'>Request by Leave Type</h4>
            <div className='grid-item-content-body'>
              {loadingReportData ?
                <NoData type={"loading"} />
                :
                <LeaveRequestByLeaveTypeChart
                  className='chart'
                  leavesPerLeaveType={reportData?.leavesPerLeaveType}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};