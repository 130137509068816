import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import "./LeaveTypeConfig.scss";
import { useData } from "@microsoft/teamsfx-react";
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  handleBackendRequestBlockErrors,
  toasterSuccessMessage,
} from "../utils/errorHandlingUtils";
import SyncForm from "./LeavePolicySync";
import { capitalizeFirstLetter } from "../utils/generalUtils";
import EmptyDataPlaceHolder2 from "./EmptyDataPlaceHolder2";
import ConfirmAction from "../helperComponents/ConfirmAction";

import "./LocationForm.scss"
import closeForm from "../../images/cancel.png";

import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'MoreVertical' }}
      menuProps={{ items: overflowItems }}
    />
  );
};

function LeaveTypeConfig(props) {
  const { toggleState, apiClient, triggerConsent, user } = props;
  const [showSyncPolicyForm, setShowSyncPolicyForm] = useState(false);
  const [leaveTypeName, setLeaveTypeName] = useState("");
  const [leaveUnit, setLeaveUnit] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveTypeData, setLeaveTypeData] = useState({});
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [isNewLeaveTypeForm, setIsNewLeaveTypeForm] = useState(true);
  const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const confirmDeleteConfig = {
    header: "Delete Leave Type",
    body: `Are you sure you want to delete leave type, ${selectedLeaveType?.leaveTypeName}?`,
    action: "Delete"
  };

  // Function to show or hide policy form
  const toggleSyncPolicyForm = (syncPolicyFormState) => {
    setShowSyncPolicyForm(syncPolicyFormState);
  };

  // Get all leave types (for default location, with default leave policy)
  const { reload: reloadLeaveTypes } = useData(async () => {
    try {
      const resLeaveTypes = await apiClient.get(
        `/leaveType/leaveTypes?type=default`
      );
      setLeaveTypes(resLeaveTypes.data.data);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Get all active locations (custom -- formatted for multiselect dropdown component)
  const { reload: reloadLocationsData } = useData(async () => {
    try {
      let resLocations = await apiClient.get(`/location/locations`);
      let filteredLocationsData = [];

      resLocations.data.data.forEach((location) => {
        if (location.status === "Active") {
          filteredLocationsData.push({
            label: capitalizeFirstLetter(location.locationName),
            value: location.locationName,
          });
        }
      });

      setLocationsData(filteredLocationsData);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // handle sending leave type and default policy data to policy popup (only leave type data for new leave type)
  // const handleLeaveTypeFormSubmit = async () => {
  //   setLeaveTypeData({
  //     leaveType: {
  //       ...leaveTypeData.leaveType,
  //       leaveName: leaveTypeName,
  //       leaveUnit: leaveUnit,
  //       location: selectedLocation.map((location) => location.value),
  //     },
  //     leavePolicy: {
  //       ...leaveTypeData.leavePolicy,
  //     },
  //   });
  //   toggleSyncPolicyForm(true);
  // };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      let response;
      let data = {
        leaveType: {
          // ...leaveTypeData.leaveType,
          leaveName: leaveTypeName,
          leaveUnit: leaveUnit,
          status: leaveStatus,
          location: selectedLocation.map((location) => location.value),
        },
        leavePolicy: {
          // ...leaveTypeData.leavePolicy,
          // yearlyQuota: syncYearlyQuota,
          // paid: (paid) ? true : false,
          // cummulative: cummulative,
          // unlimited: (unlimited) ? true : false,
          // requireReliever: (syncRequireReliever === "Yes" ? true : false)
        }
      };
      if (isNewLeaveTypeForm) {
        response = await apiClient.post("/leaveType/new", data);
      } else {
        data.leaveType.leaveTypeId = selectedLeaveType.leaveTypeId;
        response = await apiClient.put("/leaveType/update", data);
      }
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
      handleLeaveTypeSubmissionCleanup();
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  }

  // handle actions to be done after full leave type data is submitted to db on policy popup
  const handleLeaveTypeSubmissionCleanup = () => {
    setLeaveTypeName("");
    setLeaveUnit("");
    setLeaveStatus("");
    setSelectedLocation([]);
    setIsNewLeaveTypeForm(true);
    toggleSyncPolicyForm(false);
    reloadLeaveTypes();
    reloadLocationsData();
  };

  // function to handle leave type edit button click event
  const handleLeaveTypeEdit = (event) => {
    const id = event.target.parentNode.parentNode.id;
    const leaveType = leaveTypes[id];

    // formatted location(s) in the selected leave type
    let locInLeaveType = leaveType.locations.map((location) => {
      return { label: capitalizeFirstLetter(location), value: location };
    });

    // merge unique location(s) in the selected leave type with the active locations in the tenant
    let mergedLocs = [...locInLeaveType, ...locationsData];
    let mergedUniqueLocs = [...new Map(mergedLocs.map(item => [item["value"], item])).values()];

    setSelectedLeaveType(leaveType);
    setLeaveTypeName(leaveType.leaveTypeName);
    setLeaveUnit(leaveType.leaveUnit);
    setLeaveStatus(leaveType.status);
    setSelectedLocation(locInLeaveType);
    setLocationsData(mergedUniqueLocs);
    setIsNewLeaveTypeForm(false);

    // setLeaveTypeData({
    //   leaveType: {
    //     leaveTypeId: leaveType.leaveTypeId,
    //   },
    //   leavePolicy: {
    //     leavePolicyId: leaveType.leavePolicyId,
    //     yearlyQuota: leaveType.yearlyQuota,
    //     paid: leaveType.paid === "true" ? true : false,
    //     cummulative: leaveType.cummulative === "true" ? true : false,
    //     unlimited: leaveType.unlimited === "true" ? true : false,
    //     requireReliever: leaveType.requireReliever === "true" ? "Yes" : "No",
    //   },
    // });

  };

  const handleDeleteButtonClick = (event) => {
    const id = event.target.parentNode.parentNode.id;
    const leaveType = leaveTypes[id];
    setSelectedLeaveType(leaveType);
    setShowConfirmDelete(true);
  };

  // function to handle deleting a leave type and associated leave policies
  const handleLeaveTypeDelete = async (event) => {
    try {
      event.preventDefault();
      const response = await apiClient.delete(
        `/leaveType/delete?leaveTypeId=${selectedLeaveType.leaveTypeId}`
      );
      toasterSuccessMessage(response.data.message);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadLeaveTypes();
    setSelectedLeaveType();
    setShowConfirmDelete(false);
  };

  const closeDeletePopup = () => {
    setSelectedLeaveType();
    setShowConfirmDelete(false);
    toggleLeaveTypeHeaderActionPopup();
    toggleNewLeaveTypeForm();
  };

  const tableHeaderDetails = [
    "Name of Leave Type",
    "Location",
    "Leave Unit",
    "",
  ];

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [showLeaveTypeForm, setShowLeaveTypeForm] = useState(false);
  const [showLeaveTypeHeaderActionPopup, setShowLeaveTypeHeaderActionPopup] = useState(false);
  const [showLeaveTypeItemsActionPopup, setShowLeaveTypeTableItemsPopup] = useState(false);
  const [isViewForm, setIsViewForm] = useState(false);

  // Function to show or hide new leave type form
  const toggleNewLeaveTypeForm = () => {
    setShowLeaveTypeForm(!showLeaveTypeForm);
  };

  const toggleLeaveTypeHeaderActionPopup = () => {
    setShowLeaveTypeHeaderActionPopup(!showLeaveTypeHeaderActionPopup);
  };

  const leaveTypeHeaderActionPopupOptions = [
    {
      id: 1, label: "Add Leave Type", onClick: () => {
        toggleNewLeaveTypeForm();
        setShowLeaveTypeHeaderActionPopup(false);
      }
    },
  ];


  // table items popup
  // function to handle leave type edit button click event
  const handleLeaveTypeViewOrEditMobile = (id, display) => {
    const leaveType = leaveTypes[id];

    // formatted location(s) in the selected leave type
    let locInLeaveType = leaveType.locations.map((location) => {
      return { label: capitalizeFirstLetter(location), value: location };
    });

    // merge unique location(s) in the selected leave type with the active locations in the tenant
    let mergedLocs = [...locInLeaveType, ...locationsData];
    let mergedUniqueLocs = [...new Map(mergedLocs.map(item => [item["value"], item])).values()];

    setSelectedLeaveType(leaveType);
    setLeaveTypeName(leaveType.leaveTypeName);
    setLeaveUnit(leaveType.leaveUnit);
    setLeaveStatus(leaveType.status);
    setSelectedLocation(locInLeaveType);
    setLocationsData(mergedUniqueLocs);
    setIsNewLeaveTypeForm(false);
    if (display === "View") {
      setIsViewForm(true);
    } else {
      setIsViewForm(false);
    }
    toggleNewLeaveTypeForm();
  };

  const toggleLeaveTypeTableItemsPopup = () => {
    setShowLeaveTypeTableItemsPopup(!showLeaveTypeItemsActionPopup);
  };

  const leaveTypeTableItemsPopupOptions = [
    { id: 1, label: "View", onClick: (id) => handleLeaveTypeViewOrEditMobile(id, "View") },
    { id: 2, label: "Edit", onClick: (id) => handleLeaveTypeViewOrEditMobile(id, "Edit") },
  ];

  return (
    <div className="leavetype-config-section">
      {!isMobile &&
        <>
          {/* leave type form  */}
          <form
            id="leavetype-form"
            action=""
            className={toggleState === 3 ? "content active-content" : "content"}
          >
            <div className="form-group">
              <label htmlFor="">Name of Leave Type:</label>
              <input
                type="text"
                value={leaveTypeName}
                onChange={(event) => setLeaveTypeName(event.target.value)}
                placeholder="e.g Annual Leave"
              />
            </div>

            <div className="form-group">
              <label htmlFor="">Leave Unit:</label>
              <select
                name="leave unit"
                id="leave unit"
                value={leaveUnit}
                onChange={(event) => setLeaveUnit(event.target.value)}
              >
                <option value="" disabled selected className="selected">
                  Please pick a unit
                </option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="">Leave Status:</label>
              <select
                name="leave status"
                id="leave status"
                value={leaveStatus}
                onChange={(event) => setLeaveStatus(event.target.value)}
              >
                <option value="" disabled selected className="selected">
                  Please pick a status
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="">Location(s):</label>
              <MultiSelect
                // className="multi-select"
                options={locationsData}
                value={selectedLocation}
                onChange={setSelectedLocation}
                labelledBy="Select"
              />
            </div>

            <button
              className="btn btn-content-submit"
              type="submit"
              onClick={handleSubmit}
            >
              {/* {isNewLeaveTypeForm ? "Add Policy" : "Update Policy"} */}
              {isNewLeaveTypeForm ? "Save" : "Update"}
            </button>
          </form>
        </>
      }

      {isMobile &&
        <div className="config-mobile-subsection-header">
          <p>Leave Type</p>
        </div>
      }

      {/* leave type table configuration  */}
      <div className="leave-type-config-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{!isMobile ? "Name of Leave Type" : "Leave Type"}</th>
              {!isMobile && <th>Location</th>}
              <th>Leave Unit</th>
              {!isMobile && <th>Leave Status</th>}
              <th>
                {isMobile &&
                  <>
                    <IconButton
                      ariaLabel="Add Leave Type"
                      title="Add Leave Type"
                      styles={{
                        root: {
                          backgroundColor: '#00000000',
                          width: '20px',
                          height: '20px'
                        },
                        menuIcon: {
                          fontSize: '18',
                          marginLeft: '12px'
                        }
                      }}
                      menuIconProps={{ iconName: 'Add' }}
                      onClick={toggleNewLeaveTypeForm}
                    />
                    {/* <Popup
                      trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                      position="bottom right"
                      on="click"
                      open={showLeaveTypeHeaderActionPopup}
                      onClose={toggleLeaveTypeHeaderActionPopup}
                    >
                      <div className="locConfigPopupOptions-popup">
                        {leaveTypeHeaderActionPopupOptions.map((option) => (
                          <button
                            key={option.id}
                            // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                            onClick={() => option.onClick()}
                            style={{ width: '90%' }}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    </Popup> */}
                  </>
                }
              </th>
            </tr>
          </thead>

          {leaveTypes.length > 0 &&
            <tbody>
              {leaveTypes.map((leave, key) => {
                return (
                  <tr key={key} id={key}>
                    <td>{leave.leaveTypeName || "N/A"}</td>
                    {!isMobile && <td>{capitalizeFirstLetter(leave.locations).join(", ") || "N/A"}</td>}
                    <td>{capitalizeFirstLetter(leave.leaveUnit) || "N/A"}</td>
                    {!isMobile && <td>{leave.status || "N/A"}</td>}
                    <td>
                      {/* <button
                      className="btn-table btn-delete"
                      onClick={handleDeleteButtonClick}
                    >
                      delete
                    </button> */}
                      {!isMobile
                        ?
                        <button
                          className="btn-table btn-edit"
                          onClick={handleLeaveTypeEdit}
                        >
                          edit
                        </button>
                        :
                        <>
                          <OverflowSet
                            // key={"user-table-items-overflowset"}
                            aria-label="more actions for user"
                            items={[]}
                            overflowItems={[
                              {
                                key: 'View',
                                name: 'View',
                                iconProps: { iconName: 'EntryView' },
                                onClick: () => handleLeaveTypeViewOrEditMobile(key, "View"),
                              },
                              {
                                key: 'Edit',
                                name: 'Edit',
                                iconProps: { iconName: 'PageEdit' },
                                onClick: () => handleLeaveTypeViewOrEditMobile(key, "Edit"),
                              },
                            ]}
                            onRenderOverflowButton={onRenderOverflowButton}
                            onRenderItem={onRenderItem}
                          />
                          {/* <Popup
                            trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                            position="bottom right"
                            on="click"
                            open={showLeaveTypeItemsActionPopup}
                            onClose={toggleLeaveTypeTableItemsPopup}
                          >
                            <div className="locConfigPopupOptions-popup">
                              {leaveTypeTableItemsPopupOptions.map((option) => (
                                <button
                                  key={option.id}
                                  // className={selectedTab === option.id ? 'tabs active-tabs' : 'tabs'}
                                  onClick={() => option.onClick(key, option.label)}
                                  style={{ width: '90%' }}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </Popup> */}
                        </>
                      }

                    </td>
                  </tr>
                );
              })}
            </tbody>
          }

          {leaveTypes.length === 0 &&
            <tbody>
              <tr>
                <td colspan="5">
                  <EmptyDataPlaceHolder2 tableHeaderDetails={tableHeaderDetails} />
                </td>
              </tr>
            </tbody>
          }
        </table>
      </div>


      {/* {showSyncPolicyForm && (
        <SyncForm
          apiClient={apiClient}
          user={user}
          triggerConsent={triggerConsent}
          leaveTypeData={leaveTypeData}
          toggleSyncPolicyForm={toggleSyncPolicyForm}
          handleLeaveTypeSubmissionCleanup={handleLeaveTypeSubmissionCleanup}
          isNewLeaveTypeForm={isNewLeaveTypeForm}
        />
      )} */}

      {showLeaveTypeForm &&
        <div className="location-form-container">
          <form
            action=""
            className="location-form-container-mainform"
          // onSubmit={handleSubmit}
          >
            <div className="location-form-container-mainform-header">
              <h4>Configure Leave Type</h4>
              <img
                src={closeForm}
                alt="close"
                onClick={() => {
                  handleLeaveTypeSubmissionCleanup();
                  setIsViewForm(false);
                  toggleNewLeaveTypeForm();
                }}
              />
            </div>

            <div className="location-form-container-mainform-group-wrapper">
              <div className="form-group">
                <label htmlFor="">Name of Leave Type:</label>
                <input
                  type="text"
                  value={leaveTypeName}
                  onChange={(event) => setLeaveTypeName(event.target.value)}
                  placeholder="e.g Annual Leave"
                  disabled={isViewForm}
                />
              </div>

              <div className="form-group">
                <label htmlFor="">Leave Unit:</label>
                <select
                  name="leave unit"
                  id="leave unit"
                  value={leaveUnit}
                  onChange={(event) => setLeaveUnit(event.target.value)}
                  disabled={isViewForm}
                >
                  <option value="" disabled selected className="selected">
                    Please pick a unit
                  </option>
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="">Leave Status:</label>
                <select
                  name="leave status"
                  id="leave status"
                  value={leaveStatus}
                  onChange={(event) => setLeaveStatus(event.target.value)}
                  disabled={isViewForm}
                >
                  <option value="" disabled selected className="selected">
                    Please pick a status
                  </option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="">Location(s):</label>
                <MultiSelect
                  // className="multi-select"
                  options={locationsData}
                  value={selectedLocation}
                  onChange={setSelectedLocation}
                  labelledBy="Select"
                  // disabled={isViewForm}
                />
              </div>

              {!isViewForm &&
                <button
                  className="btn btn-update-location"
                  type="submit"
                  onClick={async (event) => {
                    await handleSubmit(event);
                    toggleNewLeaveTypeForm();
                  }}
                >
                  {/* {isNewLeaveTypeForm ? "Add Policy" : "Update Policy"} */}
                  {isNewLeaveTypeForm ? "Save" : "Update"}
                </button>
              }
            </div>
          </form>
        </div>
      }

      {showConfirmDelete && (
        <ConfirmAction
          close={closeDeletePopup}
          submit={handleLeaveTypeDelete}
          config={confirmDeleteConfig}
        />
      )}
    </div>
  );
}

export default LeaveTypeConfig;
