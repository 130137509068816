import React from 'react'
// import { useMediaQuery } from 'react-responsive'

function TableHeaderPlaceHolder({tableHeaderDetails}) {
  // const isMobile = useMediaQuery({query: '(max-width: 767px)'});

  return (
   
    <thead
>      <tr>
        {tableHeaderDetails.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    </thead>
   
  )
}

export default TableHeaderPlaceHolder
