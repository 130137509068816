import React, { useState } from "react";
import "./ApprovalAction.scss";
import PersonImg from "../../images/leavetracker.png";
import cancelImg from "../../images/cancel.png";
import { useData } from "@microsoft/teamsfx-react";
import moment from "moment";
import {
  handleBackendRequestBlockErrors,
  toasterSuccessMessage,
  toasterErrorMessage,
} from "../utils/errorHandlingUtils";
import { useMediaQuery } from "react-responsive";

function ApprovalAction(props) {
  const {
    selectedLeave,
    apiClient,
    toggleApprovalACtion,
    triggerConsent,
    reloadRequests,
  } = props;
  // const [toggleActionTab, setToggleActionTab] = useState(1);
  const [approverNames, setApproverNames] = useState({});
  const [comment, setComment] = useState("");
  const [approvalAction, setApprovalAction] = useState("");

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // const toggleTab = (index) => {
  //   setToggleActionTab(index);
  // };

  // Get the reliever and line manager detail from leave request approval logs
  useData(async () => {
    try {
      let response = await apiClient.get(
        `/leave/track?leaveRequestId=${selectedLeave.leaveRequestId}`
      );
      // Reduce the reliever object to only contain names of reliever and linemanager
      response = response.data.data.reduce((accApprovers, curApprover) => {
        if (curApprover.approverRole === "Reliever") {
          accApprovers.reliever = curApprover.approverFullName;
        } else if (curApprover.approverRole === "Line Manager") {
          accApprovers.lineManager = curApprover.approverFullName;
        }
        return accApprovers;
      }, {});
      setApproverNames(response);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Function to handle submission of approval action
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

        // Check if the comment is empty before rejecting
    if (approvalAction === "Reject" && comment.trim() === "") {
      toasterErrorMessage("Please enter a comment before rejecting.");
      return; // Stop further execution if comment is empty
    }
      const apiEndpoint =
        approvalAction === "Approve" ? "/leave/approve" : "/leave/reject";
      const currentDateAndTime = new Date().toLocaleString("sv-SE");
      let data = {
        leaveRequestId: selectedLeave.leaveRequestId,
        approvalLogId: selectedLeave.approvalLogId,
        comment: comment,
        approvalDate: currentDateAndTime,
        modified: selectedLeave.modified,
      };
      const response = await apiClient.post(apiEndpoint, data);
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadRequests();
    toggleApprovalACtion();
  };

  return (
    <>
      {isMobile ? (
        <div className="approval-action-container">
          <div className="approval-action-container-tabs">
            {/* <div className="approval-action-container-tabs-header">
          <button
            className={toggleActionTab === 1 ? "tab active-tab" : "tab"}
            onClick={() => toggleTab(1)}
          >
            Leave Request Details
          </button>
          <button
            className={toggleActionTab === 2 ? "tab active-tab" : "tab"}
            onClick={() => toggleTab(2)}
          >
            Approve/Reject
          </button>
        </div> */}

            <h4>
              Leave Request{" "}
              <img
                src={cancelImg}
                alt=""
                onClick={() => {
                  toggleApprovalACtion(false);
                }}
              />{" "}
            </h4>

            <div className="approval-action-container-tabs-content">
              <div
                className="content"
                // className={
                //   toggleActionTab === 1 ? "content active-content" : "content"
                // }
              >
                <div className="content-header-one">
                  <div className="content-header-one-img">
                    <img src={PersonImg} alt="" />
                  </div>
                  <div className="content-header-one-texts">
                    <h5>
                      <span className="title-span">Leave Type: </span>{" "}
                      {selectedLeave?.leaveType}
                    </h5>
                    <h6>
                      <span className="title-span">Duration: </span>{" "}
                      {selectedLeave?.totalDays} {selectedLeave?.leaveUnit}{" "}
                      {selectedLeave?.leaveUnit === "hours"
                        ? "(" +
                          selectedLeave?.startTime +
                          " - " +
                          selectedLeave?.endTime +
                          ")"
                        : ""}
                    </h6>
                    <h5>
                      <span className="title-span">Approval Status: </span>{" "}
                      {selectedLeave?.approvalStatus}
                    </h5>
                  </div>
                </div>

                <div className="content-details">
                  <div className="content-container-textarea">
                    <div>
                      <label htmlFor="">Reliever:</label>
                      <input
                        type="text"
                        placeholder={approverNames?.reliever || "N/A"}
                        disabled
                      />
                    </div>

                    <div>
                      <label htmlFor="">Line Manager:</label>
                      <input
                        type="text"
                        placeholder={approverNames?.lineManager}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="content-container-leave-details">
                    <div>
                      <label htmlFor="">Start Date | End Date:</label>
                      <input
                        type="text"
                        placeholder={
                          moment(selectedLeave?.startDate)
                            .format("DD-MMM-YYYY")
                            .toUpperCase() +
                            " | " +
                            moment(selectedLeave?.endDate)
                              .format("DD-MMM-YYYY")
                              .toUpperCase() || "N/A"
                        }
                        disabled
                      />
                    </div>

                    <div>
                      <label htmlFor="">Reason for Leave:</label>
                      <textarea
                        name=""
                        id=""
                        placeholder={selectedLeave?.reason}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                </div>

                {/* <div className="close-btn">
              <button
                className="btn btn-approval-close"
                onClick={() => {
                  toggleApprovalACtion(false);
                }}
              >
                close
              </button>
            </div> */}
              </div>
              {/* 
          <div className="approval-action-back-btn">
            <img
              src={cancelImg}
              alt=""
              className={
                toggleActionTab === 2
                  ? "content active-content btn-cancel"
                  : "content"
              }
              onClick={() => {
                toggleApprovalACtion(false);
              }}
            />
          </div> */}

              <form
                action=""
                // className={
                //   toggleActionTab === 2 ? "content active-content" : "content"
                // }
                onSubmit={handleSubmit}
              >
                <div className="approval-form-group">
                  <label htmlFor="">Comment:</label>
                  <textarea
                    name=""
                    id=""
                    placeholder="Please enter a comment"
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                  ></textarea>
                </div>

                <div className="approval-actions">
                  <button
                    className="btn-form btn-approve-cancel"
                    onClick={() => {
                      toggleApprovalACtion(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-form btn-reject"
                    onClick={() => setApprovalAction("Reject")}
                  >
                    Reject
                  </button>
                  <button
                    className="btn-form btn-approve"
                    onClick={() => setApprovalAction("Approve")}
                  >
                    Approve
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="approval-action-container">
          <div className="approval-action-container-tabs">
            {/* <div className="approval-action-container-tabs-header">
        <button
          className={toggleActionTab === 1 ? "tab active-tab" : "tab"}
          onClick={() => toggleTab(1)}
        >
          Leave Request Details
        </button>
        <button
          className={toggleActionTab === 2 ? "tab active-tab" : "tab"}
          onClick={() => toggleTab(2)}
        >
          Approve/Reject
        </button>
      </div> */}

            <h4>
              Leave Request{" "}
              <img
                src={cancelImg}
                alt=""
                onClick={() => {
                  toggleApprovalACtion(false);
                }}
              />{" "}
            </h4>

            <div className="approval-action-container-tabs-content">
              <div
                className="content"
                // className={
                //   toggleActionTab === 1 ? "content active-content" : "content"
                // }
              >
                <div className="content-header-one">
                  <div className="content-header-one-img">
                    <img src={PersonImg} alt="" />
                  </div>
                  <div className="content-header-one-texts">
                    <h5>
                      <span className="title-span">Leave Type: </span>{" "}
                      {selectedLeave?.leaveType}
                    </h5>
                    <h6>
                      <span className="title-span">Duration: </span>{" "}
                      {selectedLeave?.totalDays} {selectedLeave?.leaveUnit}{" "}
                      {selectedLeave?.leaveUnit === "hours"
                        ? "(" +
                          selectedLeave?.startTime +
                          " - " +
                          selectedLeave?.endTime +
                          ")"
                        : ""}
                    </h6>
                    <h5>
                      <span className="title-span">Approval Status: </span>{" "}
                      {selectedLeave?.approvalStatus}
                    </h5>
                  </div>
                </div>

                <div className="content-details">
                  <div className="content-container-textarea">
                    <div>
                      <label htmlFor="">Reliever:</label>
                      <input
                        type="text"
                        placeholder={approverNames?.reliever || "N/A"}
                        disabled
                      />
                    </div>

                    <div>
                      <label htmlFor="">Line Manager:</label>
                      <input
                        type="text"
                        placeholder={approverNames?.lineManager}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="content-container-leave-details">
                    <div>
                      <label htmlFor="">Start Date | End Date:</label>
                      <input
                        type="text"
                        placeholder={
                          moment(selectedLeave?.startDate)
                            .format("DD-MMM-YYYY")
                            .toUpperCase() +
                            " | " +
                            moment(selectedLeave?.endDate)
                              .format("DD-MMM-YYYY")
                              .toUpperCase() || "N/A"
                        }
                        disabled
                      />
                    </div>

                    <div>
                      <label htmlFor="">Reason for Leave:</label>
                      <textarea
                        name=""
                        id=""
                        placeholder={selectedLeave?.reason}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                </div>

                {/* <div className="close-btn">
            <button
              className="btn btn-approval-close"
              onClick={() => {
                toggleApprovalACtion(false);
              }}
            >
              close
            </button>
          </div> */}
              </div>
              {/* 
        <div className="approval-action-back-btn">
          <img
            src={cancelImg}
            alt=""
            className={
              toggleActionTab === 2
                ? "content active-content btn-cancel"
                : "content"
            }
            onClick={() => {
              toggleApprovalACtion(false);
            }}
          />
        </div> */}

              <form
                action=""
                // className={
                //   toggleActionTab === 2 ? "content active-content" : "content"
                // }
                onSubmit={handleSubmit}
              >
                <div className="approval-form-group">
                  <label htmlFor="">Comment:</label>
                  <textarea
                    name=""
                    id=""
                    placeholder="Please enter a comment"
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                  ></textarea>
                </div>

                <div className="approval-actions">
                  <button
                    className="btn-form btn-approve-cancel"
                    onClick={() => {
                      toggleApprovalACtion(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-form btn-reject"
                    onClick={() => setApprovalAction("Reject")}
                  >
                    Reject
                  </button>
                  <button
                    className="btn-form btn-approve"
                    onClick={() => setApprovalAction("Approve")}
                  >
                    Approve
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ApprovalAction;
