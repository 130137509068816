import React, { useState } from "react";
import "./Settings.scss";
import UserSettings from "./UserConfig";
import ApproverConfig from "./ApproverConfig";
import LeaveTypeConfig from "./LeaveTypeConfig";
import LeavePolicyConfig from "./LeavePolicyConfig";
import LocationConfig from "./LocationConfig";
import DesktopOnly from "./DesktopOnly";
import { useMediaQuery } from "react-responsive";
import { FaEllipsisV } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import { IOverflowSetItemProps, OverflowSet, Checkbox } from '@fluentui/react';
import { CommandBarButton, IButtonStyles } from '@fluentui/react/lib/Button';

const onRenderItem = (item) => {
  if (item.onRender) {
    return item.onRender(item);
  }
  return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems) => {
  const buttonStyles = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 'auto',
      backgroundColor: '#00000000'
    },
    menuIcon: {
      fontSize: '16'
    },
  };
  return (
    <CommandBarButton
      ariaLabel="Menu"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'CollapseMenu' }}
      menuProps={{ items: overflowItems }}
    />
  );
};


function Settings(props) {
  const { apiClient, triggerConsent, user } = props;
  const [toggleState, setToggleState] = useState(1);
  const [selectedTab, setSelectedTab] = useState(1);
  const [showTabsPopup, setShowTabsPopup] = useState(false);

  // const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const toggleTabsPopup = () => {
    setShowTabsPopup(!showTabsPopup);
  };

  const handleTabClick = (tabIndex) => {
    setShowTabsPopup(false);
    setSelectedTab(tabIndex);
    toggleTab(tabIndex);
  };

  const tabs = [
    { id: 1, label: "User", component: <UserSettings apiClient={apiClient} triggerConsent={triggerConsent} /> },
    { id: 2, label: "Approver's Log", component: <ApproverConfig apiClient={apiClient} triggerConsent={triggerConsent} /> },
    { id: 3, label: 'Leave Type', component: <LeaveTypeConfig apiClient={apiClient} triggerConsent={triggerConsent} user={user} /> },
    { id: 4, label: 'Leave Policies', component: <LeavePolicyConfig apiClient={apiClient} triggerConsent={triggerConsent} /> },
    { id: 5, label: 'Location', component: <LocationConfig apiClient={apiClient} triggerConsent={triggerConsent} user={user} /> },
  ];

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className="settings-wrapper">
        <div className="settings-tab-container">
          <div className="settings-tab-header">
            <h3>configuration settings
            </h3>
            <span className='alignright'>
              {isMobile && (
                <>
                  <OverflowSet
                    // key={"user-table-items-overflowset"}
                    aria-label="more actions for user"
                    items={[]}
                    overflowItems={[
                      {
                        key: 'Users',
                        name: 'Users',
                        iconProps: { iconName: 'People' },
                        onClick: () => handleTabClick(1),
                      },
                      {
                        key: 'Approvers',
                        name: "Approver's Log",
                        iconProps: { iconName: 'Contact' },
                        onClick: () => handleTabClick(2),
                      },
                      {
                        key: 'Leave Type',
                        name: 'Leave Type',
                        iconProps: { iconName: 'HomeVerify' },
                        onClick: () => handleTabClick(3),
                      },
                      {
                        key: 'Leave Policies',
                        name: 'Leave Policies',
                        iconProps: { iconName: 'EntitlementPolicy' },
                        onClick: () => handleTabClick(4),
                      },
                      {
                        key: 'Location',
                        name: 'Location',
                        iconProps: { iconName: 'MapPin' },
                        onClick: () => handleTabClick(5),
                      },
                    ]}
                    onRenderOverflowButton={onRenderOverflowButton}
                    onRenderItem={onRenderItem}
                  />
                  {/* <Popup
                    trigger={<span className="popup-trigger"><FaEllipsisV /> </span>}
                    position="bottom right"
                    on="click"
                    open={showTabsPopup}
                    onClose={toggleTabsPopup}
                  >
                    <div className="tabs-popup">
                      {tabs.map((tab) => (
                        <button
                          key={tab.id}
                          className={selectedTab === tab.id ? 'tabs active-tabs' : 'tabs'}
                          onClick={() => handleTabClick(tab.id)}
                          style={{ width: '90%' }}
                        >
                          {tab.label}
                        </button>
                      ))}
                    </div>
                  </Popup> */}
                </>
              )}
            </span>
          </div>

          <div className="dummy-div-for-grid-spacing"></div>

          <div className="settings-tab-nav">
            {!isMobile &&
              <>
                {/* user tab  */}
                <button
                  className={toggleState === 1 ? "tab active-tab" : "tab"}
                  onClick={() => {
                    toggleTab(1);
                    setSelectedTab(1);
                  }}
                >
                  User
                </button>

                {/* approver log tab  */}
                <button
                  className={toggleState === 2 ? "tab active-tab" : "tab"}
                  onClick={() => {
                    toggleTab(2);
                    setSelectedTab(2);
                  }}
                >
                  Approver's Log
                </button>

                {/* leave type tab  */}
                <button
                  className={toggleState === 3 ? "tab active-tab" : "tab"}
                  onClick={() => {
                    toggleTab(3);
                    setSelectedTab(3);
                  }}
                >
                  Leave Type
                </button>

                {/* leave policies tab  */}
                <button
                  className={toggleState === 4 ? "tab active-tab" : "tab"}
                  onClick={() => {
                    toggleTab(4);
                    setSelectedTab(4);
                  }}
                >
                  Leave Policies
                </button>

                {/* location tab  */}
                <button
                  className={toggleState === 5 ? "tab active-tab" : "tab"}
                  onClick={() => {
                    toggleTab(5);
                    setSelectedTab(5);
                  }}
                >
                  Location
                </button>
              </>
            }
          </div>

          <div className="settings-tab-content-container">
            <div>{tabs.find((tab) => tab.id === selectedTab)?.component}</div>
          </div>

          <div className="desktop-only-view">
            <DesktopOnly />
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
