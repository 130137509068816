import React, { useState } from "react";
import "./Navbar.scss";
import "../App.css";

function Navbar(props) {
  const { selectNav, user } = props;
  const [toggleLink, setToggleLink] = useState(1);

  const toggle = (i) => {
    setToggleLink(i);
  };

  return (
    <div>
      <nav className="nav-header">
        <ul>
          {["Owner", "Admin"].includes(user?.role) && (
            <li>
              <p
                className={toggleLink === 1 ? "active-link" : ""}
                onClick={() => {
                  toggle(1);
                  selectNav("Dashboard");
                }}
              >
                Dashboard
              </p>
            </li>
          )}

          <li className="new-request-invisible">
            <p
              className={toggleLink === 2 ? "active-link" : ""}
              onClick={() => {
                toggle(2);
                selectNav("New Request");
              }}
            >
              New Request
            </p>
          </li>

          <li>
            <p
              className={toggleLink === 3 ? "active-link" : ""}
              onClick={() => {
                toggle(3);
                selectNav("User Requests");
              }}
            >
              My Requests
            </p>
          </li>

          {["Owner", "Admin"].includes(user?.role) && (
            <li>
              <p
                className={toggleLink === 4 ? "active-link" : ""}
                onClick={() => {
                  toggle(4);
                  selectNav("All Requests");
                }}
              >
                All Requests
              </p>
            </li>
          )}

          <li>
            <p
              className={toggleLink === 5 ? "active-link" : ""}
              onClick={() => {
                toggle(5);
                selectNav("Approvals");
              }}
            >
              Approvals
            </p>
          </li>

          {["Owner", "Admin"].includes(user?.role) && (
            <li>
              <p
                className={toggleLink === 6 ? "active-link" : ""}
                onClick={() => {
                  toggle(6);
                  selectNav("Settings");
                }}
              >
                Settings
              </p>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
