import React, { useState } from "react";
import "./EditForm.scss";
import btn_Cancel from "../../images/cancel.png";
import { useData } from "@microsoft/teamsfx-react";
import {
  handleBackendRequestBlockErrors,
  toasterErrorMessage,
  toasterSuccessMessage,
} from "../utils/errorHandlingUtils";

import { useMediaQuery } from "react-responsive";

function EditForm(props) {
  const {
    selectedLeave,
    toggleEditForm,
    toggleView,
    user,
    apiClient,
    triggerConsent,
    reloadRequests,
  } = props;
  const [leaveType, setLeaveType] = useState(selectedLeave.leaveType);
  const [startDate, setStartDate] = useState(
    new Date(selectedLeave.startDate).toLocaleDateString("sv-SE")
  ); // change date format to 1850-11-20
  const [endDate, setEndDate] = useState(
    new Date(selectedLeave.endDate).toLocaleDateString("sv-SE")
  );
  const [startTime, setStartTime] = useState(selectedLeave.startTime);
  const [endTime, setEndTime] = useState(selectedLeave.endTime);
  const [totalTime, setTotalTime] = useState(selectedLeave.totalDays);
  const [reason, setReason] = useState(selectedLeave.reason);
  const [myLeaveTypes, setMyLeaveTypes] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // Get all users' data
  useData(async () => {
    try {
      // const res = await apiClient.get(`/user/users`);
      // setUsers(res.data.data);
      const resLeaveTypes = (
        await apiClient.get(
          `/leaveType/leaveTypes?type=user&location=${user.appLocation}`
        )
      ).data.data;
      setMyLeaveTypes(resLeaveTypes);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  // Function to handle submitting editted leave request
  const handleResubmit = async (event) => {
    try {
      event.preventDefault();
      // const reliever = users?.find((user) => { return user.adId === relieverADId })
      let data = {
        leaveRequestId: selectedLeave.leaveRequestId,
        leaveType: leaveType,
        startDate: startDate,
        endDate: endDate,
        startTime: selectedLeave.leaveUnit === "days" ? "00:00" : startTime,
        endTime: selectedLeave.leaveUnit === "days" ? "00:00" : endTime,
        totalDays: totalTime,
        reason: reason,
      };
      const response = await apiClient.put("/leave/update", data);
      toasterSuccessMessage(response.data.message); // TODO: Revisit the success message shown
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
    reloadRequests();
    toggleEditForm(false);
    toggleView(false);
  };

  // Function to handle calculating total days (or hours) and leave type days validation (for days leave unit)
  let calcTotalTime = async (end, start) => {
    // total time calculation for leave type (with hours as leave unit)
    if (!!end && !!start && selectedLeave.leaveUnit === "hours") {
      end = new Date("01/01/2001 " + end);
      start = new Date("01/01/2001 " + start);
      let total = (end - start) / (1000 * 3600);

      if (total < 0) {
        toasterErrorMessage("End time is before start time.");
        setEndTime("");
        setTotalTime(0);
        return;
      }
      setTotalTime(total);
      return;
    }

    // total time calculation for leave type (with days as leave unit)
    end = new Date(end);
    start = new Date(start);
    let total = (end.getTime() - start.getTime()) / (1000 * 3600 * 24) + 1;

    if (total < 0) {
      toasterErrorMessage("End date is before start date");
      setEndDate("");
      setTotalTime(0);
      return;
    }

    setTotalTime(total ? total : 0);
    let leaveTypeDetail = myLeaveTypes.find(
      (type) => type.leaveType === leaveType
    );
    if (total > leaveTypeDetail.yearlyQuota) {
      toasterErrorMessage(
        `Number of days available for ${leaveType} is ${leaveTypeDetail.yearlyQuota} day(s).`
      );
    }
  };
  return (
    <div className="edit-container">
      {isMobile ? (
        <form
          className="edit-container-form"
          onSubmit={handleResubmit}
          method="get"
        >
          <div className="edit-container-form-header">
            <h4>Leave Request</h4>
            <img
              src={btn_Cancel}
              alt="cancel"
              onClick={() => {
                toggleView(false);
                toggleEditForm(false);
              }}
            />
          </div>

          <div className="edit-container-form-group">
            <div>
              <label htmlFor="leave-type">Leave Type:</label>
              <select
                name="leave type"
                id="leave-type"
                value={leaveType}
                onChange={(event) => setLeaveType(event.target.value)}
                disabled
              >
                <option value="" disabled selected className="selected">
                  Please pick a leave type
                </option>
                {myLeaveTypes.map((leaveType, key) => {
                  return (
                    <option value={leaveType.leaveType} key={key}>
                      {leaveType.leaveType}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <label htmlFor="start-date">Start Date:</label>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                value={startDate}
                onChange={(event) => {
                  setStartDate(event.target.value);
                  if (selectedLeave.leaveUnit === "days") {
                    calcTotalTime(endDate, event.target.value);
                  } else {
                    setEndDate(event.target.value);
                  }
                }}
              />
            </div>

            {selectedLeave.leaveUnit !== "hours" && (
              <div>
                <label htmlFor="end-date">End Date:</label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  value={endDate}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                    calcTotalTime(event.target.value, startDate);
                  }}
                />
              </div>
            )}

            {selectedLeave.leaveUnit === "hours" && (
              <div className="form-group">
                <label htmlFor="start-time">Start Time:</label>
                <input
                  type="time"
                  placeholder="hh:mm"
                  value={startTime}
                  onChange={(event) => {
                    setStartTime(event.target.value);
                    calcTotalTime(endTime, event.target.value);
                  }}
                />
              </div>
            )}

            {selectedLeave.leaveUnit === "hours" && (
              <div className="form-group">
                <label htmlFor="end-time">End Time:</label>
                <input
                  type="time"
                  placeholder="hh:mm"
                  value={endTime}
                  onChange={(event) => {
                    setEndTime(event.target.value);
                    calcTotalTime(event.target.value, startTime);
                  }}
                />
              </div>
            )}

            <div>
              <label htmlFor="total-days">
                Total Time ({selectedLeave.leaveUnit || "N/A"}):
              </label>
              <input
                type="number"
                placeholder="Please, input a number"
                value={totalTime}
                onChange={(event) => setTotalTime(event.target.value)}
              />
            </div>

            <div>
              <label htmlFor="reason">Reason for Leave:</label>
              <textarea
                type="text"
                placeholder="Please explain the reason for your choice of leave type"
                value={reason}
                onChange={(event) => setReason(event.target.value)}
              />
            </div>

            <button
              type="submit"
              className="btn btn-resubmit"
              id="btn-resubmit"
            >
              submit
            </button>
          </div>
        </form>
      ) : (
        <form
          className="edit-container-form"
          onSubmit={handleResubmit}
          method="get"
        >
          <div className="edit-container-form-header">
            <h4>Leave Request</h4>
            <img
              src={btn_Cancel}
              alt="cancel"
              onClick={() => {
                toggleView(false);
                toggleEditForm(false);
              }}
            />
          </div>

          <div className="edit-container-form-group">
            <div>
              <label htmlFor="leave-type">Leave Type:</label>
              <select
                name="leave type"
                id="leave-type"
                value={leaveType}
                onChange={(event) => setLeaveType(event.target.value)}
                disabled
              >
                <option value="" disabled selected className="selected">
                  Please pick a leave type
                </option>
                {myLeaveTypes.map((leaveType, key) => {
                  return (
                    <option value={leaveType.leaveType} key={key}>
                      {leaveType.leaveType}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <label htmlFor="start-date">Start Date:</label>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                value={startDate}
                onChange={(event) => {
                  setStartDate(event.target.value);
                  if (selectedLeave.leaveUnit === "days") {
                    calcTotalTime(endDate, event.target.value);
                  } else {
                    setEndDate(event.target.value);
                  }
                }}
              />
            </div>

            {selectedLeave.leaveUnit !== "hours" && (
              <div>
                <label htmlFor="end-date">End Date:</label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  value={endDate}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                    calcTotalTime(event.target.value, startDate);
                  }}
                />
              </div>
            )}

            {selectedLeave.leaveUnit === "hours" && (
              <div className="form-group">
                <label htmlFor="start-time">Start Time:</label>
                <input
                  type="time"
                  placeholder="hh:mm"
                  value={startTime}
                  onChange={(event) => {
                    setStartTime(event.target.value);
                    calcTotalTime(endTime, event.target.value);
                  }}
                />
              </div>
            )}

            {selectedLeave.leaveUnit === "hours" && (
              <div className="form-group">
                <label htmlFor="end-time">End Time:</label>
                <input
                  type="time"
                  placeholder="hh:mm"
                  value={endTime}
                  onChange={(event) => {
                    setEndTime(event.target.value);
                    calcTotalTime(event.target.value, startTime);
                  }}
                />
              </div>
            )}

            <div>
              <label htmlFor="total-days">
                Total Time ({selectedLeave.leaveUnit || "N/A"}):
              </label>
              <input
                type="number"
                placeholder="Please, input a number"
                value={totalTime}
                onChange={(event) => setTotalTime(event.target.value)}
              />
            </div>

            <div>
              <label htmlFor="reason">Reason for Leave:</label>
              <textarea
                type="text"
                placeholder="Please explain the reason for your choice of leave type"
                value={reason}
                onChange={(event) => setReason(event.target.value)}
              />
            </div>

            <button
              type="submit"
              className="btn btn-resubmit"
              id="btn-resubmit"
            >
              submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default EditForm;
