// TableComponent.js
import './LeaveTable.css';
import React from 'react';
import moment from 'moment';
import NoData from './NoData';

const LeaveTable = ({ activeLeaveRequests = [] }) => {
  return (
    <div className='t-card-table-container'>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Department</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Leave Type</th>
            <th>Location</th>
          </tr>
        </thead>
        {activeLeaveRequests?.length > 0 &&
          <tbody>
            {activeLeaveRequests.map((item) => (
              <tr key={item.leaveRequestId}>
                <td>{item.fullName}</td>
                <td>{item.department}</td>
                <td>{moment(item.startDate)
                  .format("DD-MMM-YYYY")
                  .toLocaleUpperCase()}</td>
                <td>{moment(item.endDate)
                  .format("DD-MMM-YYYY")
                  .toLocaleUpperCase()}</td>
                <td>{item.leaveType}</td>
                <td>{item.location}</td>
              </tr>
            ))}
          </tbody>
        }
      </table>
      {activeLeaveRequests?.length === 0 &&
        <div className='t-card-table-container-empty-mobile'>
          <NoData size={"mid"} />
        </div>
      }
    </div>
  );
};

export default LeaveTable;
