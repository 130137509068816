import React, { useState } from "react";
import "./AllRequestsView.scss";
import PersonImage from "../../images/leavetracker.png";
import btn_Cancel from "../../images/cancel.png";
import { useData } from "@microsoft/teamsfx-react";
import { handleBackendRequestBlockErrors } from "../utils/errorHandlingUtils";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

function AllRequestsView(props) {
  const { selectedLeave, apiClient, toggleViewAll, triggerConsent } = props;
  const [approverNames, setApproverNames] = useState({});

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // Get the reliever and line manager detail from leave request approval logs
  useData(async () => {
    try {
      let response = await apiClient.get(
        `/leave/track?leaveRequestId=${selectedLeave.leaveRequestId}`
      );
      // Reduce the reliever object to only contain names of reliever and linemanager
      response = response.data.data.reduce((accApprovers, curApprover) => {
        if (curApprover.approverRole === "Reliever") {
          accApprovers.reliever = curApprover.approverFullName;
          accApprovers.relieverId = curApprover.approverADId;
        } else if (curApprover.approverRole === "Line Manager") {
          accApprovers.lineManager = curApprover.approverFullName;
          accApprovers.lineManagerId = curApprover.approverADId;
        }
        return accApprovers;
      }, {});
      setApproverNames(response);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  });

  return (
    <div className="all-requests-view-container">
      {isMobile ? (
        <div className="view-details-container">
          <div className="view-details-container-header-one">
            <h4>Leave Request</h4>
            <img
              src={btn_Cancel}
              alt="cancel button"
              className="btn-cancel"
              onClick={() => {
                toggleViewAll(false);
              }}
            />
          </div>

          <div className="view-details-container-header-two">
            <div className="view-details-container-header-two-img">
              <img src={PersonImage} alt="" />
            </div>
            <div className="view-details-container-header-two-texts">
              <h5>
                <span className="title-span">Leave Type: </span>{" "}
                {selectedLeave?.leaveType}
              </h5>
              <h6>
                {" "}
                <span className="title-span">Duration: </span>
                {selectedLeave?.totalDays} {selectedLeave?.leaveUnit}{" "}
                {selectedLeave?.leaveUnit === "hours"
                  ? "(" +
                    selectedLeave?.startTime +
                    " - " +
                    selectedLeave?.endTime +
                    ")"
                  : ""}
              </h6>
              <h5>
                <span className="title-span">Approval Status: </span>
                {selectedLeave?.approvalStatus}
              </h5>
            </div>
          </div>

          <div className="view-details-container-textarea">
            <label htmlFor="">Reason for Leave:</label>
            <textarea
              name=""
              id=""
              placeholder={selectedLeave?.reason}
              disabled
            ></textarea>
          </div>

          <div className="view-details-container-leave-details">
            <div>
              <label htmlFor="">Reliever:</label>
              <input
                type="text"
                placeholder={approverNames?.reliever || "N/A"}
                disabled
              />
            </div>
            <div>
              <label htmlFor="">Start Date | End Date:</label>
              <input
                type="text"
                placeholder={
                  moment(selectedLeave?.startDate)
                    .format("DD-MMM-YYYY")
                    .toUpperCase() +
                    " | " +
                    moment(selectedLeave?.endDate)
                      .format("DD-MMM-YYYY")
                      .toUpperCase() || "N/A"
                }
                disabled
              />
            </div>

            <div>
              <label htmlFor="">Line Manager:</label>
              <input
                type="text"
                placeholder={approverNames?.lineManager}
                disabled
              />
            </div>
          </div>

          <button
            className="btn btn-request-close"
            onClick={() => {
              toggleViewAll(false);
            }}
          >
            close
          </button>
        </div>
      ) : (
        <div className="view-details-container">
          <div className="view-details-container-header-one">
            <h4>Leave Request</h4>
            <img
              src={btn_Cancel}
              alt="cancel button"
              className="btn-cancel"
              onClick={() => {
                toggleViewAll(false);
              }}
            />
          </div>

          <div className="view-details-container-header-two">
            <div className="view-details-container-header-two-img">
              <img src={PersonImage} alt="" />
            </div>
            <div className="view-details-container-header-two-texts">
              <h5>
                <span className="title-span">Leave Type: </span>{" "}
                {selectedLeave?.leaveType}
              </h5>
              <h6>
                {" "}
                <span className="title-span">Duration: </span>
                {selectedLeave?.totalDays} {selectedLeave?.leaveUnit}{" "}
                {selectedLeave?.leaveUnit === "hours"
                  ? "(" +
                    selectedLeave?.startTime +
                    " - " +
                    selectedLeave?.endTime +
                    ")"
                  : ""}
              </h6>
              <h5>
                <span className="title-span">Approval Status: </span>
                {selectedLeave?.approvalStatus}
              </h5>
            </div>
          </div>

          <div className="view-details-container-textarea">
            <label htmlFor="">Reason for Leave:</label>
            <textarea
              name=""
              id=""
              placeholder={selectedLeave?.reason}
              disabled
            ></textarea>
          </div>

          <div className="view-details-container-leave-details">
            <div>
              <label htmlFor="">Reliever:</label>
              <input
                type="text"
                placeholder={approverNames?.reliever || "N/A"}
                disabled
              />
            </div>
            <div>
              <label htmlFor="">Start Date | End Date:</label>
              <input
                type="text"
                placeholder={
                  moment(selectedLeave?.startDate)
                    .format("DD-MMM-YYYY")
                    .toUpperCase() +
                    " | " +
                    moment(selectedLeave?.endDate)
                      .format("DD-MMM-YYYY")
                      .toUpperCase() || "N/A"
                }
                disabled
              />
            </div>

            <div>
              <label htmlFor="">Line Manager:</label>
              <input
                type="text"
                placeholder={approverNames?.lineManager}
                disabled
              />
            </div>
          </div>

          <button
            className="btn btn-request-close"
            onClick={() => {
              toggleViewAll(false);
            }}
          >
            close
          </button>
        </div>
      )}
    </div>
  );
}

export default AllRequestsView;
