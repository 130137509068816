import React from 'react';
import { Chart } from 'react-google-charts';
import NoData from './NoData';

const LeaveRequestByLeaveTypeChart = ({ leavesPerLeaveType = [] }) => {
  return (
    <div>
      {leavesPerLeaveType?.length === 1 ?
        <NoData />
        :
        <Chart
          height={'99%'}
          width={'100%'}
          chartType="BarChart"
          data={leavesPerLeaveType}
          options={{
            hAxis: {
              title: 'Number of Requests',
            },
            vAxis: {
              title: 'Leave Type',
            },
            legend: { position: 'none' },
          }}
        />
      }
    </div>
  );
};

export default LeaveRequestByLeaveTypeChart;
