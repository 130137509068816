import React, { useState } from "react";
import closeForm from "../../images/cancel.png";
import { useData } from "@microsoft/teamsfx-react";
import { MultiSelect } from "react-multi-select-component";
import "./LocationForm.scss"
import { handleBackendRequestBlockErrors } from "../utils/errorHandlingUtils";

function LocationForm(props) {
  const { apiClient, triggerConsent, toggleContinueForm, resetLocationName, locationData, setLocationData, handleLocationSubmit, users } = props;
  const [defaultLocUsers, setDefaultLocUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [locationName, setLocationName] = useState(locationData?.locationName || "");
  const [officeAddress, setOfficeAddress] = useState(locationData?.address || "");
  const [workweek, setWorkweek] = useState(restructureWorkweekEdit() || {});
  const [locationStatus, setLocationStatus] = useState(locationData?.status || "");
  let weekstart;

  // (when editing a location): function to restructure workweek for selected location
  function restructureWorkweekEdit() {
    if (locationData?.isEdit) {
      let workweek = {};
      locationData.workweek.forEach((day) => {
        workweek[day] = true;
      });
      return workweek;
    } else {
      return undefined;
    }
  }

  // (when editing a location): function to get names of existing users in a location and restructure
  const getUsersNamesEdit = async () => {
    let selectedLocationUsers = [...locationData.users];
    selectedLocationUsers = selectedLocationUsers.map((userId) => {
      let fullUserData = users.find((user) => user.adId === userId);
      return { label: fullUserData.fullName, value: fullUserData.adId };
    })
    return selectedLocationUsers;
  }

  // Get all users in the default location
  useData(async () => {
    let selectedLocationUsers; // to be used when editing existing location
    try {
      if (locationData.isEdit) {
        selectedLocationUsers = getUsersNamesEdit();
      }
      let usersWithoutLocation = (await apiClient.get(`/user/users?location=none`)).data.data;
      usersWithoutLocation = usersWithoutLocation.map((user) => {
        return { label: user.fullName, value: user.adId };
      })
      // add selected location's users (edit) to default location users and set selected users
      if (locationData.isEdit) {
        selectedLocationUsers = await selectedLocationUsers;
        usersWithoutLocation.unshift(...selectedLocationUsers);
        setSelectedUsers(selectedLocationUsers);
      }
      setDefaultLocUsers(usersWithoutLocation);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
    }
  })

  // function to handle changes to workweek checkboxes
  const handleWorkweekChange = async (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setWorkweek(values => ({ ...values, [name]: checked }));
  }

  // function to handle form submit event
  const handleSubmit = (event) => {
    event.preventDefault();
    let data = locationData;
    data = {
      ...data,
      workweek: processWorkweek(workweek),
      weekstart: weekstart,
      users: selectedUsers.map((user) => user.value), // return just the users' adId
      locationName: locationName,
      address: officeAddress,
      status: locationStatus
    };
    handleLocationSubmit(data);
    toggleContinueForm(false);
  }

  // function to restructure and sort workweek state variable
  const processWorkweek = (week) => {
    const weekdayRanking = {
      sunday: 1,
      monday: 2,
      tuesday: 3,
      wednesday: 4,
      thursday: 5,
      friday: 6,
      saturday: 7
    };
    let processedWeek = [];
    for (let weekDay in week) {
      if (week[weekDay] === true) {
        processedWeek.push(weekDay);
      }
    }
    processedWeek.sort((a, b) => weekdayRanking[a] - weekdayRanking[b]);
    weekstart = processedWeek[0]; // set the weekstart variable
    return processedWeek;
  }

  return (
    <div className="location-form-container">
      <form
        action=""
        className="location-form-container-mainform"
        onSubmit={handleSubmit}
      >
        <div className="location-form-container-mainform-header">
          <h4>Configure Location</h4>
          <img
            src={closeForm}
            alt="close"
            onClick={() => {
              resetLocationName("");
              setLocationData({});
              toggleContinueForm(false);
            }}
          />
        </div>

        <div className="location-form-container-mainform-group-wrapper">
          <div className="form-group">
            <label htmlFor="">Name of Location:</label>
            <input
              type="text"
              value={locationName}
              onChange={(event) => setLocationName(event.target.value)}
              placeholder="e.g United States"
              disabled={locationData?.isView}
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Office Address:</label>
            <input
              type="text"
              value={officeAddress}
              onChange={(event) => setOfficeAddress(event.target.value)}
              placeholder="e.g Head Office"
              disabled={locationData?.isView}
            />
          </div>

          <div className="form-group">
            <label htmlFor="work-weekdays">
              Check the boxes for the work weekdays of new location:
            </label>
            <div className="week-checkboxes">
              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="sunday"
                  id=""
                  checked={workweek.sunday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="sunday">Sunday</label>
              </div>

              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="monday"
                  id=""
                  checked={workweek.monday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="monday">Monday</label>
              </div>

              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="tuesday"
                  id=""
                  checked={workweek.tuesday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="tuesday">Tuesday</label>
              </div>

              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="wednesday"
                  id=""
                  checked={workweek.wednesday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="wednesday">Wednesday</label>
              </div>

              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="thursday"
                  id=""
                  checked={workweek.thursday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="thursday">Thursday</label>
              </div>

              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="friday"
                  id=""
                  checked={workweek.friday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="friday">Friday</label>
              </div>

              <div className="checkboxes">
                <input
                  type="checkbox"
                  name="saturday"
                  id=""
                  checked={workweek.saturday || false}
                  onChange={handleWorkweekChange}
                  disabled={locationData?.isView}
                />
                <label className="week-label" htmlFor="saturday">Saturday</label>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="">Location Status:</label>
            <select
              name="location status"
              id="location status"
              value={locationStatus}
              onChange={(event) => setLocationStatus(event.target.value)}
              disabled={locationData?.isView}
            >
              <option value="" disabled selected className="selected">
                Please pick a status
              </option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="">User(s):</label>
            <MultiSelect
              options={defaultLocUsers}
              value={selectedUsers}
              onChange={setSelectedUsers}
              labelledBy="Select"
              // disabled={locationData?.isView}
            />
          </div>

          {!locationData?.isView && <button className="btn btn-update-location">{(locationData?.isEdit) ? "Update" : "Save"}</button>}
        </div>
      </form>
    </div>
  );
}

export default LocationForm;
