import "./History.scss";
import React, { useState } from "react";
import "./AllRequests.scss";
import { useData } from "@microsoft/teamsfx-react";
import { handleBackendRequestBlockErrors } from "../utils/errorHandlingUtils";
import moment from "moment";
import AllRequestsView from "./AllRequestsView";
import EmptyDataPlaceHolder from "./EmptyDataPlaceHolder";
import DatePicker from "./DatePicker";
import { getUniqueObjectsFromArray } from "../utils/generalUtils";
import iconFunnel from "../../images/icon-funnel.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useMediaQuery } from "react-responsive";
import { Loader } from "@fluentui/react-northstar";

function AllRequests(props) {
  const { triggerConsent, apiClient, user } = props;
  const [allRequestsHistoryData, setAllRequestsHistoryData] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState();
  // const [defaultLeaveTypes, setDefaultLeaveTypes] = useState([]);
  // const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [historyUsers, setHistoryUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [showViewAll, setShowViewAll] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  // Get users' leave request history and extract unique history users
  const { reload: reloadRequests } = useData(async () => {
    try {
      const response = await apiClient.get(`/leave/allLeaves`);
      setAllRequestsHistoryData(response.data.data);

      let historyUsers = response.data.data.map((leaveRequest) => {
        return { adId: leaveRequest.userId, fullName: leaveRequest.fullName };
      });
      let uniqueHistoryUsers = getUniqueObjectsFromArray(historyUsers, "adId");
      setHistoryUsers(uniqueHistoryUsers);
      setLoading(false);
    } catch (error) {
      handleBackendRequestBlockErrors(error, triggerConsent);
      setLoading(false);
    }
  });

  const onDateChange = (start, end) => {
    setFilterStartDate(start);
    setFilterEndDate(end);
  };

  const toggleViewAll = (viewAllState) => {
    setShowViewAll(viewAllState);
  };

  const tableHeaderDetails = [
    "Employee Name",
    "Leave Type",
    "Start Date",
    "End Date",
    "Duration",
    "Approval Status",
    "",
  ];

  const tableHeaderDetailsMobile = [
    "Employee Name",
    "Leave Type",
    "Approval Status",
    "",
  ];

  return (
    <div className="all-history-table-container">
      <h3>All Leave History</h3>

      <div className="all-history-filter-container">
        <div className="all-history-filter-container container-desktop">
          <div className="filter-div">
            <label htmlFor="">Filter:</label>
            <select
              name=""
              id=""
              value={selectedUser}
              onChange={(event) => setSelectedUser(event.target.value)}
            >
              <option value="" selected disabled>
                Filter By Employee's Name
              </option>
              <option value="All">All</option>
              {historyUsers.map((user, key) => {
                return (
                  <option value={user.adId} key={key}>
                    {user.fullName}
                  </option>
                );
              })}
            </select>
          </div>

          <DatePicker onDateChange={onDateChange} />

          <div className="filter-div">
            <label className="label-search" htmlFor="">
              Search:
            </label>
            <div className="search">
              <input
                className="input-search"
                type="search"
                name=""
                id=""
                placeholder="Search by Approval Status"
                onChange={(event) => setSearchText(event.target.value)}
              />
              <button
                className="btn-input-search"
                onClick={() => setSearchQuery(searchText)}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="all-history-filter-container container-mobile">
          <Popup
            trigger={
              <button className="mobile-menu-filter">
                <span className="filter-icon">
                  <img src={iconFunnel} alt="" className="icon-funnel" />
                </span>{" "}
                <span className="filter-text">Filter</span>
              </button>
            }
            position="left center"
          >
            <div className="filter-input">
              <label htmlFor="">Filter:</label>
              <select
                name=""
                id=""
                value={selectedUser}
                onChange={(event) => setSelectedUser(event.target.value)}
              >
                <option value="" selected disabled>
                  Employee's Name
                </option>
                <option value="All">All</option>
                {historyUsers.map((user, key) => {
                  return (
                    <option value={user.adId} key={key}>
                      {user.fullName}
                    </option>
                  );
                })}
              </select>
            </div>

            <DatePicker onDateChange={onDateChange} />
          </Popup>
        </div>
      </div>

      {loading ? (
        <div>
          <Loader style={{ margin: 100 }} />
        </div>
      ) : (
        <>
          {!isMobile && allRequestsHistoryData.length === 0 ? (
            <EmptyDataPlaceHolder tableHeaderDetails={tableHeaderDetails} />
          ) : (
            <div className="desktop-table">
              <table>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Leave Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Duration</th>
                    <th>Approval Status</th>
                    <th></th>
                  </tr>
                </thead>
                {allRequestsHistoryData
                  .filter(
                    (row) =>
                      (!searchQuery.length ||
                        row.approvalStatus
                          .toString()
                          .toLowerCase()
                          .includes(searchQuery.toString().toLowerCase())) &&
                      (!selectedUser ||
                        selectedUser === "All" ||
                        row.userId === selectedUser) &&
                      (!filterEndDate ||
                        (new Date(row.createdAt).toLocaleDateString("sv-SE") >=
                          filterStartDate &&
                          new Date(row.createdAt).toLocaleDateString("sv-SE") <=
                            filterEndDate))
                  )
                  .map((item) => {
                    return (
                      <tbody>
                        <tr key={item.leaveRequestId} id={item.leaveRequestId}>
                          <td>{item.fullName}</td>
                          <td>{item.leaveType}</td>
                          <td>
                            {moment(item.startDate)
                              .format("DD-MMM-YYYY")
                              .toLocaleUpperCase()}
                          </td>
                          {/* <td>{(new Date (item.startDate)).toLocaleDateString('nl-NL')}
                    </td> */}
                          <td>
                            {moment(item.endDate)
                              .format("DD-MMM-YYYY")
                              .toLocaleUpperCase()}
                          </td>

                          <td>
                            {item.totalDays} {item.leaveUnit}
                          </td>
                          <td>
                            {item.approvalStatus === "Pending"
                              ? item.approvalStatus + " - " + item.approverRole
                              : item.approvalStatus}
                          </td>
                          <td>
                            {/* <button
                        className="btn-table btn-track"
                        onClick={(event) => {
                          let leaveId = event.target.parentNode.parentNode.id;
                          setSelectedLeave(allRequestsHistoryData[leaveId]);
                          toggleTracker(true);
                        }}
                      >
                        track
                      </button> */}

                            <button
                              className="btn-table btn-view"
                              onClick={(event) => {
                                let leaveRequestId = parseInt(
                                  event.target.parentNode.parentNode.id
                                );
                                setSelectedLeave(
                                  allRequestsHistoryData.find(
                                    (leaveRequest) =>
                                      leaveRequest.leaveRequestId ===
                                      leaveRequestId
                                  )
                                );
                                toggleViewAll(true);
                              }}
                            >
                              view
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          )}

          {isMobile && allRequestsHistoryData.length === 0 ? (
            <EmptyDataPlaceHolder tableHeaderDetails={tableHeaderDetailsMobile} />
          ) : (
             <div className="mobile-table">
                <table>
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Leave Type</th>
                      <th>Approval Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  {allRequestsHistoryData
                    .filter(
                      (row) =>
                        (!searchQuery.length ||
                          row.approvalStatus
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toString().toLowerCase())) &&
                        (!selectedUser ||
                          selectedUser === "All" ||
                          row.userId === selectedUser) &&
                        (!filterEndDate ||
                          (new Date(row.createdAt).toLocaleDateString("sv-SE") >=
                            filterStartDate &&
                            new Date(row.createdAt).toLocaleDateString("sv-SE") <=
                              filterEndDate))
                    )
                    .map((item) => {
                      return (
                        <tbody>
                          <tr key={item.leaveRequestId} id={item.leaveRequestId}>
                            <td>{item.fullName}</td>
                            <td>{item.leaveType}</td>

                            <td>
                              {item.approvalStatus === "Pending"
                                ? item.approvalStatus + " - " + item.approverRole
                                : item.approvalStatus}
                            </td>
                            <td>
                              <button
                                className="btn-table btn-view"
                                onClick={(event) => {
                                  let leaveRequestId = parseInt(
                                    event.target.parentNode.parentNode.id
                                  );
                                  setSelectedLeave(
                                    allRequestsHistoryData.find(
                                      (leaveRequest) =>
                                        leaveRequest.leaveRequestId ===
                                        leaveRequestId
                                    )
                                  );
                                  toggleViewAll(true);
                                }}
                              >
                                view
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
            </div>
          )}
        </>
      )}

      {showViewAll && (
        <AllRequestsView
          toggleViewAll={toggleViewAll}
          selectedLeave={selectedLeave}
          apiClient={apiClient}
          triggerConsent={triggerConsent}
          user={user}
          reloadRequests={reloadRequests}
        />
      )}
    </div>
  );
}

export default AllRequests;
